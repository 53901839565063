<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Chauffeurs">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="clientForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="nom">nom <span class="text-danger">*</span></label>
                <input type="text" formControlName="nom" class="form-control" id="nom" [ngClass]="{
                        'is-invalid':
                          clientForm.get('nom')?.invalid &&
                          clientForm.get('nom')?.touched
                      }" />
                <div *ngIf="
                        clientForm.get('nom')?.invalid &&
                        clientForm.get('nom')?.touched
                      " class="invalid-feedback">
                  <div *ngIf="clientForm.get('nom')?.errors?.['required']">
                    Le nom est requis.
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="telephone">telephone<span class="text-danger">*</span></label>
                <input type="text" formControlName="telephone" class="form-control" id="telephone" [ngClass]="{
                    'is-invalid':
                      clientForm.get('telephone')?.invalid &&
                      clientForm.get('telephone')?.touched
                  }" />
                <div *ngIf="
                    clientForm.get('telephone')?.invalid &&
                    clientForm.get('telephone')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="clientForm.get('telephone')?.errors?.['required']">
                    La telephone est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="typeVehicule">Profils<span class="text-danger">*</span></label>
                <select formControlName="idProfil" class="form-select" [ngClass]="{
                                  'is-invalid':
                                    clientForm.get('idProfil')?.invalid &&
                                    clientForm.get('idProfil')?.touched
                                }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let profil of profils" [value]="profil.idProfil">
                    {{ profil.libelle }}
                  </option>
                </select>
                <div *ngIf="clientForm.get('idProfil')?.errors?.['required'] && clientForm.get('idProfil')?.touched"
                  class="invalid-feedback">
                  Le profil est requis.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="prenoms">prenoms<span class="text-danger">*</span></label>
                <input type="text" formControlName="prenoms" class="form-control" id="prenoms" [ngClass]="{
                    'is-invalid':
                      clientForm.get('prenoms')?.invalid &&
                      clientForm.get('prenoms')?.touched
                  }" />
                <div *ngIf="
                    clientForm.get('prenoms')?.invalid &&
                    clientForm.get('prenoms')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="clientForm.get('prenoms')?.errors?.['required']">
                    La prenoms est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="email">email<span class="text-danger">*</span></label>
                <input type="email" formControlName="email" class="form-control" id="email" [ngClass]="{
                    'is-invalid':
                      clientForm.get('email')?.invalid &&
                      clientForm.get('email')?.touched
                  }" />
                <div *ngIf="
                    clientForm.get('email')?.invalid &&
                    clientForm.get('email')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="clientForm.get('email')?.errors?.['required']">
                    La email est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="nom">Mot de passe<span class="text-danger">*</span></label>
                <input type="text" formControlName="password" class="form-control" id="password" [ngClass]="{
                  'is-invalid':
                    clientForm.get('password')?.invalid &&
                    clientForm.get('password')?.touched
                }" />
                <div *ngIf="
                                clientForm.get('password')?.invalid &&
                                clientForm.get('password')?.touched
                              " class="invalid-feedback">
                  <div *ngIf="clientForm.get('password')?.errors?.['required']">
                    Le mot de passe est requis.
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "Enregistrer" -->
          <button type="submit" class="btn btn-primary" [disabled]="clientForm.invalid" *ngIf="!isLoading">
            Enregistrer
          </button>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-liste-utilisateur></app-liste-utilisateur>
    </app-card>
  </div>
</div>