import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Router } from '@angular/router';
import { FournisseurService } from '../service/fournisseur.service';
import { FournisseurReponse } from '../reponse/fournisseur.reponse';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { FormGroup } from '@angular/forms';
import { alertConfirm } from 'src/app/helpers/alert-confirm';

@Component({
  selector: 'app-table-fournisseur',
  standalone: true,
  imports: [CommonModule, DataTablesModule],
  templateUrl: './table-fournisseur.component.html',
  styleUrl: './table-fournisseur.component.scss',
})
export class TableFournisseurComponent implements OnInit {
  isLoading = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;

  @Input() fournisseurForm: FormGroup | any;

  // Déclarez l'événement de clic à émettre
  @Output() handlePlusClick = new EventEmitter<void>();
  onPlusClick() {
    this.handlePlusClick.emit(); // Émet l'événement
  }
  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;

  constructor(
    private fournisseurService: FournisseurService,
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/fournisseurs`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        // URL to fetch data from
        dataSrc: '', // If the JSON response is an array, leave as an empty string
      },
      columns: [
        { title: 'Nom', data: 'nom' },
        { title: 'Prénoms', data: 'prenoms' },
        { title: 'Email', data: 'email' },
        { title: 'Téléphone', data: 'telephone' },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idFournisseur',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => `
        <button class="btn btn-link-primary btn-edit"
                title="Edit"
                id="edit-${row.idFournisseur}"
                data-info='${JSON.stringify({ nom: row.nom, prenoms: row.prenoms, idFournisseur: row.idFournisseur, email: row.email, telephone: row.telephone, adresse: row.adresse, nomEntreprise: row.nomEntreprise }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-pencil"></i>
        </button>
        <button class="btn btn-link-primary btn-delete"
                title="Delete"
                id="delete-${row.idFournisseur}"
                data-info='${JSON.stringify({ nom: row.libelle, prenoms: row.prenoms, idFournisseur: row.idFournisseur, email: row.email, telephone: row.telephone, adresse: row.adresse }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-trash"></i>
        </button>
    `
        }
      ],
      responsive: true,
      select: false,

    };

  }

  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then(() => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#clientTable tbody').on('click', '.btn-edit', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');
        this.fournisseurForm?.patchValue({
          nom: row['nom'],
          prenoms: row['prenoms'],
          email: row['email'],
          telephone: row['telephone'],
          adresse: row['adresse'],
          nomEntreprise: row['nomEntreprise'],
          idFournisseur: row['idFournisseur']
        });
        this.onPlusClick();
      });
      $('#clientTable tbody').on('click', '.btn-delete', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');
        this.confirmSuppression(row['idFournisseur'])
      });

    });
  }


  confirmSuppression(idFournisseur: any) {
    alertConfirm('Voulez-vous supprimer ce fournisseur ?', () => {
      this.onSubmitEdit(idFournisseur);
    });
  }

  onSubmitEdit(idFournisseur: any) {
    this.isLoading = true;
    this.fournisseurService.delete(idFournisseur).subscribe(
      (response: FournisseurReponse) => {
        this.isLoading = false;
        this.hasSuccess = true;
        this.successMessage = 'Suppression effectuée avec succès.';
        window.location.reload();
      },
      (error: any) => {
        this.hasError = true;
        this.isLoading = false;
        this.hasSuccess = false;
        this.errorMessage = error.error.message;
        console.error("Erreur lors de la Suppression", error);
      }
    );

  }

}

