import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionRapportRoutingModule } from './gestion-rapport-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    GestionRapportRoutingModule,
    PdfViewerModule,
  ]
})
export class GestionRapportModule { }
