<div class="row">


  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Véhicule" showButton="true" (plusClicked)="handlePlusClick()" buttonName="+">
        <h5>Enregistremment</h5>
        <hr />
        <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
          <p>{{successMessage}}</p>
        </div>
        <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
          <p>{{errorMessage}}</p>
        </div>
        <form [formGroup]="vehiculeForm" (ngSubmit)="onSubmit()">
          <div class="row" *ngIf="formStep === 0">
            <div class="col-md-6">
              <div class="form-group">
                <label for="marque">Marque du véhicule<span class="text-danger">*</span></label>
                <ng-container *ngIf="vehiculeForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                    [items]="marques" [selectOnTab]="true" (change)="selectMarque($event)" bindValue="idMarque"
                    labelForId="idMarque" formControlName="idMarque" [ngClass]="{
                                                  'is-invalid':
                                                    vehiculeForm.get('idMarque')?.invalid &&
                                                    vehiculeForm.get('idMarque')?.touched
                                                }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                  </ng-select>
                </ng-container>
                <div *ngIf="vehiculeForm.get('idMarque')?.errors?.['required'] && vehiculeForm.get('idMarque')?.touched"
                  class="invalid-feedback">
                  La marque est requise.
                </div>
              </div>

              <!-- Modèle -->
              <div class="form-group">
                <label for="modele">Modèle du véhicule<span class="text-danger">*</span></label>
                <ng-container *ngIf="vehiculeForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                    [items]="modeles" [selectOnTab]="true" (change)="selectModele($event)" bindValue="idModele"
                    labelForId="idModele" formControlName="idModele" [ngClass]="{
                                                                        'is-invalid':
                                                                          vehiculeForm.get('idModele')?.invalid &&
                                                                          vehiculeForm.get('idModele')?.touched
                                                                      }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                  </ng-select>
                </ng-container>
                <div *ngIf="vehiculeForm.get('idModele')?.errors?.['required'] && vehiculeForm.get('idModele')?.touched"
                  class="invalid-feedback">
                  Le modèle est requis.
                </div>

              </div>

              <!-- Type du véhicule -->
              <div class="form-group">
                <label for="typeVehicule">Type du véhicule<span class="text-danger">*</span></label>
                <ng-container *ngIf="vehiculeForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                    [items]="types" [selectOnTab]="true" (change)="selectType($event)" bindValue="idType"
                    labelForId="idType" formControlName="idType" [ngClass]="{
                                                                                    'is-invalid':
                                                                                      vehiculeForm.get('idType')?.invalid &&
                                                                                      vehiculeForm.get('idType')?.touched
                                                                                  }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                  </ng-select>
                </ng-container>
                <div *ngIf="vehiculeForm.get('idType')?.errors?.['required'] && vehiculeForm.get('idType')?.touched"
                  class="invalid-feedback">
                  Le type de véhicule est requis.
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <!-- Immatriculation Provisoire -->
              <div class="form-group">
                <label for="immatriculationProvisoire">Immatriculation provisoire<span
                    class="text-danger">*</span></label>
                <input type="text" formControlName="immatriculationProvisoire" class="form-control" [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('immatriculationProvisoire')?.invalid &&
                      vehiculeForm.get('immatriculationProvisoire')?.touched
                  }" />
                <div
                  *ngIf="vehiculeForm.get('immatriculationProvisoire')?.errors?.['required'] && vehiculeForm.get('immatriculationProvisoire')?.touched"
                  class="invalid-feedback">
                  L'immatriculation provisoire est requise.
                </div>
                <div
                  *ngIf="vehiculeForm.get('immatriculationProvisoire')?.errors?.['minlength'] && vehiculeForm.get('immatriculationProvisoire')?.touched"
                  class="invalid-feedback">
                  Elle doit contenir au moins 3 caractères.
                </div>
              </div>
              <!-- Immatriculation Définitive -->
              <div class="form-group">
                <label for="immatriculationDefinitive">Immatriculation Définitive</label>
                <input type="text" formControlName="immatriculationDefinitive" class="form-control" [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('immatriculationDefinitive')?.invalid &&
                      vehiculeForm.get('immatriculationDefinitive')?.touched
                  }" />
                <div
                  *ngIf="vehiculeForm.get('immatriculationDefinitive')?.errors?.['required'] && vehiculeForm.get('immatriculationDefinitive')?.touched"
                  class="invalid-feedback">
                  L'immatriculation définitive est requise.
                </div>
                <div
                  *ngIf="vehiculeForm.get('immatriculationDefinitive')?.errors?.['minlength'] && vehiculeForm.get('immatriculationDefinitive')?.touched"
                  class="invalid-feedback">
                  Elle doit contenir au moins 3 caractères.
                </div>
              </div>
              <!-- Catégorie -->
              <div class="form-group">
                <label for="categorie">Catégorie<span class="text-danger">*</span></label>
                <select formControlName="categorie" class="form-select" [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('categorie')?.invalid &&
                      vehiculeForm.get('categorie')?.touched
                  }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let categorie of categories" [value]="categorie.id">
                    {{ categorie.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="vehiculeForm.get('categorie')?.errors?.['required'] && vehiculeForm.get('categorie')?.touched"
                  class="invalid-feedback">
                  La catégorie est requise.
                </div>
              </div>
            </div>
          </div>

          <!-- Étape suivante -->
          <div class="row" *ngIf="formStep === 1">
            <div class="col-md-6">
              <!-- Année de fabrication -->
              <!-- Fournisseur -->
              <div class="form-group">
                <label for="fournisseur">Fournisseur<span class="text-danger">*</span></label>
                <ng-container *ngIf="vehiculeForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                    [items]="fournisseurs" [selectOnTab]="true" (change)="selectFournisseur($event)"
                    bindValue="idFournisseur" labelForId="idFournisseur" formControlName="idFournisseur" [ngClass]="{
                                                                                                                      'is-invalid':
                                                                                                                        vehiculeForm.get('idFournisseur')?.invalid &&
                                                                                                                        vehiculeForm.get('idFournisseur')?.touched
                                                                                                                    }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.nom }} {{ item.prenoms }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.nom }} {{ item.prenoms }}
                    </ng-template>

                  </ng-select>
                </ng-container>
                <div
                  *ngIf="vehiculeForm.get('idFournisseur')?.errors?.['required'] && vehiculeForm.get('idFournisseur')?.touched"
                  class="invalid-feedback">
                  Le Fournisseur est requis.
                </div>
              </div>

              <!-- Kilométrage -->
              <div class="form-group">
                <label for="kilometrage">Kilométrage<span class="text-danger"></span></label>
                <input type="text" formControlName="kilometrage" class="form-control" />

              </div>
            </div>

            <div class="col-md-6">
              <!-- Nombre de places -->
              <div class="form-group">
                <label for="nombrePlace">Nombre de places</label>
                <input type="number" min="2" formControlName="nombrePlace" class="form-control" />
              </div>



              <!-- Type de carburant (sélecteur) -->
              <div class="form-group">
                <label for="carburant">Type de carburant<span class="text-danger">*</span></label>
                <select formControlName="typeCarburant" class="form-select" [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('typeCarburant')?.invalid &&
                      vehiculeForm.get('typeCarburant')?.touched
                  }">
                  <option value="">Sélectionner</option>
                  <option value="Essence">Essence</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Électrique">Électrique</option>
                  <option value="Hybride">Hybride</option>
                </select>
                <div
                  *ngIf="vehiculeForm.get('typeCarburant')?.errors?.['required'] && vehiculeForm.get('typeCarburant')?.touched"
                  class="invalid-feedback">
                  Le type de carburant est requis.
                </div>
              </div>

            </div>
          </div>

          <div class="row" *ngIf="formStep === 2">
            <div class="col-md-6">
              <!-- Numéro de la carte grise -->
              <div class="form-group">
                <label for="numeroCarteGrise">Numéro de la carte grise</label>
                <input type="text" formControlName="numeroCarteGrise" class="form-control" [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('numeroCarteGrise')?.invalid &&
                      vehiculeForm.get('numeroCarteGrise')?.touched
                  }" placeholder="Numéro de la carte grise" />
                <div
                  *ngIf="vehiculeForm.get('numeroCarteGrise')?.errors?.['required'] && vehiculeForm.get('numeroCarteGrise')?.touched"
                  class="invalid-feedback">
                  Le numéro de la carte grise est requis.
                </div>
              </div>

              <!-- Châssis -->
              <div class="form-group">
                <label for="chassis">Châssis<span class="text-danger">*</span></label>
                <input type="text" formControlName="chassis" class="form-control" [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('chassis')?.invalid &&
                      vehiculeForm.get('chassis')?.touched
                  }" placeholder="Numéro de châssis" />
                <div *ngIf="vehiculeForm.get('chassis')?.errors?.['required'] && vehiculeForm.get('chassis')?.touched"
                  class="invalid-feedback">
                  Le numéro de châssis est requis.
                </div>
              </div>



              <!-- Date de mise en circulation -->
              <div class="form-group">
                <label for="dateMiseCirculation">Date de mise en circulation <span class="text-danger">*</span></label>
                <input type="date" formControlName="dateMiseCirculation" class="form-control" [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('dateMiseCirculation')?.invalid &&
                      vehiculeForm.get('dateMiseCirculation')?.touched
                  }" />
                <div
                  *ngIf="vehiculeForm.get('dateMiseCirculation')?.errors?.['required'] && vehiculeForm.get('dateMiseCirculation')?.touched"
                  class="invalid-feedback">
                  La date de mise en circulation est requise.
                </div>
              </div>
              <!-- Usage -->

            </div>

            <div class="col-md-6">

              <!-- Puissance fiscale -->
              <div class="form-group">
                <label for="puissanceFiscale">Puissance fiscale</label>
                <input type="text" formControlName="puissanceFiscale" class="form-control" [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('puissanceFiscale')?.invalid &&
                      vehiculeForm.get('puissanceFiscale')?.touched
                  }" placeholder="Puissance fiscale" />
                <div
                  *ngIf="vehiculeForm.get('puissanceFiscale')?.errors?.['required'] && vehiculeForm.get('puissanceFiscale')?.touched"
                  class="invalid-feedback">
                  La puissance fiscale est requise.
                </div>
              </div>

              <!-- Mode -->
              <div class="form-group">
                <label for="mode">Mode <span class="text-danger">*</span></label>
                <select formControlName="mode" class="form-select" [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('mode')?.invalid &&
                      vehiculeForm.get('mode')?.touched
                  }">
                  <option value="">Sélectionner</option>
                  <option value="Automatique">Automatique</option>
                  <option value="Manuel">Manuel</option>
                </select>
                <div *ngIf="vehiculeForm.get('mode')?.errors?.['required'] && vehiculeForm.get('mode')?.touched"
                  class="invalid-feedback">
                  Le mode est requis.
                </div>
              </div>
              <div class="form-group">
                <label for="usage">Usage <span class="text-danger">*</span></label>
                <select formControlName="usage" class="form-select" [ngClass]="{
                                                        'is-invalid':
                                                          vehiculeForm.get('usage')?.invalid &&
                                                          vehiculeForm.get('usage')?.touched
                                                      }">
                  <option value="">Sélectionner</option>
                  <option value="Publique">Publique</option>
                  <option value="Privé">Privé</option>
                </select>
                <div *ngIf="vehiculeForm.get('usage')?.errors?.['required'] && vehiculeForm.get('usage')?.touched"
                  class="invalid-feedback">
                  L'usage est requis.
                </div>
              </div>
            </div>
          </div>



          <!-- Navigation entre les étapes -->
          <div class="d-flex justify-content-end">
            <button *ngIf="formStep > 0" type="button" (click)="previousStep()" class="btn btn-primary me-2">
              Retour
            </button>
            <button *ngIf="formStep < 2" type="button" (click)="nextStep()" class="btn btn-primary">
              Suivant
            </button>
            <button *ngIf="formStep == 2 && !isLoading" [disabled]="vehiculeForm.invalid" type="submit"
              class="btn btn-primary">
              Enregistrer
            </button>
            <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
              <span class="spinner-grow spinner-grow-sm" role="status"></span>
              Traitement...
            </button>
          </div>
        </form>
      </app-card>
    </div>
  </div>
</div>