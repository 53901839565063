import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { environment } from 'src/environments/environment';
import { ModelService } from '../services/model.service';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-liste-md',
  standalone: true,
  imports: [CommonModule, DataTablesModule, SharedModule, RouterModule],
  templateUrl: './liste-md.component.html',
  styleUrl: './liste-md.component.scss'
})
export class ListeMdComponent implements OnInit {

  @Input() modelForm: FormGroup | any;
  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;

  constructor(
    private modelService: ModelService,
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/modeles`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        // URL to fetch data from
        dataSrc: '', // If the JSON response is an array, leave as an empty string
      },
      columns: [
        { title: 'Libellé', data: 'libelle' },
        { title: 'Description', data: 'description' },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idModele',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => `
        <button class="btn btn-link-primary btn-edit"
                title="Edit"
                id="edit-${row.idModele}"
                data-info='${JSON.stringify({ libelle: row.libelle, description: row.description, idModele: row.idModele, idMarque: row.marque.idMarque }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-pencil"></i>
        </button>
        <button class="btn btn-link-primary btn-delete"
                title="Delete"
                id="delete-${row.idModele}"
                data-info='${JSON.stringify({ libelle: row.libelle, description: row.description, idModele: row.idModele, idMarque: row.marque.idMarque }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-trash"></i>
        </button>
    `
        }
      ],
      responsive: true,
      select: false,

    };

  }

  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then(() => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#typeTable tbody').on('click', '.btn-edit', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');
        this.modelForm?.patchValue({
          libelle: row['libelle'],
          description: row['description'],
          idModele: row['idModele'],
          idMarque: row['idMarque']
        });
      });

    });
  }
}
