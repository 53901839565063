import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party
import { ImageCroppedEvent, ImageCropperModule, ImageTransform } from 'ngx-image-cropper';
import { VehiculeService } from '../../../services/vehicule.service';
import { ParametreReponse } from '../../../reponses/vehicule.reponse';
import { EMPTY, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document-creation',
  standalone: true,
  imports: [CommonModule, ImageCropperModule, SharedModule],
  templateUrl: './document-creation.component.html',
  styleUrl: './document-creation.component.scss'
})
export class DocumentCreationComponent {
  documentForm: FormGroup;
  imageChangedEvent = '';
  croppedImage!: string | undefined | null;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  parametreReponse$: Observable<ParametreReponse[]> = EMPTY;
  id: string | null = null;
  isLoading = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  // Constructor
  constructor(private fb: FormBuilder, private cdref: ChangeDetectorRef, private vehiculeService: VehiculeService, private route: ActivatedRoute) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.parametreReponse$ = this.getTypeDocument();
    this.documentForm = this.fb.group({
      idDocument: [null],
      typeDocument: ['', Validators.required],
      lien: ['', Validators.required],
      extension: ['', Validators.required],
      idParent: [this.id, Validators.required],
      parent: ['VEHICULE', Validators.required],
    });

  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.documentForm.get('lien')?.setValue(event.base64);

  }
  imageLoaded() {
    this.showCropper = true;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;

    // Récupérer le fichier sélectionné
    const file = event.target.files[0];

    if (file) {
      // Extraire l'extension du fichier
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      this.documentForm.get('extension')?.setValue(fileExtension);

      // Utiliser FileReader pour obtenir le contenu Base64
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64String = e.target.result.split(',')[1]; // Récupère le contenu Base64 sans le préfixe
        this.documentForm.get('lien')?.setValue(base64String);
      };
      reader.readAsDataURL(file); // Lecture du fichier en tant que URL Base64
    }
  }
  getTypeDocument(): Observable<ParametreReponse[]> {
    return this.vehiculeService.getParametresType("CONFIG_DOCUMENT");
  }

  onSubmit() {
    if (this.documentForm.get('idDocument')?.value == null) {
      this.isLoading = true;
      if (this.documentForm.valid) {
        const payload = this.documentForm.value;
        payload.lien = payload.lien?.split(',')[1];
        payload.idDocument = null;
        this.vehiculeService.createDocument(payload).subscribe(
          response => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.successMessage = 'Document ajouté avec succès.';
            this.documentForm.reset();
            this.croppedImage = null;
            this.cdref.detectChanges();
          },
          error => {
            this.hasError = true;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de l\'enregistrement du document', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    } else {
      // this.onSubmitEdit();
    }
  }

}
