// Angular import
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  // public props
  @Input() cardTitle!: string;
  @Input() cardClass!: string;
  @Input() blockClass!: string;
  @Input() headerClass!: string;
  @Input() hidHeader = false;
  @Input() customHeader = false;
  @Input() customDate = false;
  @Input() CardDate!: string;
  @Input() showButton = false;
  @Input() buttonName: string = '+';
  @Output() plusClicked = new EventEmitter<void>(); // Émetteur d'événement
  onPlusClick() {
    console.log('Bouton + cliqué dans le composant enfant');
    this.plusClicked.emit(); // Émet un événement
  }
}
