<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Nouveau Workflow">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="workflowForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">

              <div class="form-group">
                <label for="typeVehicule">Activité<span class="text-danger">*</span></label>
                <select formControlName="endpoint" class="form-select" [ngClass]="{
                                                  'is-invalid':
                                                    workflowForm.get('endpoint')?.invalid &&
                                                    workflowForm.get('endpoint')?.touched
                                                }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let endpoint of (workflowStateReponse$ | async)" [value]="endpoint.valeur">
                    {{ endpoint.libelle }}
                  </option>
                </select>
                <div *ngIf="workflowForm.get('endpoint')?.errors?.['required'] && workflowForm.get('endpoint')?.touched"
                  class="invalid-feedback">
                  L'activité est requise.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="libelle">libelle <span class="text-danger">*</span></label>
              <input type="text" formControlName="label" class="form-control" id="label" [ngClass]="{
                  'is-invalid':
                    workflowForm.get('label')?.invalid &&
                    workflowForm.get('label')?.touched
                }" />
              <div *ngIf="
                  workflowForm.get('label')?.invalid &&
                  workflowForm.get('label')?.touched
                " class="invalid-feedback">
                <div *ngIf="workflowForm.get('label')?.errors?.['required']">
                  Le libelle est requis.
                </div>
                <div *ngIf="workflowForm.get('label')?.errors?.['minlength']">
                  Le libelle doit contenir au moins 3 caractères.
                </div>
              </div>

              <div class="form-group">
                <label for="description">Description <span class="text-danger">*</span></label>
                <input type="text" formControlName="description" class="form-control" id="description" />

              </div>
              <div class="d-flex justify-content-end">
                <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
                  <span class="spinner-grow spinner-grow-sm" role="status"></span>
                  Traitement...
                </button>

                <!-- Bouton "Enregistrer" -->
                <button type="submit" class="btn btn-primary" [disabled]="workflowForm.invalid" *ngIf="!isLoading">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="mb-5"></div>
      <app-liste-workflow></app-liste-workflow>
    </app-card>

  </div>
</div>