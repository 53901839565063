import { Component } from '@angular/core';
import { FcmService } from './helpers/fcm-service';

// third party
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',


})
export class AppComponent {
  title = 'parc_auto_app';

  // constructor

  constructor(private fcmService: FcmService,
    private notifier: NotifierService,
  ) {

  }


  ngOnInit() {
    this.notifier.getConfig().position = { horizontal: { position: 'right', distance: 12 }, vertical: { position: 'bottom', distance: 12, gap: 10 } };

    // Initialize FCM listener when the app starts
    this.fcmService.requestPermission();
    this.fcmService.requestNotificationPermission();
    this.fcmService.currentMessage.subscribe((message: any) => {
      if (message != null) {
        const notification = message.notification || {};
        this.showNotification(notification.title, notification.body);
      }

    })
  }


  showNotification(type: string, message: string): void {
    this.notifier.notify("warning", message);
  }


}
