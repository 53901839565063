import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party
import { matches } from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { CompagnieReponse } from '../../gestion-compagnie-assurance/reponse/compagnie.reponse';
import { VehiculeReponse } from '../../gestion-vehicule/vehicule/reponses/vehicule.reponse';
import { VehiculeService } from '../../gestion-vehicule/vehicule/services/vehicule.service';
import { CompagnieService } from '../../gestion-compagnie-assurance/service/compagnie.service';
import { MaintenanceService } from '../service/maintenance.service';
import { MaintenanceModele } from '../modeles/maintenance.modele';
import { MaintenanceReponse } from '../reponse/maintenance.reponse';
@Component({
  selector: 'app-creation-maintenance',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './creation-maintenance.component.html',
  styleUrl: './creation-maintenance.component.scss'
})
export class CreationMaintenanceComponent {
  maintenanceForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  vehicules$: Observable<VehiculeReponse[]> = EMPTY;
  compagnies$: Observable<CompagnieReponse[]> = EMPTY;
  constructor(private fb: FormBuilder,
    private vehiculeService: VehiculeService,
    private compagnieService: CompagnieService,
    private maintenanceService: MaintenanceService
  ) {
    this.maintenanceForm = this.fb.group({
      dateMaintenance: ['', [Validators.required]],
      dateFin: [''],
      motif: ['', [Validators.required]],
      description: ['', [Validators.required]],
      idVehicule: [null, [Validators.required]],
      cout: [0],
      idCompagnie: [null, [Validators.required]],
      lien: [''],
      extension: [''],
    });
    this.compagnies$ = this.compagnieService.getAll("CENTRE");
    this.vehicules$ = this.vehiculeService.getAll();
  }


  fileChangeEvent(event: any): void {
    // Récupérer le fichier sélectionné
    const file = event.target.files[0];

    if (file) {
      // Extraire l'extension du fichier
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      this.maintenanceForm.get('extension')?.setValue(fileExtension);

      // Utiliser FileReader pour obtenir le contenu Base64
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64String = e.target.result.split(',')[1]; // Récupère le contenu Base64 sans le préfixe
        this.maintenanceForm.get('lien')?.setValue(base64String);
      };
      reader.readAsDataURL(file); // Lecture du fichier en tant que URL Base64
    }
  }


  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = true;
    const payload: MaintenanceModele = this.maintenanceForm.value;
    if (this.maintenanceForm.get('lien')?.value) {
      payload.document = {
        lien: this.maintenanceForm.get('lien')?.value,
        extension: this.maintenanceForm.get('extension')?.value
      }
    }
    this.maintenanceService.create(payload).subscribe(
      (response: MaintenanceReponse) => {
        this.isLoading = false;
        this.hasError = false;
        this.hasSuccess = true;
        this.successMessage = 'Maintenance ajoutée avec succès.';
        this.maintenanceForm.reset();
      },
      (error: any) => {
        this.hasError = true;
        this.hasSuccess = false;
        this.isLoading = false;
        this.errorMessage = error.error.message;
        console.error("Erreur lors de la création de la maintenance", error);
      }
    );

  }

}
