import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionParametreRoutingModule } from './gestion-parametre.routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionParametreRoutingModule
  ]
})
export class GestionParametreModule { }
