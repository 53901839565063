<div class="table-responsive">
  <table class="table mb-0">
    <thead>
      <tr>
        <th>Description</th>
        <th class="text-end">Image</th>
        <th class="text-end"></th>
      </tr>
    </thead>
    <tbody>
      @for (document of documentReponse$ | async; track document) {
      <tr>
        <td>
          <div>
            <h5 class="mb-1">{{ document.typeDocument }}</h5>
          </div>
        </td>

        <td class="text-end"><img [src]="baseUrl + document.lien" alt="{{ document.typeDocument }}"
            class="wid-50 rounded" (click)="openModal(document)" />
        </td>
        @if (trash) {
        <td class="text-end">
          <button type="button" class="btn btn-link-danger" title="Delete" (click)="confirm(document.idDocument)">
            <i class="ti ti-trash"></i>
          </button>
        </td>
        }
      </tr>
      }
    </tbody>
  </table>
</div>
<notifier-container></notifier-container>