import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { ParametreReponse } from '../reponse/parametre.reponse';
import { ParametreModele } from '../modeles/parametre.modele';


@Injectable({
  providedIn: 'root',
})
export class ParametreService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }


  update(payload: ParametreModele, id: any): Observable<ParametreReponse> {
    return this.http.put<any>(
      `${this.url}/parametres/${id}`,
      payload,
      this.getHttpOptions()
    );
  }

  getAllByType(query: any): Observable<ParametreReponse[]> {
    const url = `${this.url}/parametres/type/${query}`;
    return this.http.get<any>(
      url,
      this.getHttpOptions()
    );
  }
}
