// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { TableVilleComponent } from "../table-ville/table-ville.component";
import { VilleService } from '../service/ville.service';
import { VilleModele } from '../modeles/ville.modele';
import { VilleReponse } from '../reponse/ville.reponse';

@Component({
  selector: 'app-creation-ville',
  standalone: true,
  imports: [CommonModule, SharedModule, TableVilleComponent],
  templateUrl: './creation-ville.component.html',
  styleUrl: './creation-ville.component.scss'
})
export class CreationVilleComponent {
  categorieForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private villeService: VilleService
  ) {
    this.categorieForm = this.fb.group({
      idVille: [''],
      libelle: ['', [Validators.required]],
      montantChauffeur: [''],
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    console.log(this.categorieForm.get('idVille')?.value);

    if (this.categorieForm.get('idVille')?.value == "") {
      this.isLoading = true;
      this.hasError = false;
      this.hasSuccess = false;
      if (this.categorieForm.valid) {
        const payload: VilleModele = this.categorieForm.value;
        this.villeService.create(payload).subscribe(
          (response: VilleReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.successMessage = 'Ville ajoutée avec succès.';
            this.categorieForm.reset();
          },
          (error: any) => {
            this.hasError = true;
            this.hasSuccess = false;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de la création de la ville', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    } else {
      this.onSubmitEdit();
    }
  }

  onSubmitEdit() {
    this.isLoading = true;
    if (this.categorieForm.valid) {
      const idVille = this.categorieForm.get('idVille')?.value;
      this.categorieForm.get('idVille')?.setValue("");
      const payload: VilleModele = this.categorieForm.value;
      payload.idVille = null;
      this.villeService.update(payload, idVille).subscribe(
        (response: VilleReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Ville modifiée avec succès.';
          this.categorieForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la modification de la ville', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  onReset() {
    this.categorieForm.reset();
  }

}
