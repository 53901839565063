// Angular import
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { NgApexchartsModule } from 'ng-apexcharts';

// third party
import { ApexAxisChartSeries, ApexChart, ChartComponent, ApexDataLabels, ApexPlotOptions, ApexResponsive, ApexStroke } from 'ng-apexcharts';
import { CustomsThemeService } from 'src/app/theme/shared/service/customs-theme.service';
import { FormatNumberPipe, transform } from 'src/app/helpers/format-number';
import { DashBoardService } from '../service/dashboard.service';
import { Stat2Response } from '../reponse/stat2.reponse';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  stroke: ApexStroke;
  colors: string[];
  fill: ApexFill;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-analytics',
  standalone: true,
  imports: [CommonModule, SharedModule, FormatNumberPipe, NgApexchartsModule],
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  // public props
  @ViewChild('accountChart') accountChart!: ChartComponent;
  chartOptions: Partial<ChartOptions> = {};
  preset = ['#673ab7', '#2196f3', '#f44336'];
  data: any[] = [];
  total: number = 0;
  vehiculeDisponible: number = 0;
  vehiculeSortant: number = 0;
  chauffeurDisponible: number = 0;
  chauffeurSortant: number = 0;
  rowTable1: any[] = [];
  rowTable: any[] = [];
  totalLocation: number = 0;
  totalVenteArticle: number = 0;
  cards: any[] = [];
  tableList: any[] = [];
  // Constructor
  constructor(private theme: CustomsThemeService, private dashBoardService: DashBoardService) {
    this.getByDate(new Date().toISOString().split('T')[0]);

  }

  // Life cycle events
  ngOnInit(): void {
    this.theme.customsTheme.subscribe((res) => {
      if (res == 'preset-1') {
        this.preset = ['#673ab7', '#f44336', '#1e88e5'];
      }
      if (res == 'preset-2') {
        this.preset = ['#009688', '#d9534f', '#546e7a'];
      }
      if (res == 'preset-3') {
        this.preset = ['#ec407a', '#d9534f', '#1c2f59'];
      }
      if (res == 'preset-4') {
        this.preset = ['#c77e23', '#f44336', '#135152'];
      }
      if (res == 'preset-5') {
        this.preset = ['#3fb0ac', '#f44336', '#14383d'];
      }
      if (res == 'preset-6') {
        this.preset = ['#2ca58d', '#f44336', '#091f3c'];
      }
      if (res == 'preset-7') {
        this.preset = ['#3f51b5', '#f44336', '#3949ab'];
      }
    });
  }

  // private Method

  revenueList = [
    {
      color: 'text-success',
      name: 'Bitcoin',
      percentage: '+ $145.85'
    },
    {
      color: 'text-danger',
      name: 'Ethereum',
      percentage: '- $6.368'
    },
    {
      color: 'text-success',
      name: 'Ripple',
      percentage: '+ $458.63'
    },
    {
      color: 'text-danger',
      name: 'Neo',
      percentage: '- $5.631'
    },
    {
      color: 'text-danger',
      name: 'Bitcoin',
      percentage: '- $75.86'
    },
    {
      color: 'text-success',
      name: 'Ethereum',
      percentage: '+ $453.63'
    },
    {
      color: 'text-danger',
      name: 'Ripple',
      percentage: '+ $786.63'
    },
    {
      color: 'text-success',
      name: 'Neo',
      percentage: '+ $145.85'
    },
    {
      color: 'text-success',
      name: 'Bitcoin',
      percentage: '- $6.368'
    },
    {
      color: 'text-success',
      name: 'Ethereum',
      percentage: '+ $458.63'
    },
    {
      color: 'text-danger',
      name: 'Neo',
      percentage: '- $5.631'
    },
    {
      color: 'text-danger',
      name: 'Ripple',
      percentage: '+ $145.85'
    },
    {
      color: 'text-success',
      name: 'Bitcoin',
      percentage: '- $75.86'
    },
    {
      color: 'text-success',
      name: 'Bitcoin',
      percentage: '+ $453.63'
    },
    {
      color: 'text-danger',
      name: 'Ethereum',
      percentage: '+ $786.63'
    }
  ];



  dailyCard = [
    {
      title: 'Daily user',
      amount: '1,658',
      icon: 'account_circle',
      color: 'bg-secondary'
    },
    {
      title: 'Daily visitor',
      amount: '5,678',
      icon: 'description',
      color: 'bg-primary'
    }
  ];



  getByDate(date: any) {
    this.dashBoardService.getStatByDate(date).subscribe((data: Stat2Response) => {
      const seriesL: any = [];
      const seriesVH: any = [];
      const seriesV: any = [];
      this.vehiculeDisponible = data.vehiculeDisponible ?? 0;
      this.vehiculeSortant = data.vehiculeSortant ?? 0;
      this.chauffeurDisponible = data.chauffeurDisponible ?? 0;
      this.chauffeurSortant = data.chauffeurSortant ?? 0;
      this.totalLocation = data.totalLocation ?? 0;
      this.totalVenteArticle = data.totalVenteArticle ?? 0;

      data.monthlyStatsArticleByYear?.forEach((element: any) => {
        seriesV.push(element.totalMontant);
        this.total += element.totalMontant;
      })
      data.monthlyStatsLocationByYear?.forEach((element: any) => {
        seriesL.push(element.totalMontant);
        this.total += element.totalMontant;
      })
      data.monthlyStatsVehiculeByYear?.forEach((element: any) => {
        seriesVH.push(element.totalMontant);
        this.total += element.totalMontant;
      })
      data.top5VehiculeByQuantiteTotal?.forEach((element: any) => {
        this.tableList.push({
          name: element.article,
          quantity: element.quantiteTotale,
          amount: element.montantTotal
        })
      })


      this.rowTable1 = [
        {
          icon: 'share',
          value: transform(this.vehiculeDisponible),
          ship: 'ShaVéhicules disponibles'
        },
        {
          icon: 'router',
          value: transform(this.vehiculeSortant),
          ship: 'Véhicules sortants'
        }
      ];
      this.cards = [
        {
          title: 'Total location',
          amount: transform(this.totalLocation),
          text: transform(seriesL.reduce((a: any, b: any) => a + b, 0)),
          icon: 'monetization_on',
          color: 'bg-secondary'
        },
        {
          title: 'Total vente de pièces',
          amount: transform(this.totalVenteArticle),
          text: transform(seriesV.reduce((a: any, b: any) => a + b, 0)),
          icon: 'account_circle',
          color: 'bg-primary'
        }
      ];
      this.rowTable = [
        {
          icon: 'filter_vintage',
          value: transform(this.chauffeurDisponible),
          ship: 'Chauffeurs disponibles'
        },
        {
          icon: 'local_mall',
          value: transform(this.chauffeurSortant),
          ship: 'Chauffeurs sortants'
        }
      ];


      this.data = [
        {
          background: 'bg-light-secondary',
          icons: 'ti ti-device-analytics text-secondary',
          value: transform(seriesL.reduce((a: any, b: any) => a + b, 0))
        },
        {
          background: 'bg-light-primary',
          icons: 'ti ti-device-analytics text-primary',
          value: transform(seriesV.reduce((a: any, b: any) => a + b, 0))
        },
        {
          background: 'bg-light-danger',
          icons: 'ti ti-device-analytics text-danger',
          value: transform(seriesVH.reduce((a: any, b: any) => a + b, 0))
        }
      ];

      this.chartOptions = {
        chart: {
          type: 'area',
          height: 215,
          sparkline: {
            enabled: true
          }
        },
        colors: ['#673ab7', '#2196f3', '#f44336'],
        stroke: {
          curve: 'smooth',
          width: 2
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 80, 100]
          }
        },
        series: [
          {
            name: 'Location de véhicules',
            data: seriesL
          },
          {
            name: 'Vente de véhicules',
            data: seriesVH
          },
          {
            name: 'Vente de pièces',
            data: seriesV
          }
        ],
        tooltip: {
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          marker: {
            show: false
          }
        }
      };

    })
  }
}
