// Angular import
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { ParametreReponse } from '../../gestion-vehicule/vehicule/reponses/vehicule.reponse';
import { EMPTY, Observable } from 'rxjs';
import { PaiementService } from '../service/paiement.service';
import { TableSuppressionComponent } from '../table-suppression/table-suppression.component';
import { VenteVehiculeModele } from '../../gestion-location-vente/vente-vehicule/modeles/vente.vehicule.modele';
import { VenteVehiculeService } from '../../gestion-location-vente/vente-vehicule/service/vente.vehicule.service';
import { VenteVehiculeReponse } from '../../gestion-location-vente/vente-vehicule/reponse/vente.vehicule.reponse';

@Component({
  selector: 'app-suppression',
  standalone: true,
  imports: [CommonModule, SharedModule, TableSuppressionComponent],
  templateUrl: './suppression.component.html',
  styleUrl: './suppression.component.scss'
})
export class SuppressionComponent implements OnInit {
  @ViewChild(TableSuppressionComponent) enfantComponent!: TableSuppressionComponent;

  paiementForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  numFacture: any = ""
  params$: Observable<ParametreReponse[]> = EMPTY;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private paiementService: PaiementService,
    private venteVehiculeService: VenteVehiculeService
  ) {
    this.params$ = this.paiementService.getAllMoyenPaiement("MOTIF_SUPPRESSION");
    this.paiementForm = this.fb.group({
      idCommande: ['', [Validators.required]],
      motifSuppression: ['', [Validators.required]],
      oldMontant: ['', [Validators.required]],
      numFacture: ['', [Validators.required]],
      etat: ['', [Validators.required]],
      descriptionMotif: ['', [Validators.required]],
      motif: [''],
    });
    this.paiementForm.get('motif')?.valueChanges.subscribe((value) => {
      this.paiementForm.patchValue({ motifSuppression: value });
    })
    //this.paiementForm.disable();
    this.paiementForm.get('etat')?.valueChanges.subscribe((value) => {
      if (value != 'DELETING') {
        this.paiementForm.disable();
      }
    });
  }


  refreshTable() {
    this.enfantComponent.initializeDataTable(true);
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.hasError = false;
    this.hasSuccess = false;
    const isValid = this.paiementForm.get('idCommande')?.value !== null && this.paiementForm.get('suppressionMotif')?.value !== null && this.paiementForm.get('motif')?.value !== '';
    if (isValid) {
      this.isLoading = true;
      const payload: VenteVehiculeModele = this.paiementForm.value;
      this.venteVehiculeService.delete(this.paiementForm.get('idCommande')?.value, payload).subscribe(
        (response: VenteVehiculeReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Suppression soumise avec succès.';
          this.paiementForm.reset();
          window.location.reload();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la suppression', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  onSubmitForDelete() {
    this.hasError = false;
    this.hasSuccess = false;
    const isValid = this.paiementForm.get('idCommande')?.value !== null;
    if (isValid) {
      this.isLoading = true;
      this.venteVehiculeService.updateToRevokeForDelete(this.paiementForm.get('idCommande')?.value).subscribe(
        (response: VenteVehiculeReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Suppression annulée avec succès.';
          this.paiementForm.reset();
          window.location.reload();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error("Erreur lors de l'annulation de la suppression", error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }
  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
