<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h5>Création Profil</h5>
      </div>
      <div class="card-body">
        <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
          <p>{{successMessage}}</p>
        </div>
        <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
          <p>{{errorMessage}}</p>
        </div>


        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                  <button mat-icon-button></button>
                  <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node)">{{ node.item }}</mat-checkbox>
                </mat-tree-node>

                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                  <button mat-icon-button matTreeNodeToggle class="mat-focus-indicator mat-icon-button mat-button-base"
                    [attr.aria-label]="'Toggle ' + node.item">
                    <span class="mat-button-wrapper">
                      <mat-icon class="mat-icon-rtl-mirror">
                        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                      </mat-icon>
                    </span>
                  </button>
                  <mat-checkbox [checked]="descendantsAllSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node)">{{
                    node.item }}</mat-checkbox>

                </mat-tree-node>
              </mat-tree>
            </div>

            <div class="col-md-6">
              <form [formGroup]="profilForm" (ngSubmit)="onSubmit()">
                <label for="libelle">libelle <span class="text-danger">*</span></label>
                <input type="text" formControlName="libelle" class="form-control" id="libelle" [ngClass]="{
                    'is-invalid':
                      profilForm.get('libelle')?.invalid &&
                      profilForm.get('libelle')?.touched
                  }" />
                <div *ngIf="
                    profilForm.get('libelle')?.invalid &&
                    profilForm.get('libelle')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="profilForm.get('libelle')?.errors?.['required']">
                    Le libelle est requis.
                  </div>
                  <div *ngIf="profilForm.get('libelle')?.errors?.['minlength']">
                    Le libelle doit contenir au moins 3 caractères.
                  </div>
                </div>


                <div class="d-flex justify-content-end mt-3">
                  <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status"></span>
                    Traitement...
                  </button>

                  <!-- Bouton "Enregistrer" -->
                  <button type="submit" class="btn btn-primary" [disabled]="profilForm.invalid" *ngIf="!isLoading">
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>
</div>