// Angular import
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { TableCaisseComponent } from "../table-caisse/table-caisse.component";
import { ParametreReponse } from '../../gestion-vehicule/vehicule/reponses/vehicule.reponse';
import { EMPTY, Observable } from 'rxjs';
import { PaiementService } from '../service/paiement.service';
import { PaiementModele } from '../modeles/paiement.modele';
import { PaiementReponse } from '../reponse/paiement.reponse';

@Component({
  selector: 'app-caisse',
  standalone: true,
  imports: [CommonModule, SharedModule, TableCaisseComponent],
  templateUrl: './caisse.component.html',
  styleUrl: './caisse.component.scss'
})
export class CaisseComponent implements OnInit {
  @ViewChild(TableCaisseComponent) enfantComponent!: TableCaisseComponent;

  paiementForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  numFacture: any = ""
  params$: Observable<ParametreReponse[]> = EMPTY;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private paiementService: PaiementService
  ) {
    this.params$ = this.paiementService.getAllMoyenPaiement("M_PAIEMENT");
    this.paiementForm = this.fb.group({
      idCommande: ['', [Validators.required]],
      modePaiement: ['', [Validators.required]],
      montant: ['', [Validators.required]],
      reste: ['', [Validators.required]],
      datePaiement: ['', [Validators.required]],
      oldMontant: ['', [Validators.required]],
      numFacture: ['', [Validators.required]],
    });
    this.paiementForm.get('montant')?.valueChanges.subscribe((value) => {
      if (!this.paiementForm.get('montant')?.value) {
        const reste = Number(this.paiementForm.get('reste')?.value);
        const montant = Number(value);
        if (!isNaN(montant) && !isNaN(reste)) {
          if (montant > reste) {
            // Set errors on 'montant' if it exceeds 'reste'
            this.paiementForm.get('montant')?.setErrors({ 'invalid': true });
          } else if (montant > 0) {
            // Clear errors if valid

            this.paiementForm.get('montant')?.setErrors(null);
          }
        }
      }
    });
  }

  refreshTable() {
    this.enfantComponent.initializeDataTable(true);
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.paiementForm.valid) {
      const payload: PaiementModele = this.paiementForm.value;
      this.paiementService.create(payload).subscribe(
        (response: PaiementReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Paiement ajouté avec succès.';
          this.paiementForm.reset();
          this.refreshTable();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du paiement', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
