// angular import
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// bootstrap import
import { SharedModule } from 'src/app/theme/shared/shared.module';

// third party
import { Router, RouterModule } from '@angular/router';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-liste-workflow',
  standalone: true,
  imports: [CommonModule, DataTablesModule, SharedModule, RouterModule],
  templateUrl: './liste-workflow.component.html',
  styleUrl: './liste-workflow.component.scss'
})
export class ListeWorkflowComponent implements OnInit {

  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;

  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/workflows`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        // URL to fetch data from
        dataSrc: '', // If the JSON response is an array, leave as an empty string
      },
      columns: [
        { title: 'Libellé', data: 'label' },
        { title: 'Description', data: 'description' },
        { title: 'Etat', data: 'status' },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idWorkflow',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any) => `
                    <button class="btn btn-link-primary btn-view" title="View" id="${data}">
                        <i class="ti ti-eye"></i>
                    </button>
                    <button class="btn btn-link-primary btn-edit" title="Edit" id="${data}">
                        <i class="ti ti-pencil"></i>
                    </button>
                    <button class="btn btn-link-primary btn-delete" title="Delete" id="${data}">
                        <i class="ti ti-trash"></i>
                    </button>
                `
        }
      ],
      responsive: true,
      select: false,

    };


  }
  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#workflowTable tbody').on('click', '.btn-view', (event) => {
        this.navigateToRoute(event.currentTarget.id);
      });

    });
  }
  navigateToRoute(rowData: any) {

    this.router.navigate(['/app/gestion-workflow/workflow-actions', rowData]);
  }

}
