<div class="row">
  <div class="col-sm-12">
    <div class="card table-card">
      <div class="card-header">
        <div class="d-flex align-items-center justify-content-between">
          <h5>Liste des ventes de pièces</h5>
        </div>
      </div>

      <div class="card-body">
        <div class="table-responsive p-2">
          <table datatable [dtOptions]="dtRouterLinkOptions" id="tableVehicule" class="table  row-border table-hover">
          </table>
        </div>
      </div>
    </div>
  </div>
</div>