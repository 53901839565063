// Angular import
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { PaiementReponse } from '../reponse/paiement.reponse';
import { TableRetourComponent } from '../table-retour/table-retour.component';
import { VenteVehiculeService } from '../../gestion-location-vente/vente-vehicule/service/vente.vehicule.service';
import { DetailCommande } from '../../gestion-location-vente/vente-vehicule/modeles/vente.vehicule.modele';

@Component({
  selector: 'app-retour',
  standalone: true,
  imports: [CommonModule, SharedModule, TableRetourComponent],
  templateUrl: './retour.component.html',
  styleUrl: './retour.component.scss'
})
export class RetourComponent implements OnInit {
  @ViewChild(TableRetourComponent) enfantComponent!: TableRetourComponent;

  paiementForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  numFacture: any = ""


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private venteVehiculeService: VenteVehiculeService
  ) {

    this.paiementForm = this.fb.group({
      montant: [null],
      reste: ['', [Validators.required]],
      idDetailCommande: ['', [Validators.required]],
      datePaiement: ['', [Validators.required]],
      oldMontant: ['', [Validators.required]],
      numFacture: ['', [Validators.required]],
    });

  }

  refreshTable() {
    this.enfantComponent.initializeDataTable(true);
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.paiementForm.valid) {
      const payload: DetailCommande = {} as DetailCommande;
      payload.dateValidationRetour = this.paiementForm.get('datePaiement')?.value;
      payload.idDetailCommande = this.paiementForm.get('idDetailCommande')?.value;
      console.log(payload);


      this.venteVehiculeService.updateDateValidationRetour(payload, payload.idDetailCommande).subscribe(
        (response: PaiementReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Date de validation ajoutée avec succès.';
          this.paiementForm.reset();
          this.refreshTable();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création de la date de validation', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
