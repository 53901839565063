import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { VenteVehiculeReponse } from '../../../vente-vehicule/reponse/vente.vehicule.reponse';
import { VenteVehiculeService } from '../../../vente-vehicule/service/vente.vehicule.service';
import { ChauffeurReponse } from 'src/app/Pages/gestion-chauffeur/reponse/chauffeur.reponse';
import { ChauffeurService } from 'src/app/Pages/gestion-chauffeur/service/chauffeur.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { VenteVehiculeModele } from '../../../vente-vehicule/modeles/vente.vehicule.modele';

@Component({
  selector: 'app-validation-location-vehicule-detail',
  standalone: true,
  imports: [CommonModule, NgSelectModule, SharedModule],
  templateUrl: './validation-location-vehicule-detail.component.html',
  styleUrl: './validation-location-vehicule-detail.component.scss',
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class ValidationLocationVehiculeDetailComponent implements OnInit {
  selectedOption: any;
  venteForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  // public props
  id: string | null = null;
  vehicule$!: Observable<VenteVehiculeReponse | null>;
  chauffeurList$: Observable<ChauffeurReponse[]> = EMPTY;
  chauffeurSelected: ChauffeurReponse = {} as ChauffeurReponse;
  activatedChauffeurs: any[] = [];
  constructor(public router: Router, private route: ActivatedRoute,
    private venteVehiculeService: VenteVehiculeService, private fb: FormBuilder, private chauffeurService: ChauffeurService) {
    this.venteForm = this.fb.group({
      idChauffeur: [null],
    });
    this.venteForm.get('montantChauffeur')?.disable();
  }

  ngOnInit() {
    this.chauffeurList$ = this.findChauffeur();
    this.chauffeurList$.subscribe((chauffeurs: any[]) => {
      this.activatedChauffeurs = chauffeurs.filter(v => v.etat === 'ACTIVATED');
    });

    this.id = this.route.snapshot.paramMap.get('id');
    this.findDetail();
  }
  selectChauffeur(chauffeurReponse: ChauffeurReponse): void {
    this.chauffeurSelected = chauffeurReponse;
  }

  findDetail() {
    this.vehicule$ = this.venteVehiculeService.getById(this.id);
  }


  findChauffeur(): Observable<ChauffeurReponse[]> {
    return this.chauffeurService.getAll();
  }


  onSubmit() {
    // this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    this.vehicule$.subscribe((data: any) => {
      const payload: VenteVehiculeModele = data;
      // Ensure detailCommandes array exists and has at least one element
      if (payload.detailCommandes && payload.detailCommandes.length > 0) {
        payload.detailCommandes[0].idChauffeur = this.venteForm.get('idChauffeur')?.value;
        payload.detailCommandes[0].montantChauffeur = this.venteForm.get('montantChauffeur')?.value;

        this.isLoading = true; // Set loading state before making the request

        this.venteVehiculeService.updateState(this.id, payload).subscribe(
          (response: VenteVehiculeReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.successMessage = 'Location ajoutée avec succès.';
            this.venteForm.reset(); // Reset the form after successful creation
            this.findDetail();
          },
          (error: any) => {
            this.hasError = true;
            this.isLoading = false;
            this.errorMessage = error.error?.message || 'Une erreur est survenue'; // Default message if none provided
            console.error('Erreur lors de la création de la Location', error);
          }
        );
      } else {
        this.hasError = true;
        this.errorMessage = 'Aucune commande détaillée trouvée.';
        console.error('Payload detailCommandes is empty or not defined', payload);
      }
    });
  }
}
