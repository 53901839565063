import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { GestionProfilRoutingModule } from './gestion-profil-routing.module';
import { DetailProfilComponent } from './detail-profil/detail-profil.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    DetailProfilComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    GestionProfilRoutingModule,
    MatNativeDateModule,
    HttpClientModule,
    SharedModule,
    MatRippleModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule
  ]
})
export class GestionProfilModule { }
