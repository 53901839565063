import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// bootstrap import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { Router, RouterModule } from '@angular/router';

// third party

import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';

@Component({
  selector: 'app-list-v',
  standalone: true,
  imports: [CommonModule, DataTablesModule, SharedModule, RouterModule],
  templateUrl: './list-v.component.html',
  styleUrl: './list-v.component.scss'
})

export class ListVComponent implements OnInit {
  selectedVehicule: any;
  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;


  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/vehicules`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        // URL to fetch data from
        dataSrc: '', // If the JSON response is an array, leave as an empty string
      },
      columns: [
        { title: 'Marque', data: 'marque.libelle' },
        { title: 'Modele', data: 'modele.libelle' },
        { title: 'Type', data: 'type.libelle' },
        { title: 'IMT Définitive', data: 'immatriculationDefinitive' },
        { title: 'IMT Provisoire', data: 'immatriculationProvisoire' },
        {
          title: 'Etat', data: 'etat',
          render: function (data: string, type: string, row: any) {
            // Customize badge class based on 'etat' value
            let badgeClass = 'badge bg-light-success rounded f-12';

            // You can add more conditions here if needed
            // For example, different colors for different states
            if (data === 'Pending') {
              badgeClass = 'badge bg-warning rounded f-12';
            } else if (data === 'ACTIVATED') {
              badgeClass = 'badge bg-light-success rounded f-12';
            } else if (data === 'Canceled') {
              badgeClass = 'badge bg-danger rounded f-12';
            }
            if (data == 'ACTIVATED') {
              data = 'DISPONIBLE'
            } else if (data == 'RENTAL') {
              data = 'EN MISSION'
            }
            else if (data == 'UPDATED') {
              data = 'EN ATTENTE DE MISE A JOUR'
            }

            else if (data == 'PENDING') {
              data = 'EN ATTENTE DE VERIFICATION'
            } else if (data == 'PRE_CONFIRMED') {
              data = 'EN ATTENTE DE CONFIRMATION'
            }

            // Return the HTML for the badge
            return `<span class="${badgeClass}">${data}</span>`;
          }

        },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idVehicule',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => {
            return `
      <button class="btn btn-link-primary btn-view" title="View" id="${data}">
          <i class="ti ti-eye"></i>
      </button>
      ${row.etat == 'UPDATED'
                ? `<button class="btn btn-link-primary btn-edit" title="Edit" id="${data}">
             <i class="ti ti-pencil"></i>
           </button>`
                : ''
              }
      <button class="btn btn-link-primary btn-delete" title="Delete" id="${data}">
          <i class="ti ti-trash"></i>
      </button>
    `;
          }
        }
      ],

      responsive: true,
      select: false,


    };


  }

  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#tableVehicule tbody').on('click', '.btn-view', (event) => {
        this.navigateToRoute(event.currentTarget.id);
      });
      $('#tableVehicule tbody').on('click', '.btn-edit', (event) => {
        this.navigateToRouteModification(event.currentTarget.id);
      });
    });
  }
  navigateToRoute(data: any) {
    this.router.navigate(['/app/gestion-vehicule/vehicules/detail-vehicule', data]);
  }


  navigateToRouteModification(data: any) {
    this.router.navigate(['/app/gestion-vehicule/vehicules/modification-vehicule', data]);
  }
  navigateToCreate() {
    this.router.navigate(['/app/gestion-vehicule/vehicules/ajout-vehicule']);
  }

}
