import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionVenteRoutingModule } from './gestion-vente.routing.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionVenteRoutingModule
  ]
})
export class GestionVenteModule { }
