import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SessionService } from '../../auth/services/session.service';
import { environment } from 'src/environments/environment';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { WorkflowActionReponse, WorkflowReponse } from '../reponses/workflow.reponse';
import { alertConfirm } from 'src/app/helpers/alert-confirm';
import { WorkflowService } from '../services/workflow.service';
import { NotifierModule, NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-detail-workflow',
  standalone: true,

  imports: [CommonModule, NotifierModule, DataTablesModule, SharedModule, RouterModule],
  templateUrl: './detail-workflow.component.html',
  styleUrl: './detail-workflow.component.scss',
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class DetailWorkflowComponent {
  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;
  workflowName: string = '';
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  constructor(
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private localStorageService: SessionService,
    private workflowService: WorkflowService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/workflows/${this.route.snapshot.params['id']}`;
    const component = this;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        // URL to fetch data from

        dataSrc: function (json: WorkflowReponse) {
          component.workflowName = json.label
          // Flatten the detailCommandes array
          const flatData = json.workflowActions.flatMap((action: WorkflowActionReponse) =>
          ({
            stateOrder: action.stateOrder,
            etat: action.beforeStep,
            profil: action.profil.libelle,
            idWorkflowAction: action.idWorkflowAction,
            dateCreation: action.dateCreation
          })

          );
          return flatData;
        }



      },
      columns: [
        {
          title: 'Ordres', data: 'stateOrder',
          render: function (data: any) {
            return `# ${data}`
          }
        },
        {
          title: 'Etapes', data: 'etat',
        },
        {
          title: 'Profil', data: 'profil'
        },

        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idWorkflowAction',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => {
            return `
      <button class="btn btn-link-primary btn-delete" title="Delete" id="${data}">
          <i class="ti ti-trash"></i>
      </button>
    `;
          }
        }
      ],
      responsive: true,
      select: false,

    };


  }
  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#workflowTable tbody').on('click', '.btn-delete', (event) => {
        this.confirm(event.currentTarget.id);
      });

    });


  }

  confirm(id: any) {
    alertConfirm('Voulez-vous supprimer ?', () => {
      this.onDelete(id);
    });

  }

  onDelete(id: any) {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;

    this.workflowService.deleteWorkflowAction(id).subscribe(
      (response: WorkflowActionReponse) => {
        this.isLoading = false;
        this.hasError = false;
        this.hasSuccess = true;
        this.successMessage = 'Suppression effectuée avec succès.';
        this.showNotification('success', this.successMessage);
        this.initializeDataTable();
      },
      (error: any) => {
        this.hasError = true;
        this.isLoading = false;
        this.hasSuccess = false;
        this.errorMessage = error.error.message;
        this.showNotification('error', this.errorMessage);
      }
    );

  }
  showNotification(type: string, message: string): void {
    this.notifier.getConfig().position = { horizontal: { position: 'right', distance: 12 }, vertical: { position: 'top', distance: 12, gap: 10 } };
    console.log(type, message);

    this.notifier.notify(type, message);
  }
}
