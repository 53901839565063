import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationWorkflowComponent } from './creation-workflow/creation-workflow.component';
import { DetailWorkflowComponent } from './detail-workflow/detail-workflow.component';
import { ListeWorkflowComponent } from './liste-workflow/liste-workflow.component';
import { WorkflowActionComponent } from './workflow-action/workflow-action.component';

const routes: Routes = [
  {
    path: 'liste-workflow',
    component: ListeWorkflowComponent
  },
  {
    path: 'creation-workflow',
    component: CreationWorkflowComponent
  },
  {
    path: 'detail-workflow/:id',
    component: DetailWorkflowComponent
  },
  {
    path: 'workflow-actions/:id',
    component: WorkflowActionComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionWorkflowRoutingModule { }
