import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationMdComponent } from './creation-md/creation-md.component';
import { ModelsDetailComponent } from './models-detail/models-detail.component';

const routes: Routes = [

  {
    path: 'ajout-models',
    component: CreationMdComponent
  },
  {
    path: 'models-Detail',
    component: ModelsDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModelsRoutingModule { }
