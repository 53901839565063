import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationVComponent } from './creation-v/creation-v.component';
import { ListVComponent } from './list-v/list-v.component';
import { VehiculeDetailComponent } from './vehicule-detail/vehicule-detail.component';
import { ValidationVComponent } from './validation-v/validation-v.component';
import { ModificationVComponent } from './modification-v/modification-v.component';
import { ValidationVehiculeDetailComponent } from './validation-v/validation/validation-vehicule-detail.component';
import { ImportationComponent } from './importation/importation.component';

const routes: Routes = [
  {
    path: 'liste-vehicule',
    component: ListVComponent
  },
  {
    path: 'ajout-vehicule',
    component: CreationVComponent
  },
  {
    path: 'importation-vehicule',
    component: ImportationComponent
  },
  {
    path: 'detail-vehicule/:id',
    component: VehiculeDetailComponent
  },
  {
    path: 'modification-vehicule/:id',
    component: ModificationVComponent
  },
  {
    path: 'validation-vehicule',
    component: ValidationVComponent
  },
  {
    path: 'validation-vehicule-detail/:id',
    component: ValidationVehiculeDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VehiculeRoutingModule { }
