import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'round',
  standalone: true
})
export class RoundPipe implements PipeTransform {

  transform(value: number, decimalPlaces: number = 0): number {
    if (isNaN(value)) return value; // Handle NaN values
    const factor = Math.pow(10, decimalPlaces);
    return Math.ceil(value * factor) / factor;
  }
}
