<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Stock en cours">
      <form [formGroup]="stockForm">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="idCategorie">Catégorie<span class="text-danger">*</span></label>
                <ng-container *ngIf="stockForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #categorieSelect
                    [items]="categories$ | async" [selectOnTab]="true" (change)="selectCategorie($event)"
                    bindValue="idCategorie" labelForId="idCategorie" formControlName="idCategorie" [ngClass]="{
                                                                      'is-invalid':
                                                                        stockForm.get('idCategorie')?.invalid &&
                                                                        stockForm.get('idCategorie')?.touched
                                                                    }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                  </ng-select>
                </ng-container>

                <div *ngIf="stockForm.get('idCategorie')?.errors?.['required'] && stockForm.get('idCategorie')?.touched"
                  class="invalid-feedback">
                  La Catégorie est requise.
                </div>

              </div>

            </div>
            <div class="col-md-6">

            </div>
          </div>
        </div>

      </form>
      <div class="mb-5"></div>
      <div class="table-responsive">
        <table datatable [dtOptions]="dtRouterLinkOptions" id="stockTable" class="table  row-border table-hover">
        </table>
      </div>

    </app-card>
  </div>
</div>