import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationStockComponent } from './creation-stock/creation-stock.component';
import { StockEncoursComponent } from './stock-encours/stock-encours.component';

const routes: Routes = [

  {
    path: 'ajout-stock',
    component: CreationStockComponent
  },
  {
    path: 'stock-encours',
    component: StockEncoursComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionStockRoutingModule { }
