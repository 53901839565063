<div class="row">
  <div class="col-xl-8">
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-auto">
            <h3>Courbe représentant les chiffres d’affaires</h3>
          </div>
          <div class="col-auto">
            <h3> <i class="feather icon-trending-down text-danger mx-2"></i>{{ total | formatNumber }}</h3>
          </div>
        </div>
        <p class="mb-3">Courbe présentant les chiffres d’affaires (vente de véhicules, location de véhicules et ventes
          diverses)</p>
        <div class="row align-items-center mb-3">
          @for (item of data; track item) {
          <div class="col-auto mb-3">
            <div class="d-inline-flex align-items-center">
              <div class="avtar {{ item.background }}">
                <i class="{{ item.icons }}"></i>
              </div>
              <h4 class="mb-0 ms-2">{{ item.value }}</h4>
            </div>
          </div>
          }
        </div>
      </div>
      <div class="accountChart">
        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions" [responsive]="chartOptions.responsive" [stroke]="chartOptions.stroke"
          [colors]="preset" [fill]="chartOptions.fill" [tooltip]="chartOptions.tooltip"></apx-chart>
      </div>
    </div>
    <div class="row">
      @for (card of cards; track card) {
      <div class="col-md-6">
        <div class="card order-card" [ngClass]="card.color">
          <div class="card-body">
            <h5 class="text-white">{{ card.title }}</h5>
            <h3 class="text-white">{{ card.amount }}</h3>
            <p class="m-b-0">{{ card.text }}</p>
            <i class="material-icons-two-tone d-block f-46 card-icon text-white">{{ card.icon }}</i>
          </div>
        </div>
      </div>
      }
    </div>

  </div>
  <div class="col-xl-4">
    <div class="card flat-card">
      <div class="row-table">
        @for (list of rowTable1; track list) {
        <div class="col-sm-6 card-body w-50 br">
          <div class="row g-1">
            <div class="col-4">
              <div class="b-icons bg-light-primary">
                <i class="material-icons-two-tone text-secondary">{{ list.icon }}</i>
              </div>
            </div>
            <div class="col-8 text-md-center">
              <h5>{{ list.value }}</h5>
              <span>{{ list.ship }}</span>
            </div>
          </div>
        </div>
        }
      </div>
      <div class="row-table">
        @for (list of rowTable; track list) {
        <div class="col-sm-6 card-body w-50 br">
          <div class="row g-1">
            <div class="col-4">
              <div class="b-icons bg-light-primary">
                <i class="material-icons-two-tone text-secondary">{{ list.icon }}</i>
              </div>
            </div>
            <div class="col-8 text-md-center">
              <h5>{{ list.value }}</h5>
              <span>{{ list.ship }}</span>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
    <div class="card table-card">
      <div class="card-header borderless">
        <h5>Total Revenue</h5>
      </div>
      <div class="card-body px-0 py-0">
        <div class="table-responsive">
          <ng-scrollbar style="height: 240px; position: relative" visibility="hover">
            <table class="table table-hover mb-0">
              <tbody>
                @for (task of revenueList; track task) {
                <tr>
                  <td><i class="fas fa-caret-up f-24" [ngClass]="task.color"></i></td>
                  <td>{{ task.name }}</td>
                  <td>
                    <h6 [ngClass]="task.color">{{ task.percentage }}</h6>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </ng-scrollbar>
        </div>
      </div>
    </div>

  </div>
  <div class="card table-card">
    <div class="card-header">
      <h5>Les 5 top véhicules</h5>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <ng-scrollbar style="height: 310px; position: relative" visibility="hover" track="all">
          <table class="table table-hover table-borderless">
            <thead>
              <tr>
                <th>Véhicule</th>
                <th>Quantité</th>
                <th class="text-end">Montant</th>
              </tr>
            </thead>
            <tbody>
              @for (task of tableList; track task) {
              <tr>
                <td>{{ task.name }}</td>
                <td>{{ task.quantity }}</td>
                <td class="text-end">{{ task.amount | formatNumber }}</td>
              </tr>
              }
            </tbody>
          </table>
        </ng-scrollbar>
      </div>

    </div>
  </div>
</div>