import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UtilisateurModele } from '../modeles/utilisateur.modele';
import { UtilisateurReponse } from '../reponses/utilisateur.reponse';
import { UtilisateurService } from '../services/utilisateur.service';
import { ListeUtilisateurComponent } from "../liste-utilisateur/liste-utilisateur.component";
import { ProfilReponse } from '../../gestion-profil/reponses/profil.reponse';
import { ProfilService } from '../../gestion-profil/services/profil.service';

@Component({
  selector: 'app-creation-utilisateur',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule, FileUploadModule, ReactiveFormsModule, ListeUtilisateurComponent],
  templateUrl: './creation-utilisateur.component.html',
  styleUrl: './creation-utilisateur.component.scss'
})
export class CreationUtilisateurComponent implements OnInit {
  clientForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  profils: ProfilReponse[] = [];
  showForm: boolean = false;
  buttonName: string = '+';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private profilService: ProfilService,
    private utilisateurService: UtilisateurService
  ) {
    this.clientForm = this.fb.group({
      nom: ['', [Validators.required]],
      password: [null],
      prenoms: [null, [Validators.required]],
      email: [null, [Validators.required]],
      idProfil: [null, [Validators.required]],
      telephone: [null, [Validators.required]],
      idAdmin: [null]
    });
  }

  handlePlusClick() {
    this.showForm = !this.showForm;
    this.buttonName = this.showForm ? '-' : '+';
  }

  onReset() {
    this.clientForm.reset();
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    if (this.clientForm.get('idAdmin')?.value == "") {
      this.isLoading = true;
      this.hasError = false;
      this.hasSuccess = false;
      if (this.clientForm.valid) {
        const payload: UtilisateurModele = this.clientForm.value;
        this.utilisateurService.create(payload).subscribe(
          (response: UtilisateurReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.hasError = false;
            this.successMessage = 'Utilisateur ajouté avec succès.';
            this.clientForm.reset();
          },
          (error: any) => {
            this.hasError = true;
            this.isLoading = false;
            this.hasSuccess = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de la création du Utilisateur', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    } else {
      this.onSubmitEdit();
    }
  }



  onSubmitEdit() {
    console.log(this.clientForm.get('idAdmin')?.value);

    this.isLoading = true;
    if (this.clientForm.valid) {
      const idAdmin = this.clientForm.get('idAdmin')?.value;
      this.clientForm.get('idAdmin')?.setValue("");
      const payload: UtilisateurModele = this.clientForm.value;
      payload.idAdmin = null;
      this.utilisateurService.update(payload, idAdmin).subscribe(
        (response: UtilisateurReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Utilisateur modifiée avec succès.';
          this.clientForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.hasSuccess = false;
          this.errorMessage = error.error.message;
          console.error("Erreur lors de la modification de l'Utilisateur", error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {
    this.profilService.getAll().subscribe(
      response => {
        this.profils = response;
      }
    );
  }
}
