// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { TableArticleComponent } from "../table-article/table-article.component";
import { CategorieReponse } from '../../gestion-categorie/reponse/categorie.reponse';
import { EMPTY, Observable } from 'rxjs';
import { CategorieService } from '../../gestion-categorie/service/categorie.service';
import { ProduitService } from '../service/produit.service';
import { ProduitReponse } from '../reponse/produit.reponse';
import { ProduitModele } from '../modeles/produit.modele';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-creation-article',
  standalone: true,
  imports: [CommonModule, SharedModule, NgSelectModule, TableArticleComponent],
  templateUrl: './creation-article.component.html',
  styleUrl: './creation-article.component.scss'
})
export class CreationArticleComponent implements OnInit {
  produitForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  categories$: Observable<CategorieReponse[]> = EMPTY;
  showForm: boolean = false;
  buttonName: string = '+';
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private categorieService: CategorieService,
    private produitService: ProduitService
  ) {
    this.categories$ = this.categorieService.getAll();
    this.produitForm = this.fb.group({
      libelle: ['', [Validators.required]],
      code: [[''], [Validators.required]],
      idCategorie: [[''], [Validators.required]],
      description: [''],
      idProduit: [''],
    });
  }

  onReset() {
    this.produitForm.reset();
  }
  handlePlusClick() {
    this.showForm = !this.showForm;
    this.buttonName = this.showForm ? '-' : '+';
  }

  selectCategorie(produitReponse: ProduitReponse): void {
    this.produitForm.get('idCategorie')?.setValue(produitReponse.idCategorie);
  }
  customSearch(term: string, item: any): boolean {
    if (!term) return true;

    term = term.toLowerCase();

    const searchableFields = ['libelle', 'nom', 'prenoms', 'immatriculationProvisoire', 'valeur']; // Liste des champs à rechercher

    return searchableFields.some(field =>
      item[field] && item[field].toLowerCase().includes(term)
    );
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    if (this.produitForm.get('idProduit')?.value == "") {
      this.hasError = false;
      this.hasSuccess = false;
      if (this.produitForm.valid) {
        this.isLoading = true;
        const payload: ProduitModele = this.produitForm.value;

        this.produitService.create(payload).subscribe(
          (response: ProduitReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.hasError = false;
            this.successMessage = 'Produit ajouté avec succès.';
            this.produitForm.reset();
          },
          (error: any) => {
            this.hasError = true;
            this.hasSuccess = false;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de la création du produit', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    } else {
      this.onSubmitEdit();
    }
  }


  onSubmitEdit() {
    this.hasError = false;
    this.hasSuccess = false;
    if (this.produitForm.valid) {
      this.isLoading = true;
      const payload: ProduitModele = this.produitForm.value;

      this.produitService.update(payload, this.produitForm.get('idProduit')?.value).subscribe(
        (response: ProduitReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.hasError = false;
          this.successMessage = 'Modification effectuée avec succès.';
          this.produitForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la modification du produit', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }
  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
