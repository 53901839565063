import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { VehiculeService } from '../services/vehicule.service';
import { MarqueReponse } from '../../marque/reponses/marque.reponse';
import { TypeReponse } from '../../type/reponses/type.reponse';
import { FournisseurReponse } from 'src/app/Pages/gestion-partenaire/fournisseur/reponse/fournisseur.reponse';
import { MarqueService } from '../../marque/services/marque.service';
import { TypeService } from '../../type/services/type.service';
import { FournisseurService } from 'src/app/Pages/gestion-partenaire/fournisseur/service/fournisseur.service';
import { ModelReponse } from '../../models/reponses/model.reponse';
import { ModelService } from '../../models/services/model.service';
import { VehiculeModele } from '../modeles/vehicule.modele';
import { Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-creation-v',
  standalone: true,
  imports: [CommonModule, NgSelectModule, SharedModule],
  templateUrl: './creation-v.component.html',
  styleUrl: './creation-v.component.scss',
})
export class CreationVComponent {
  vehiculeForm: FormGroup;
  formStep = 0;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  marques: MarqueReponse[] = [];
  types: TypeReponse[] = [];
  categories = [
    {
      libelle: "VENTE",
      id: "V_VEHICULE"
    },
    {
      libelle: "LOCATION",
      id: "L_VEHICULE"
    },
  ];
  usages = ['PUBLIQUE', 'PRIVEE'];
  fournisseurs: FournisseurReponse[] = [];
  modeles: ModelReponse[] = [];

  constructor(
    private fb: FormBuilder,
    private vehiculeService: VehiculeService,
    private marqueService: MarqueService,
    private typeService: TypeService,
    private fournisseurService: FournisseurService,
    private modelService: ModelService,
    private router: Router

  ) {
    this.vehiculeForm = this.fb.group({
      // Etape 1
      idMarque: ['', Validators.required],
      idModele: ['', Validators.required],
      idType: ['', Validators.required],
      immatriculationProvisoire: ['', Validators.required],
      immatriculationDefinitive: [''],
      categorie: ['', Validators.required],
      idFournisseur: ['', Validators.required],
      nombrePlace: [''],


      // Etape 2
      anneeFabrication: [''],
      kilometrage: [''],
      couleur: [''],
      typeCarburant: ['', Validators.required],

      // Etape 3
      numeroCarteGrise: [''],
      chassis: ['', Validators.required],
      dateMiseCirculation: [''],
      dateCarteGrise: [''],
      puissanceFiscale: [''],
      usage: ['', Validators.required],
      mode: ['', Validators.required],

      // Etape 4

      montantTva: [0],
      dateAchat: [''],
      prixAchat: [0],
      prixVente: [0],
      prixLocation: [''],
    });
    this.vehiculeForm.get('idMarque')?.valueChanges.subscribe(value => {
      this.onMarqueChange(value);
    });
  }

  selectMarque(marqueReponse: MarqueReponse): void {
    this.vehiculeForm.get('idMarque')?.setValue(marqueReponse.idMarque);
  }
  selectModele(modeleReponse: ModelReponse): void {
    this.vehiculeForm.get('idModele')?.setValue(modeleReponse.idModele);
  }
  selectType(typeReponse: TypeReponse): void {
    this.vehiculeForm.get('idType')?.setValue(typeReponse.idType);
  }
  selectFournisseur(fournisseurReponse: FournisseurReponse): void {
    this.vehiculeForm.get('idFournisseur')?.setValue(fournisseurReponse.idFournisseur);
  }
  customSearch(term: string, item: any): boolean {
    if (!term) return true;

    term = term.toLowerCase();

    const searchableFields = ['libelle', 'nom', 'telephone', 'prenoms', 'immatriculationProvisoire', 'valeur']; // Liste des champs à rechercher

    return searchableFields.some(field =>
      item[field] && item[field].toLowerCase().includes(term)
    );
  }
  handlePlusClick() {
    this.router.navigate(['/app/gestion-vehicule/vehicules/importation-vehicule']);

  }

  onMarqueChange(selectedId: number): void {
    this.marques.forEach((marque) => {
      if (marque.idMarque === selectedId) {
        this.modeles = marque.modeles;
      }
    })
  }
  ngOnInit(): void {
    this.marqueService.getAll().subscribe(
      response => {
        this.marques = response;
      }
    );

    this.fournisseurService.getAll().subscribe(
      response => {
        this.fournisseurs = response;
      }
    );

    this.typeService.getAll().subscribe(
      response => {
        this.types = response;
      }
    );
  }

  // Naviguer entre les étapes
  nextStep() {
    if (this.formStep < 3) {
      this.formStep++;
    }
  }

  previousStep() {
    if (this.formStep > 0) {
      this.formStep--;
    }
  }

  // Vérifie si l'étape actuelle est valide
  isStepValid(): boolean {
    const stepFields = [
      ['idMarque', 'idModele', 'idType', 'categorie', 'idFournisseur'], // Étape 0
      [
        'anneeFabrication',
        'kilometrage',
        'couleur',
        'nombrePorte',
        'carburant',
      ], // Étape 1
      [
        'numeroCarteGrise',
        'chassis',
        'cylindree',
        'nombreEssieux',
        'dateCirculation',
      ], // Étape 2
      [
        'fraisDouane',
        'montantTva',
        'fraisCarteGrise',
        'dateAchat',
        'prixAchat',
      ], // Étape 3
    ];

    return stepFields[this.formStep].every(
      (field) => this.vehiculeForm.get(field)?.valid
    );
  }

  // Soumettre le formulaire une fois que tout est rempli
  onSubmit() {
    this.isLoading = true
    if (this.vehiculeForm.valid) {
      const payload = this.vehiculeForm.value;
      const vehiculePayload: VehiculeModele = {
        idMarque: payload.idMarque,
        idModele: payload.idModele,
        idType: payload.idType,
        categorie: payload.categorie,
        immatriculationProvisoire: payload.immatriculationProvisoire,
        immatriculationDefinitive: payload.immatriculationDefinitive,
        dateAchat: payload.dateAchat,
        idFournisseur: payload.idFournisseur,
        tarifVehicule: {
          prixAchat: payload.prixAchat,
          prixVente: payload.prixVente,
          prixLocation: payload.prixLocation,
          fraisDouane: payload.fraisDouane,
          montantTva: payload.montantTva,
          fraisCarteGrise: payload.fraisCarteGrise,
          autreFrais: payload.autreFrais,
        },

        carteGrise: {
          annee: payload.anneeFabrication,
          kilometrage: payload.kilometrage,
          couleur: payload.couleur,
          nombrePortes: payload.nombrePorte,
          nombrePlace: payload.carburant,
          boiteVitesse: payload.boiteVitesse,
          typeCarburant: payload.typeCarburant,
          carrosserie: payload.carrosserie,
          numeroCarteGrise: payload.numeroCarteGrise,
          dateCarteGrise: payload.dateCirculation,
          cylindree: payload.cylindree,
          puissanceFiscale: payload.puissanceFiscale,
          nombreEssieux: payload.nombreEssieux,
          dateMiseEnCirculation: payload.dateCirculation,
          mode: payload.mode,
          usage: payload.usage,
        }
      };
      this.vehiculeService.create(vehiculePayload).subscribe(
        (response) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Type de véhicule ajouté avec succès.';
          this.vehiculeForm.reset();
        },
        (error) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error("Erreur lors de l'enregistrement du véhicule", error);
        }
      );
    }
  }
}
