import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party
import { EMPTY, Observable } from 'rxjs';
import { VehiculeReponse } from '../../gestion-vehicule/vehicule/reponses/vehicule.reponse';
import { VehiculeService } from '../../gestion-vehicule/vehicule/services/vehicule.service';

import { ActivatedRoute } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { ParametreReponse } from '../../gestion-parametre/reponse/parametre.reponse';
import { ParametreService } from '../../gestion-parametre/service/parametre.service';
import { CarburantService } from '../service/carburant.service';
import { CarburantModele } from '../modeles/carburant.modele';
import { CarburantReponse } from '../reponse/carburant.reponse';
@Component({
  selector: 'app-update-carburant',
  standalone: true,
  imports: [CommonModule, NgSelectModule, SharedModule],
  templateUrl: './update-carburant.component.html',
  styleUrl: './update-carburant.component.scss'
})
export class UpdateCarburantComponent {
  maintenanceForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  vehicules$: Observable<VehiculeReponse[]> = EMPTY;
  parametres$: Observable<ParametreReponse[]> = EMPTY;
  maintenance$: Observable<CarburantReponse> = EMPTY;
  constructor(private fb: FormBuilder,
    private vehiculeService: VehiculeService,
    private parametreService: ParametreService,
    private carburantService: CarburantService,
    private route: ActivatedRoute
  ) {
    this.maintenanceForm = this.fb.group({
      dateApprovisionnement: ['', [Validators.required]],
      kilometrage: ['', [Validators.required]],
      description: ['', [Validators.required]],
      idVehicule: [null, [Validators.required]],
      quantite: [0, [Validators.required]],
      cout: [0, [Validators.required]],
      station: [null, [Validators.required]],
      lien: [''],
      extension: [''],
    });
    this.parametres$ = this.parametreService.getAllByType("STATION");
    this.vehicules$ = this.vehiculeService.getAll();
    this.maintenance$ = this.carburantService.getById(this.route.snapshot.params['id']);
    this.maintenance$.subscribe((data) => {
      this.maintenanceForm.get('dateApprovisionnement')?.setValue(data.dateApprovisionnement);
      this.maintenanceForm.get('kilometrage')?.setValue(data.kilometrage);
      this.maintenanceForm.get('quantite')?.setValue(data.quantite);
      this.maintenanceForm.get('description')?.setValue(data.description);
      this.maintenanceForm.get('idVehicule')?.setValue(data.idVehicule);
      this.maintenanceForm.get('cout')?.setValue(data.cout);
      this.maintenanceForm.get('station')?.setValue(data.station);
    });
  }

  customSearch(term: string, item: any): boolean {
    if (!term) return true;

    term = term.toLowerCase();

    const searchableFields = ['libelle', 'nom', 'prenoms', 'immatriculationProvisoire', 'valeur']; // Liste des champs à rechercher

    return searchableFields.some(field =>
      item[field] && item[field].toLowerCase().includes(term)
    );
  }

  fileChangeEvent(event: any): void {
    // Récupérer le fichier sélectionné
    const file = event.target.files[0];

    if (file) {
      // Extraire l'extension du fichier
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      this.maintenanceForm.get('extension')?.setValue(fileExtension);

      // Utiliser FileReader pour obtenir le contenu Base64
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64String = e.target.result.split(',')[1]; // Récupère le contenu Base64 sans le préfixe
        this.maintenanceForm.get('lien')?.setValue(base64String);
      };
      reader.readAsDataURL(file); // Lecture du fichier en tant que URL Base64
    }
  }


  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = true;
    const payload: CarburantModele = this.maintenanceForm.value;
    if (this.maintenanceForm.get('lien')?.value) {
      payload.document = {
        lien: this.maintenanceForm.get('lien')?.value,
        extension: this.maintenanceForm.get('extension')?.value
      }
    }
    this.carburantService.update(payload, this.route.snapshot.params['id']).subscribe(
      (response: CarburantReponse) => {
        this.isLoading = false;
        this.hasError = false;
        this.hasSuccess = true;
        this.successMessage = 'Carburant mis à jour avec succès.';
        this.maintenanceForm.reset();
      },
      (error: any) => {
        this.hasError = true;
        this.hasSuccess = false;
        this.isLoading = false;
        this.errorMessage = error.error.message;
        console.error("Erreur lors de la mise à jour", error);
      }
    );

  }

}
