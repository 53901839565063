<div class="col-sm-12">
  <div class="card shadow">
    <div class="card-body">
      <form novalidate [formGroup]="profileFrom" (ngSubmit)="onSubmit()">
        <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
          <p>{{successMessage}}</p>
        </div>
        <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
          <p>{{errorMessage}}</p>
        </div>
        <div class="form-group mb-3">
          <label for="floatingInput">Profil</label>
          <input type="text" class="form-control" style="height: 30px;" id="floatingInput"
            placeholder="Entrer un profil" formControlName="libelle">
        </div>

        <div class="row mb-3">
          <div class="col-md-5">
            <label class="form-label">Pages</label>
            <select class="multiple-select" multiple="multiple" class="form-control" formControlName="page_select">
              <option *ngFor="let page of arrayMenu" value="{{page.idParent}},{{page.libelle}}">{{page.libelle}}
              </option>
            </select>
          </div>
          <div class="col-md-1" style="margin: 40px 10px 10px 10px;">
            <div class="row mb-1">
              <button type="button" class="btn btn-primary btn-sm" (click)="onAdd()"><i
                  class="ti ti-arrow-right"></i></button>
            </div>
            <div class="row">
              <button type="button" class="btn btn-primary btn-sm" (click)="onRemoveArray()"><i
                  class="ti ti-arrow-left"></i></button>
            </div>
          </div>
          <div class="col-md-5">
            <label class="form-label">Pages selectionnées</label>
            <select class="multiple-select" multiple="multiple" class="form-control"
              formControlName="page_select_remove">
              <option *ngFor="let el of array2" value="{{el}}">{{el}}</option>
            </select>
          </div>
        </div>
        <div class="text-right">
          <button *ngIf="!isLoading" [disabled]="!profileFrom.valid" type="submit"
            class="btn btn-primary btn-sm mdi mdi-checkbox-multiple-marked-outline">Enregistrer</button>
          <button *ngIf="isLoading" class="spinner-border text-primary mdi mdi-checkbox-multiple-marked-outline"
            role="status">
            <span class="sr-only">Traitement...</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


<form novalidate [formGroup]="actionFrom">
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">

      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Ajouter une permission</h4>
        </div>
        <div class="modal-body">
          <div *ngIf="hasSuccess2" class="alert alert-success mt-2 mb-2">
            <p>{{successMessage2}}</p>
          </div>
          <div *ngIf="hasError2" class="alert alert-danger mt-2 mb-2">
            <p>{{errorMessage2}}</p>
          </div>
          <div class="row">
            <div class="col-md-5">
              <label class="form-label">Actions</label>
              <select class="multiple-select" multiple="multiple" class="form-control" formControlName="action_select">
                <option *ngFor="let act of arrayAction" value="{{act.label}},{{act.code}}">{{act.label}}
                </option>
              </select>
            </div>
            <div class="col-md-1" style="margin: 40px 10px 10px 10px;">
              <div class="row mb-1">
                <button type="button" class="btn btn-primary btn-sm" (click)="onAddAction()"><i
                    class="ti ti-arrow-right"></i></button>
              </div>
              <div class="row">
                <button type="button" class="btn btn-primary btn-sm" (click)="onRemoveArrayAction()"><i
                    class="ti ti-arrow-left"></i></button>
              </div>
            </div>
            <div class="col-md-5">
              <label class="form-label">Actions selectionnées</label>
              <select class="multiple-select" multiple="multiple" class="form-control"
                formControlName="action_select_remove">
                <option *ngFor="let el of arrayAction2" value="{{el}}">{{el}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm" (click)="onCancel()" data-dismiss="modal"><i
              class="mdi mdi-window-close"></i> Annuler</button>
          <button *ngIf="!isLoading" type="submit"
            class="btn btn-primary btn-sm mdi mdi-checkbox-multiple-marked-outline" (click)="onAddEnd()"
            [disabled]="!actionFrom.valid"><i class=""></i> Enregistrer</button>
          <button *ngIf="isLoading" class="spinner-border text-primary mdi mdi-checkbox-multiple-marked-outline"
            role="status">
            <span class="sr-only">Traitement...</span>
          </button>
        </div>
      </div>

    </div>
  </div>
</form>