import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { Subject, Subscription } from 'rxjs';
import { WebSocketService } from '../../services/websocket.service';
import { ImportationReponse } from '../../reponses/vehicule.reponse';
import { SharedModule } from "../../../../../theme/shared/shared.module";
import { CardComponent } from "../../../../../theme/shared/components/card/card.component";
import { VehiculeService } from '../../services/vehicule.service';

@Component({
  standalone: true,
  selector: 'app-table-historique',
  imports: [CommonModule, DataTablesModule, SharedModule],
  templateUrl: './table-historique.component.html',
  styleUrl: './table-historique.component.scss',
})
export class TableHistoriqueComponent implements OnInit, OnDestroy, AfterViewInit {
  dtTrigger: Subject<any> = new Subject();
  importations: ImportationReponse[] = [];
  // public props
  dtRouterLinkOptions: object = {};
  dataTableInstance: any;
  @ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;
  private subscriptions: Subscription = new Subscription();
  constructor(
    private localStorageService: SessionService,
    private datePipe: DatePipe,
    private vehiculeService: VehiculeService,
    private webSocketService: WebSocketService
  ) {


  }



  ngOnInit() {

    this.webSocketService.connect();

    // Écouter les messages
    this.webSocketService.messages$.subscribe((data: any) => {
      if (data) {
        this.dtRouterLinkOptions = {}
        this.importations.push(JSON.parse(data) as ImportationReponse);
        this.initializeDataTable2();
      }

    });

    this.initializeDataTable2();
  }
  ngOnDestroy() {
    this.webSocketService.disconnect();
  }

  rafraichir() {
    const dateDebut = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
    const dateFin = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
    this.vehiculeService.getAllImportation("VEHICULE", dateDebut, dateFin, "page=0&limit=100").subscribe((data: ImportationReponse[]) => {
      this.importations = data;
      this.initializeDataTable2();
    })
  }

  initializeDataTable2() {
    this.dtRouterLinkOptions = {
      data: this.importations,
      processing: false,
      serverSide: false,
      bDestroy: true,
      error: function (e: any, settings: any, techNote: any, message: any) {
        console.error("DataTable Error: ", message);
      },
      columns: [
        { title: 'Ligne', data: 'line' },
        { title: 'Nom fichier', data: 'libelle' },
        { title: 'Contenu', data: 'contenu' },
        { title: 'Erreur', data: 'erreur' },
        {
          title: 'Etat',
          data: 'etat',
          render: function (data: string, type: string, row: any) {
            let badgeClass = 'badge bg-light-danger rounded f-12';
            if (data === 'OK') {
              badgeClass = 'badge bg-light-success rounded f-12';
            }
            return `<span class="${badgeClass}">${data}</span>`;
          }
        },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        }
      ],
      responsive: true,
      select: false,
      rowCallback: function (row: Node, data: any) {
        if (data.etat === 'NOK') {
          $(row).css({
            'background-color': 'rgba(255, 0, 0, 0.1)', // Red shade
            'border-bottom': '2px solid white'          // White separator
          });
        } else if (data.etat === 'OK') {
          $(row).css({
            'background-color': 'rgba(0, 255, 0, 0.1)', // Green shade
            'border-bottom': '2px solid white'          // White separator
          });
        } else {
          $(row).css({
            'border-bottom': '2px solid white'          // White separator for other states
          });
        }
      }
    };
    this.dtTrigger.next(this.dtRouterLinkOptions);
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next(this.dtRouterLinkOptions);
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      $('#paiementTable tbody').on('click', '.btn-edit', (event) => {

      });
    });
  }




}

