<div class="row">
  <div class="col-sm-12">
    <app-card
      cardTitle="Paiement de facture N° {{paiementForm.get('numFacture')?.value}}  Montant Total: {{paiementForm.get('oldMontant')?.value}}">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="paiementForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="modePaiement">Moyen de paiement<span class="text-danger">*</span></label>
                <select type="text" formControlName="modePaiement" class="form-control" [ngClass]="{
                                                      'is-invalid':
                                                        paiementForm.get('modePaiement')?.invalid &&
                                                        paiementForm.get('modePaiement')?.touched
                                                    }">
                  <option value="">Sélectionner le moyen de paiement</option>
                  <option *ngFor="let param of params$ | async" [value]="param.valeur">
                    {{ param.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="paiementForm.get('modePaiement')?.errors?.['modePaiement'] && paiementForm.get('modePaiement')?.touched"
                  class="invalid-feedback">
                  Le moyen de paiement est requis.
                </div>

              </div>

            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="datePaiement">Date de paiement<span class="text-danger">*</span></label>
                    <input type="date" formControlName="datePaiement" class="form-control" id="datePaiement" [ngClass]="{
                                'is-invalid':
                                  paiementForm.get('datePaiement')?.invalid &&
                                  paiementForm.get('datePaiement')?.touched
                              }" />
                    <div *ngIf="
                                paiementForm.get('datePaiement')?.invalid &&
                                paiementForm.get('datePaiement')?.touched
                              " class="invalid-feedback">
                      <div *ngIf="paiementForm.get('datePaiement')?.errors?.['required']">
                        La date de paiement est requise.
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="montant">Montant<span class="text-danger">*</span></label>
                    <input type="text" formControlName="montant" class="form-control" id="montant" [ngClass]="{
                                                'is-invalid':
                                                  paiementForm.get('montant')?.invalid &&
                                                  paiementForm.get('montant')?.touched
                                              }" />
                    <div *ngIf="
                                                paiementForm.get('montant')?.invalid &&
                                                paiementForm.get('montant')?.touched
                                              " class="invalid-feedback">
                      <div *ngIf="paiementForm.get('montant')?.errors?.['required']">
                        Le montant est requis.
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "Enregistrer" -->
          <button type="submit" class="btn btn-primary" [disabled]="paiementForm.invalid" *ngIf="!isLoading">
            Valider
          </button>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-caisse [paiementForm]="paiementForm"></app-table-caisse>
    </app-card>
  </div>
</div>