import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { UtilisateurModele } from '../modeles/utilisateur.modele';
import { UtilisateurReponse } from '../reponses/utilisateur.reponse';


@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }

  create(payload: UtilisateurModele): Observable<UtilisateurReponse> {
    return this.http.post<any>(`${this.url}/admins`, payload, this.getHttpOptions());
  }

  update(payload: UtilisateurModele, id: any): Observable<UtilisateurReponse> {
    return this.http.put<any>(`${this.url}/admins/${id}`, payload, this.getHttpOptions());
  }

  delete(id: any): Observable<UtilisateurReponse> {
    return this.http.delete<any>(`${this.url}/admins/${id}`, this.getHttpOptions());
  }

  getById(id: any): Observable<UtilisateurReponse> {
    return this.http.get<any>(`${this.url}/admins/${id}`, this.getHttpOptions());
  }

  getAll(query?: any): Observable<UtilisateurReponse[]> {
    const url = query ? `${this.url}/admins?${query}` : `${this.url}/admins`;
    return this.http.get<any>(url, this.getHttpOptions());
  }

}
