import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { WorkflowActionModele, WorkflowModele } from '../modeles/workflow.modele';
import { WorkflowReponse, WorkflowStateReponse } from '../reponses/workflow.reponse';
import { ListeWorkflowComponent } from "../liste-workflow/liste-workflow.component";
import { ProfilReponse } from '../../gestion-profil/reponses/profil.reponse';
import { ProfilService } from '../../gestion-profil/services/profil.service';
import { DetailWorkflowComponent } from "../detail-workflow/detail-workflow.component";

@Component({
  selector: 'app-creation-workflow',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule, FileUploadModule, ReactiveFormsModule, ListeWorkflowComponent, DetailWorkflowComponent],
  templateUrl: './workflow-action.component.html',
  styleUrl: './workflow-action.component.scss'
})
export class WorkflowActionComponent implements OnInit {
  id: any;
  workflowForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  profils: ProfilReponse[] = [];
  workflow: WorkflowReponse = {} as WorkflowReponse;
  workflowStateReponse: WorkflowStateReponse[] = [];

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private workflowService: WorkflowService, private profilService: ProfilService) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.workflowForm = this.fb.group({
      profileId: ['', Validators.required],
      stepId: ['', Validators.required],
      stateOrder: ['', Validators.required]
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    if (this.workflowForm.valid) {
      const payload: WorkflowActionModele = this.workflowForm.value;
      this.workflowStateReponse.forEach((reponse: WorkflowStateReponse) => {
        if (reponse.idWorkflowState == payload.stepId) {
          payload.beforeStep = reponse.beforeStep
          payload.endpoint = this.workflow.endpoint;
          payload.idWorkflow = this.id
        }
      })

      this.createWorkflow(payload).subscribe(
        (response: WorkflowReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Workflow ajouté avec succès.';
          this.workflowForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du workflow', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête
  createWorkflow(payload: WorkflowActionModele): Observable<WorkflowReponse> {
    return this.workflowService.createWorkflowState(payload);
  }

  ngOnInit(): void {
    this.profilService.getAll().subscribe(
      response => {
        this.profils = response;
      }
    );
    this.workflowService.getStateAll().subscribe(
      response => {

        this.workflowStateReponse = response;
      }
    );
    this.workflowService.getById(this.id).subscribe(
      response => {
        this.workflow = response;
      }
    );
  }
  @Output() onCloseModel = new EventEmitter();
  //Form construct


  onClose() {
    this.onCloseModel.emit(false)
  };



}
