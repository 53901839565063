import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { VehiculeService } from '../../../services/vehicule.service';
import { DocumentReponse } from '../../../reponses/vehicule.reponse';
import { EMPTY, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Image, ModalGalleryService } from '@ks89/angular-modal-gallery';
import { alertConfirm } from 'src/app/helpers/alert-confirm';
// third party
import { NotifierModule, NotifierService } from 'angular-notifier';


@Component({
  selector: 'app-document-list',
  standalone: true,
  imports: [CommonModule, NotifierModule],
  templateUrl: './document-list.component.html',
  styleUrl: './document-list.component.scss'
})
export class DocumentListComponent {
  @Input() trash = true;
  baseUrl: any = environment.BaseUrl + '/documents/view/';
  documentReponse$: Observable<DocumentReponse[]> = EMPTY;
  id: string | null = null;
  constructor(private notifier: NotifierService, private modalGalleryService: ModalGalleryService, private vehiculeService: VehiculeService, private route: ActivatedRoute) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.documentReponse$ = this.vehiculeService.getDocumentByIdParentAndParent("VEHICULE", this.id);

  }

  // private Method
  openModal(document: any): void {
    const url = this.baseUrl + document.lien;
    // Open the URL in a new blank window/tab
    window.open(url, '_blank');
    return
    const imageToShow: Image = new Image(1, {
      img: this.baseUrl + document.lien,
      extUrl: this.baseUrl + document.lien
    });
    this.modalGalleryService.open({
      id: 400,
      images: [imageToShow],
      currentImage: imageToShow
    });
  }

  confirm(id: any) {
    alertConfirm('Voulez-vous supprimer ce document ?', () => {
      this.vehiculeService.deleteDocumentById(id).subscribe(
        response => {
          this.showNotification('success', 'Document supprimer avec succès.')
          this.documentReponse$ = this.vehiculeService.getDocumentByIdParentAndParent("VEHICULE", this.id);
        },
        error => {
          this.showNotification('error', 'Une erreur est survenue.')
        }
      );
    });

  }


  showNotification(type: string, message: string): void {
    this.notifier.getConfig().position = { horizontal: { position: 'right', distance: 12 }, vertical: { position: 'top', distance: 12, gap: 10 } };
    console.log(type, message);

    this.notifier.notify(type, message);
  }
}
