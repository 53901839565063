<div class="card" [ngClass]="cardClass" #toAnimate>
  @if (!hidHeader) {
  <div class="card-header d-flex justify-content-between align-items-center" [ngClass]="headerClass">
    <div>
      <!-- Titre et sous-titre ici -->
      <h5 *ngIf="!customHeader">{{ cardTitle }}</h5>
      <span *ngIf="!customDate">{{ CardDate }}</span>
      <ng-content select=".app-card-header" *ngIf="customHeader"></ng-content>
      <ng-content select=".app-card-subheader" *ngIf="customDate"></ng-content>
    </div>

    <!-- Bouton placé à droite -->
    <button *ngIf="showButton" class="btn btn-secondary btn-sm  square-button" (click)="onPlusClick()">{{ buttonName
      }}</button>
  </div>
  }
  <div class="card-block" [ngClass]="blockClass">
    <ng-content></ng-content>
  </div>
</div>