<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Chauffeurs" showButton="true" (plusClicked)="handlePlusClick()" buttonName="{{buttonName}}">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form *ngIf="showForm" [formGroup]="clientForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="nom">nom <span class="text-danger">*</span></label>
                <input type="text" formControlName="nom" class="form-control" id="nom" [ngClass]="{
                        'is-invalid':
                          clientForm.get('nom')?.invalid &&
                          clientForm.get('nom')?.touched
                      }" />
                <div *ngIf="
                        clientForm.get('nom')?.invalid &&
                        clientForm.get('nom')?.touched
                      " class="invalid-feedback">
                  <div *ngIf="clientForm.get('nom')?.errors?.['required']">
                    Le nom est requis.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="adresse">adresse</label>
                <input type="text" formControlName="adresse" class="form-control" id="adresse" [ngClass]="{
                    'is-invalid':
                      clientForm.get('adresse')?.invalid &&
                      clientForm.get('adresse')?.touched
                  }" />
                <div *ngIf="
                    clientForm.get('adresse')?.invalid &&
                    clientForm.get('adresse')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="clientForm.get('adresse')?.errors?.['required']">
                    La adresse est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="telephone">telephone<span class="text-danger">*</span></label>
                <input type="text" formControlName="telephone" class="form-control" id="telephone" [ngClass]="{
                    'is-invalid':
                      clientForm.get('telephone')?.invalid &&
                      clientForm.get('telephone')?.touched
                  }" />
                <div *ngIf="
                    clientForm.get('telephone')?.invalid &&
                    clientForm.get('telephone')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="clientForm.get('telephone')?.errors?.['required']">
                    La telephone est requise.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="prenoms">prenoms<span class="text-danger">*</span></label>
                <input type="text" formControlName="prenoms" class="form-control" id="prenoms" [ngClass]="{
                    'is-invalid':
                      clientForm.get('prenoms')?.invalid &&
                      clientForm.get('prenoms')?.touched
                  }" />
                <div *ngIf="
                    clientForm.get('prenoms')?.invalid &&
                    clientForm.get('prenoms')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="clientForm.get('prenoms')?.errors?.['required']">
                    La prenoms est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="email">email<span class="text-danger">*</span></label>
                <input type="email" formControlName="email" class="form-control" id="email" [ngClass]="{
                    'is-invalid':
                      clientForm.get('email')?.invalid &&
                      clientForm.get('email')?.touched
                  }" />
                <div *ngIf="
                    clientForm.get('email')?.invalid &&
                    clientForm.get('email')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="clientForm.get('email')?.errors?.['required']">
                    La email est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="nom">Numéro de permis<span class="text-danger">*</span></label>
                <input type="text" formControlName="numeroPermis" class="form-control" id="numeroPermis" [ngClass]="{
                  'is-invalid':
                    clientForm.get('numeroPermis')?.invalid &&
                    clientForm.get('numeroPermis')?.touched
                }" />
                <div *ngIf="
                                clientForm.get('numeroPermis')?.invalid &&
                                clientForm.get('numeroPermis')?.touched
                              " class="invalid-feedback">
                  <div *ngIf="clientForm.get('numeroPermis')?.errors?.['required']">
                    Le numero de permis est requis.
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "Enregistrer" -->
          <button type="submit" class="btn btn-primary" [disabled]="clientForm.invalid"
            *ngIf="!clientForm.get('idChauffeur')?.value&&!isLoading">
            Enregistrer
          </button>

          <!-- Bouton "Modifier" -->
          <div class="row">
            <div class="col text-right"> <!-- Added a column to align buttons to the right -->
              <button type="submit" class="btn btn-primary me-2" [disabled]="clientForm.invalid || isLoading"
                *ngIf="clientForm.get('idChauffeur')?.value">
                Modifier
              </button>

              <button class="btn btn-secondary" type="button" (click)="onReset()"
                *ngIf="clientForm.get('idChauffeur')?.value && !isLoading">
                Annuler
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-chauffeur [clientForm]="clientForm" (handlePlusClick)="handlePlusClick()"></app-table-chauffeur>
    </app-card>
  </div>
</div>