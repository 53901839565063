import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { EMPTY, Observable } from 'rxjs';
import { WorkflowService } from '../services/workflow.service';
import { WorkflowModele } from '../modeles/workflow.modele';
import { WorkflowReponse, WorkflowStateReponse } from '../reponses/workflow.reponse';
import { ListeWorkflowComponent } from "../liste-workflow/liste-workflow.component";


@Component({
  selector: 'app-creation-workflow',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule, FileUploadModule, ReactiveFormsModule, ListeWorkflowComponent],
  templateUrl: './creation-workflow.component.html',
  styleUrl: './creation-workflow.component.scss'
})
export class CreationWorkflowComponent implements OnInit {
  workflowStateReponse$: Observable<WorkflowStateReponse[]> = EMPTY;
  workflowForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;

  constructor(private fb: FormBuilder, private workflowService: WorkflowService) {
    this.workflowStateReponse$ = this.getWorkflowState();
    this.workflowForm = this.fb.group({
      endpoint: ['', Validators.required],
      label: ['', [Validators.required, Validators.minLength(1)]],
      description: ['', Validators.required]
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    if (this.workflowForm.valid) {
      const payload: WorkflowModele = this.workflowForm.value;
      this.createWorkflow(payload).subscribe(
        (response: WorkflowReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Workflow ajouté avec succès.';
          this.workflowForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du workflow', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête
  createWorkflow(payload: WorkflowModele): Observable<WorkflowReponse> {
    return this.workflowService.create(payload);
  }

  ngOnInit(): void {

  }
  @Output() onCloseModel = new EventEmitter();
  //Form construct


  onClose() {
    this.onCloseModel.emit(false)
  };

  getWorkflowState(): Observable<WorkflowStateReponse[]> {
    return this.workflowService.getParamsWorkflowState("WORKFLOW_ENDPOINT");
  }

}
