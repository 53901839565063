// Angular import
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party
import 'pdfjs-dist/build/pdf.worker.entry';
// After:
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as pdfjsLib from 'pdfjs-dist';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { ParametreReponse } from '../../gestion-vehicule/vehicule/reponses/vehicule.reponse';
import { EMPTY, Observable } from 'rxjs';
import { PaiementService } from '../../gestion-caisse/service/paiement.service';
import { RapportService } from '../service/rapport.service';
import { RapportReponse } from '../reponse/rapport.reponse';
import { WorkflowStateReponse } from '../../gestion-workflow/reponses/workflow.reponse';
import { WorkflowService } from '../../gestion-workflow/services/workflow.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SafePipe } from 'src/app/helpers/safe.pipe';


@Component({
  selector: 'app-rapport',
  standalone: true,
  imports: [CommonModule, SharedModule, PdfViewerModule, SafePipe],
  templateUrl: './rapport.component.html',
  styleUrl: './rapport.component.scss'
})
export class RapportComponent {
  @ViewChild('pdfCanvas', { static: true }) pdfCanvas!: ElementRef<HTMLCanvasElement>;
  paiementForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  numFacture: any = ""
  params$: Observable<ParametreReponse[]> = EMPTY;
  params2$: Observable<ParametreReponse[]> = EMPTY;
  selectedButton: string = '';
  pdfUrl: SafeResourceUrl | null = null;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private paiementService: PaiementService,
    private rapportService: RapportService,
    private workflowService: WorkflowService,
    private sanitizer: DomSanitizer
  ) {

    this.params$ = this.paiementService.getAllMoyenPaiement("TYPE_RAPPORT");
    this.params2$ = this.paiementService.getAllMoyenPaiement("ETAT_RAPPORT");
    this.paiementForm = this.fb.group({
      etat: ['', [Validators.required]],
      categorie: ['', [Validators.required]],
      dateDebut: ['', [Validators.required]],
      dateFin: ['', [Validators.required]],
    });

  }

  // Méthode appelée à la soumission du formulaire
  onSubmit(selectedButton: string) {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.paiementForm.valid) {
      const query = `type=${this.paiementForm.value.categorie}&etat=${this.paiementForm.value.etat}&startDate=${this.paiementForm.value.dateDebut}&endDate=${this.paiementForm.value.dateFin}&extension=${this.selectedButton}`;
      this.rapportService.getAll(query).subscribe(
        (response: RapportReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Rapport ajouté avec succès.';
          if (response.extension == "xlsx") {
            this.downloadExcel(response.valeur);
          } else {
            this.loadPdfFromBase64(response.valeur);
          }
          this.paiementForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du paiement', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête

  loadPdfFromBase64(base64Pdf: string) {
    const pdfBlobUrl = this.rapportService.getPdfUrl(base64Pdf);
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfBlobUrl);

  }



  loadPdf(base64String: string) {
    const pdfData = atob(base64String); // Decode base64
    const loadingTask = pdfjsLib.getDocument({ data: pdfData });

    loadingTask.promise.then(pdf => {
      pdf.getPage(1).then(page => {
        const viewport = page.getViewport({ scale: 1 });
        const canvas = this.pdfCanvas.nativeElement;
        const context = canvas.getContext('2d')!;

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        page.render(renderContext);
      });
    });
  }

  downloadExcel(base64String: string) {
    const binaryString = atob(base64String); // Decode base64
    const binaryLength = binaryString.length;
    const bytes = new Uint8Array(binaryLength);

    for (let i = 0; i < binaryLength; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const workbook = XLSX.read(bytes, { type: 'array' });
    const excelBlob = this.workbookToBlob(workbook);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelBlob);
    link.download = 'file.xlsx';
    link.click();
  }


  workbookToBlob(workbook: XLSX.WorkBook): Blob {
    const wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
    const wbout = XLSX.write(workbook, wopts);
    return new Blob([wbout], { type: 'application/octet-stream' });
  }
}
