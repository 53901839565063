import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationVenteComponent } from './creation-vente/creation-vente.component';
import { TableVenteComponent } from './table-vente/table-vente.component';
import { VentePieceDetailComponent } from './detail/vente-piece-detail.component';

const routes: Routes = [

  {
    path: 'creation-vente-piece',
    component: CreationVenteComponent
  },
  {
    path: 'liste-vente-piece',
    component: TableVenteComponent
  },
  {
    path: 'detail-vente-piece/:id',
    component: VentePieceDetailComponent
  },



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionVenteRoutingModule { }
