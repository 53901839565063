import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { VenteVehiculeReponse } from '../../../vente-vehicule/reponse/vente.vehicule.reponse';
import { VenteVehiculeService } from '../../../vente-vehicule/service/vente.vehicule.service';
import { ChauffeurReponse } from 'src/app/Pages/gestion-chauffeur/reponse/chauffeur.reponse';
import { ChauffeurService } from 'src/app/Pages/gestion-chauffeur/service/chauffeur.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { VenteVehiculeModele } from '../../../vente-vehicule/modeles/vente.vehicule.modele';
import { FormatNumberPipe } from 'src/app/helpers/format-number';
import { Config } from 'src/app/helpers/config';
import e from 'express';

@Component({
  selector: 'app-validation-location-vehicule-detail',
  standalone: true,
  imports: [CommonModule, FormatNumberPipe, NgSelectModule, SharedModule],
  templateUrl: './validation-location-vehicule-detail.component.html',
  styleUrl: './validation-location-vehicule-detail.component.scss',
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class ValidationLocationVehiculeDetailComponent implements OnInit {
  selectedOption: any;
  venteForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  // public props
  id: string | null = null;
  vehicule$!: Observable<VenteVehiculeReponse | null>;
  chauffeurList$: Observable<ChauffeurReponse[]> = EMPTY;
  chauffeurSelected: ChauffeurReponse = {} as ChauffeurReponse;
  activatedChauffeurs: any[] = [];
  constructor(public router: Router, private route: ActivatedRoute,
    private venteVehiculeService: VenteVehiculeService, private fb: FormBuilder, private chauffeurService: ChauffeurService) {

    this.venteForm = this.fb.group({
      tasks: this.fb.array([]),
    });
    this.venteForm.get('montantChauffeur')?.disable();
  }



  get tasks(): FormArray {
    return this.venteForm.get('tasks') as FormArray;
  }

  loadTasks(v: any) {
    this.tasks.clear();
    v.detailCommandes.forEach((task: any) => {
      this.tasks.push(
        this.fb.group({
          idDetailCommande: [task.idDetailCommande], // Identifiant unique de la commande
          idChauffeur: [task.idChauffeur || null],   // Pré-remplissage possible
        })
      );
    });
  }
  getTaskIndexById(idDetailCommande: number): number {
    return this.tasks.value.findIndex(
      (task: any) => task.idDetailCommande === idDetailCommande
    );
  }
  trackByFn(index: number, item: any): number {
    return item.idDetailCommande;
  }
  selectChauffeur(event: any, idDetailCommande: number) {
    const taskIndex = this.tasks.value.findIndex(
      (task: any) => task.idDetailCommande === idDetailCommande
    );
    if (taskIndex !== -1) {
      this.tasks.at(taskIndex).get('idChauffeur')?.setValue(event);
    }

  }

  ngOnInit() {
    this.chauffeurList$ = this.findChauffeur();
    this.chauffeurList$.subscribe((chauffeurs: any[]) => {
      this.activatedChauffeurs = chauffeurs.filter(v => v.etat === 'ACTIVATED');
    });

    this.id = this.route.snapshot.paramMap.get('id');
    this.findDetail();
  }
  // selectChauffeur(chauffeurReponse: ChauffeurReponse): void {
  //   this.chauffeurSelected = chauffeurReponse;
  // }

  findDetail() {
    this.vehicule$ = this.venteVehiculeService.getById(this.id);
    this.vehicule$.subscribe((v) => {
      this.loadTasks(v);
    });
  }


  calculMontant(item: any) {
    return Config.calculateTotalPrice((parseFloat(item.prixUnitaire) || 0), Config.calculateDaysBetweenDates(item.dateDebut, item.dateFin));
  }

  calculMontantChauffeur(item: any) {
    return Config.calculateTotalPrice((parseFloat(item.montantChauffeur) || 0), Config.calculateDaysBetweenDates(item.dateDebut, item.dateFin));
  }
  nombreJour(item: any) {
    return Config.calculateDaysBetweenDates(item.dateDebut, item.dateFin)
  }

  getEtat(etat: any) {
    if (etat === 'ACTIVATED') {
      return 'VALIDÉ';
    } else if (etat === 'PENDING') {
      return 'EN ATTENTE DE VERIFICATION';
    } else if (etat === 'PRE_CONFIRMED') {
      return 'EN ATTENTE DE CONFIRMATION';
    }
  }

  findChauffeur(): Observable<ChauffeurReponse[]> {
    return this.chauffeurService.getAll();
  }


  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Update the state of the VenteVehicule (Location) specified by `this.id` with the selected chauffeur.
   * If the form is valid and the payload is valid, the state is updated and the location is reloaded.
   * If the form is invalid or the payload is invalid, an error message is displayed.
   */
  /******  fe01557a-3194-4070-acbb-26cb0ef49673  *******/
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    this.vehicule$.subscribe((data: any) => {
      const payload: VenteVehiculeModele = data;
      // Ensure detailCommandes array exists and has at least one element
      if (payload.detailCommandes && payload.detailCommandes.length > 0) {
        payload.detailCommandes.forEach((detail: any) => {
          if (detail.driver == 'Avec chauffeur' && detail.etat == "PENDING") {
            const matchingTask = this.tasks.value.find((task: any) => task.idDetailCommande === detail.idDetailCommande);
            if (matchingTask) {
              detail.idChauffeur = matchingTask.idChauffeur.idChauffeur; // Ajouter l'idChauffeur à l'objet detail
            }
          }
        });
        payload.detailCommandes.forEach((detail: any) => {
          if (detail.driver == 'Avec chauffeur' && detail.idChauffeur == null && detail.etat == "PENDING") {
            this.hasError = true;
            this.isLoading = false;
            this.errorMessage = 'Veuillez choisir un chauffeur';
            return;
          }
        })
        this.isLoading = true; // Set loading state before making the request

        this.venteVehiculeService.updateState(this.id, payload).subscribe(
          (response: VenteVehiculeReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.successMessage = 'Etat de réservation effectué avec succès.';
            this.venteForm.reset(); // Reset the form after successful creation
            this.findDetail();
            window.location.reload();
          },
          (error: any) => {
            this.hasError = true;
            this.isLoading = false;
            this.errorMessage = error.error?.message || 'Une erreur est survenue'; // Default message if none provided
            console.error('Erreur lors de la création de la Location', error);
          }
        );
      } else {
        this.hasError = true;
        this.errorMessage = 'Aucune commande détaillée trouvée.';
        console.error('Payload detailCommandes is empty or not defined', payload);
      }
    });
  }
}
