// Angular Imports
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// project import
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';

const routes: Routes = [
  {
    path: 'app',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./Pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'gestion-location-vente',
        loadChildren: () =>
          import('./Pages/gestion-location-vente/gestion-location-vente.module').then(
            (m) => m.GestionLocationVenteModule
          ),
      },
      {
        path: 'gestion-profil',
        loadChildren: () =>
          import('./Pages/gestion-profil/gestion-profil.module').then(
            (m) => m.GestionProfilModule
          ),
      },
      {
        path: 'gestion-utilisateur',
        loadChildren: () =>
          import('./Pages/gestion-utilisateur/gestion-utilisateur.module').then(
            (m) => m.GestionUtilisateurModule
          ),
      },
      {
        path: 'gestion-parametre',
        loadChildren: () =>
          import('./Pages/gestion-parametre/gestion-parametre.module').then(
            (m) => m.GestionParametreModule
          ),
      },
      {
        path: 'gestion-workflow',
        loadChildren: () =>
          import('./Pages/gestion-workflow/gestion-workflow.module').then(
            (m) => m.GestionWorkflowModule
          ),
      },
      {
        path: 'gestion-partenaire',
        loadChildren: () =>
          import('./Pages/gestion-partenaire/gestion-patenaire.module').then(
            (m) => m.GestionPartenaireModule
          ),
      },
      {
        path: 'gestion-vehicule',
        loadChildren: () =>
          import('./Pages/gestion-vehicule/gestion-vehicule.module').then(
            (m) => m.GestionVehiculeModule
          ),
      },
      {
        path: 'gestion-chauffeur',
        loadChildren: () =>
          import('./Pages/gestion-chauffeur/gestion-chauffeur.module').then(
            (m) => m.GestionChauffeurModule
          ),
      },
      {
        path: 'gestion-carburant',
        loadChildren: () =>
          import('./Pages/gestion-carburant/gestion-carburant.module').then(
            (m) => m.GestionCarburantModule
          ),
      },
      {
        path: 'gestion-maintenance',
        loadChildren: () =>
          import('./Pages/gestion-maintenance/gestion-maintenance.module').then(
            (m) => m.GestionMaintenanceModule
          ),
      },
      {
        path: 'gestion-compagnie',
        loadChildren: () =>
          import('./Pages/gestion-compagnie-assurance/gestion-compagnie.module').then(
            (m) => m.GestionCompagnieModule
          ),
      },
      {
        path: 'gestion-visite',
        loadChildren: () =>
          import('./Pages/gestion-visite/gestion-visite.module').then(
            (m) => m.GestionVisiteModule
          ),
      },
      {
        path: 'gestion-documents',
        loadChildren: () =>
          import('./Pages/gestion-document/gestion-document.module').then(
            (m) => m.GestionDocumentModule
          ),
      },
      {
        path: 'gestion-assurance',
        loadChildren: () =>
          import('./Pages/gestion-assurance/gestion-assurance.module').then(
            (m) => m.GestionAssuranceModule
          ),
      },
      {
        path: 'gestion-centre',
        loadChildren: () =>
          import('./Pages/gestion-centre/gestion-centre.module').then(
            (m) => m.GestionCentreModule
          ),
      },
      {
        path: 'gestion-compte',
        loadChildren: () =>
          import('./Pages/auth/account/account.module').then(
            (m) => m.AccountModule
          ),
      },
      {
        path: 'gestion-article',
        loadChildren: () =>
          import('./Pages/gestion-article/gestion-article.module').then(
            (m) => m.GestionArticleModule
          ),
      },
      {
        path: 'gestion-categorie',
        loadChildren: () =>
          import('./Pages/gestion-categorie/gestion-categorie.module').then(
            (m) => m.GestionCategorieModule
          ),
      },
      {
        path: 'gestion-vente-piece',
        loadChildren: () =>
          import('./Pages/gestion-vente-piece/gestion-vente.module').then(
            (m) => m.GestionVenteModule
          ),
      },
      {
        path: 'gestion-stock',
        loadChildren: () =>
          import('./Pages/gestion-stock/gestion-stock.module').then(
            (m) => m.GestionStockModule
          ),
      },
      {
        path: 'gestion-caisse',
        loadChildren: () =>
          import('./Pages/gestion-caisse/gestion-caisse.module').then(
            (m) => m.GestionCaisseModule
          ),
      },

      {
        path: 'gestion-suppression',
        loadChildren: () =>
          import('./Pages/gestion-suppression/gestion-suppression.module').then(
            (m) => m.GestionSuppressionModule
          ),
      },
      {
        path: 'gestion-rapport',
        loadChildren: () =>
          import('./Pages/gestion-rapport/gestion-rapport.module').then(
            (m) => m.GestionRapportModule
          ),
      },
      {
        path: 'gestion-ville',
        loadChildren: () =>
          import('./Pages/gestion-ville/gestion-ville.module').then(
            (m) => m.GestionVilleModule
          ),
      },
    ],
  },

  {
    path: 'auth',
    component: GuestComponent,
    loadChildren: () =>
      import('./Pages/auth/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
