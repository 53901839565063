<div class="row">
  <div class="col-md-12">
    <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
      <p>{{successMessage}}</p>
    </div>
    <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
      <p>{{errorMessage}}</p>
    </div>
    <form [formGroup]="maintenanceForm" (ngSubmit)="onSubmit()">
      <app-card cardTitle="Modification">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="idVehicule">Référence au véhicule<span class="text-danger">*</span></label>
              <ng-container *ngIf="maintenanceForm">
                <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                  [items]="vehicules$ | async" [selectOnTab]="true" (change)="selectClient($event)"
                  bindValue="idVehicule" labelForId="idVehicule" formControlName="idVehicule" [ngClass]="{
                                              'is-invalid':
                                                maintenanceForm.get('idVehicule')?.invalid &&
                                                maintenanceForm.get('idVehicule')?.touched
                                            }">
                  <!-- Template pour le label affiché dans le champ sélectionné -->
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.immatriculationProvisoire }} {{ item.modele?.libelle }} {{ item.marque?.libelle }}
                  </ng-template>

                  <!-- Template pour les options dans la liste déroulante -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.immatriculationProvisoire }} {{ item.modele?.libelle }} {{ item.marque?.libelle }}
                  </ng-template>

                </ng-select>
              </ng-container>

              <div
                *ngIf="maintenanceForm.get('idVehicule')?.errors?.['required'] && maintenanceForm.get('idVehicule')?.touched"
                class="invalid-feedback">
                Le vehicule est requis.
              </div>

            </div>
            <div class="form-group">
              <label class="form-label" for="dateFin">Date d'approvisionnement<span class="text-danger">*</span></label>
              <input type="date" id="dateApprovisionnement" formControlName="dateApprovisionnement" class="form-control"
                required />
              <div
                *ngIf="maintenanceForm.get('dateApprovisionnement')?.errors?.['required'] && maintenanceForm.get('dateApprovisionnement')?.touched"
                class="invalid-feedback">
                La date d'approvisionnement est requise.
              </div>

            </div>
            <div class="form-group">
              <label class="form-label" for="numeroPolice">Kilomètres parcourus <span
                  class="text-danger">*</span></label>
              <input type="number" id="kilometrage" formControlName="kilometrage" class="form-control" required />
              <div
                *ngIf="maintenanceForm.get('motif')?.errors?.['required'] && maintenanceForm.get('kilometrage')?.touched"
                class="invalid-feedback">
                Le kilometrage est requis.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="valeur">Nom de la station<span class="text-danger">*</span></label>
              <ng-container *ngIf="maintenanceForm">
                <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                  [items]="parametres$ | async" [selectOnTab]="true" (change)="selectClient($event)" bindValue="valeur"
                  labelForId="valeur" formControlName="station" [ngClass]="{
                                                                'is-invalid':
                                                                  maintenanceForm.get('station')?.invalid &&
                                                                  maintenanceForm.get('station')?.touched
                                                              }">
                  <!-- Template pour le label affiché dans le champ sélectionné -->
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.libelle }}
                  </ng-template>

                  <!-- Template pour les options dans la liste déroulante -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.libelle }}
                  </ng-template>
                </ng-select>
              </ng-container>
              <div
                *ngIf="maintenanceForm.get('station')?.errors?.['required'] && maintenanceForm.get('station')?.touched"
                class="invalid-feedback">
                La station est requise.
              </div>

            </div>
            <div class="form-group">
              <label class="form-label" for="quantite">Quantité <span class="text-danger">*</span></label>
              <input type="number" id="quantite" formControlName="quantite" class="form-control" />
              <div
                *ngIf="maintenanceForm.get('quantite')?.errors?.['required'] && maintenanceForm.get('quantite')?.touched"
                class="invalid-feedback">
                La quantite est requise.
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="cout">Coût <span class="text-danger">*</span> </label>
              <input type="number" id="cout" formControlName="cout" class="form-control" />
              <div *ngIf="maintenanceForm.get('cout')?.errors?.['required'] && maintenanceForm.get('cout')?.touched"
                class="invalid-feedback">
                Le coût est requis.
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <label class="form-label" for="cout">Description <span class="text-danger">*</span></label>
              <textarea id="description" rows="5" formControlName="description" class="form-control"
                required></textarea>

            </div>
          </div>
          <div class="clo-sm-12">
            <div class="form-group">
              <label class="form-label" for="fileAssurance">Facture</label>
              <input type="file" id="lien" formControlName="lien" (change)="fileChangeEvent($event)"
                class="form-control" />

            </div>
          </div>
          <div class="card-footer text-end">
            <div class="d-flex justify-content-end my-3">
              <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status"></span>
                Traitement...
              </button>

              <!-- Bouton "Enregistrer" -->
              <button type="submit" class="btn btn-primary" [disabled]="maintenanceForm.invalid" *ngIf="!isLoading">
                Modifier
              </button>
            </div>
          </div>
        </div>
      </app-card>

    </form>
  </div>
</div>