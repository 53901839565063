import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { TypeModele } from '../modeles/type.modele';
import { TypeReponse } from '../reponses/type.reponse';
import { TypeService } from '../services/type.service';
import { ListeTComponent } from "../liste-t/liste-t.component";

@Component({
  selector: 'app-creation-t',
  standalone: true,
  imports: [CommonModule, SharedModule, ListeTComponent, RouterModule, ListeTComponent],
  templateUrl: './creation-t.component.html',
  styleUrl: './creation-t.component.scss'
})
export class CreationTComponent implements OnInit {
  typeForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  constructor(private fb: FormBuilder, private typeService: TypeService) {
    this.typeForm = this.fb.group({
      idType: [''],
      libelle: ['', [Validators.required, Validators.minLength(1)]],
      description: ['']
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    if (this.typeForm.get('idType')?.value == "") {
      this.isLoading = true;
      if (this.typeForm.valid) {
        const payload: TypeModele = this.typeForm.value;
        payload.idType = null;
        this.createType(payload).subscribe(
          (response: TypeReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.successMessage = 'Type de véhicule ajouté avec succès.';
            this.typeForm.reset();
          },
          (error: any) => {
            this.hasError = true;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de la création du type', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    } else {
      this.onSubmitEdit();
    }
  }

  // Méthode pour envoyer la requête
  createType(payload: TypeModele): Observable<TypeReponse> {
    return this.typeService.create(payload);
  }

  ngOnInit(): void {

  }

  onReset() {
    this.typeForm.reset();
  }



  onSubmitEdit() {
    this.isLoading = true;
    if (this.typeForm.valid) {
      const idType = this.typeForm.get('idType')?.value;
      this.typeForm.get('idMarque')?.setValue("");
      const payload: TypeModele = this.typeForm.value;
      payload.idType = null;
      this.typeService.update(payload, idType).subscribe(
        (response: TypeReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Type de véhicule modifié avec succès.';
          this.typeForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la modification du type', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

}
