export class Config {

  static calculateTotalPrice(price: number, days: number): number {
    return (price * days);
  }

  static calculateDaysBetweenDates(dateDebut: any, dateFin: any): number {
    const startDate: Date = new Date(dateDebut);
    const endDate: Date = new Date(dateFin);

    // Vérifier si les dates sont identiques
    if (startDate.toDateString() === endDate.toDateString()) {
      return 1; // Si les dates sont identiques, retourner 1
    }

    // Convertir les deux dates en millisecondes
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    // Calculer la différence en millisecondes
    const differenceInTime = endTime - startTime;

    // Convertir la différence en jours (1 jour = 24 * 60 * 60 * 1000 millisecondes)
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return Math.ceil(differenceInDays); // Arrondir au nombre de jours
  }


  static customizeBadgeClass(etat: string) {
    let badgeClass = 'badge bg-danger rounded f-12';
    let displayText = '';

    switch (etat) {
      case 'Pending':
        badgeClass = 'badge bg-warning rounded f-12';
        displayText = 'EN ATTENTE DE VERIFICATION';
        break;
      case 'ACTIVATED':
        badgeClass = 'badge bg-light-success rounded f-12';
        displayText = 'VALIDE';
        break;
      case 'Canceled':
        badgeClass = 'badge bg-danger rounded f-12';
        displayText = 'EN ATTENTE DE SUPPRESSION';
        break;
      case 'UPDATED':
        displayText = 'EN ATTENTE DE MISE A JOUR';
        break;
      case 'RENTAL':
        displayText = 'EN MISSION';
        break;
      case 'PENDING':
        displayText = 'EN ATTENTE DE VERIFICATION';
        break;
      case 'PRE_CONFIRMED':
        displayText = 'EN ATTENTE DE CONFIRMATION';
        break;
      case 'DELETING':
        displayText = 'EN ATTENTE DE SUPPRESSION';
        break;
      default:
        displayText = etat; // if no match found, keep the original state value as text
    }

    return `<span class="${badgeClass}">${displayText}</span>`;
  }

}
