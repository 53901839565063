import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionCategorieRoutingModule } from './gestion-categorie.routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionCategorieRoutingModule
  ]
})
export class GestionCategorieModule { }
