import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Client, Message } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private client: Client;
  private messagesSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  // Un observable pour écouter les messages reçus
  public messages$ = this.messagesSubject.asObservable();

  constructor() {
    this.client = new Client({
      brokerURL: environment.WebSocketChannel + "/ws", // Remplacez par l'URL de votre serveur WebSocket
      connectHeaders: {
        // Vous pouvez ajouter ici des en-têtes de connexion si nécessaire
      },
      // Utilisation de SockJS pour la connexion au serveur
      webSocketFactory: () => new SockJS(environment.WebSocketChannel + "/ws"), // Remplacez par l'URL de votre serveur WebSocket
      onConnect: () => {
        console.log('Connected to WebSocket server');
        // S'abonner à un canal spécifique
        this.client.subscribe('/topic/importation', (message: Message) => {
          this.handleMessage(message);
        });
      },
      onStompError: (frame) => {
        console.error('STOMP error:', frame);
      },
      onWebSocketError: (error) => {
        console.error('WebSocket error:', error);
      },
    });
  }

  // Méthode pour établir la connexion WebSocket
  connect(): void {
    this.client.activate();
  }

  // Méthode pour se déconnecter du serveur WebSocket
  disconnect(): void {
    if (this.client.connected) {
      this.client.deactivate();
    }
  }

  // Méthode pour envoyer un message sur le WebSocket
  sendMessage(destination: string, body: any): void {
    if (this.client.connected) {
      this.client.publish({
        destination: destination,
        body: JSON.stringify(body), // Le message à envoyer (ici converti en JSON)
      });
    } else {
      console.warn('WebSocket client is not connected.');
    }
  }

  // Gérer les messages reçus du serveur
  private handleMessage(message: Message): void {
    //console.log('Received message:', message.body);
    this.messagesSubject.next(message.body);  // On émet le message dans le sujet
  }
}
