// angular import
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// bootstrap import
import { SharedModule } from 'src/app/theme/shared/shared.module';

// third party
import { Router, RouterModule } from '@angular/router';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { environment } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';
import { alertConfirm } from 'src/app/helpers/alert-confirm';
import { UtilisateurService } from '../services/utilisateur.service';
import { UtilisateurReponse } from '../reponses/utilisateur.reponse';
import { UtilisateurModele } from '../modeles/utilisateur.modele';

@Component({
  selector: 'app-liste-utilisateur',
  standalone: true,
  imports: [CommonModule, DataTablesModule, SharedModule, RouterModule],
  templateUrl: './liste-utilisateur.component.html',
  styleUrl: './liste-utilisateur.component.scss'
})
export class ListeUtilisateurComponent implements OnInit {
  @Input() clientForm: FormGroup | any;

  // Déclarez l'événement de clic à émettre
  @Output() handlePlusClick = new EventEmitter<void>();
  onPlusClick() {
    this.handlePlusClick.emit(); // Émet l'événement
  }
  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  constructor(
    private localStorageService: SessionService,
    private router: Router,
    private utilisateurService: UtilisateurService

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/admins`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        // URL to fetch data from
        dataSrc: '', // If the JSON response is an array, leave as an empty string
      },
      columns: [
        { title: 'Nom', data: 'nom' },
        { title: 'Prénoms', data: 'prenoms' },
        { title: 'Email', data: 'email' },
        { title: 'Téléphone', data: 'telephone' },
        { title: 'Profil', data: 'profil.libelle' },
        {
          title: 'Etat',
          data: 'etat',
          render: function (data: string, type: string, row: any) {
            // Customize badge class based on 'etat' value
            let badgeClass = 'badge bg-light-success rounded f-12';

            // You can add more conditions here if needed
            // For example, different colors for different states
            if (data === 'DEACTIVATED') {
              badgeClass = 'badge bg-warning rounded f-12';
            }
            if (data == 'ACTIVATED') {
              data = 'ACTIVÉ'
            } else if (data == 'DEACTIVATED') {
              data = 'DESACTIVÉ'
            }
            // Return the HTML for the badge
            return `<span class="${badgeClass}">${data}</span>`;
          }
        },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: null, // Ensures that DataTables doesn't look for this column in the dataset
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => `
        <button class="btn btn-link-primary btn-edit"
                title="Edit"
                id="edit-${row.idCategorie}"
                data-info='${JSON.stringify({ nom: row.nom, prenoms: row.prenoms, email: row.email, telephone: row.telephone, idProfil: row.idProfil, idAdmin: row.idAdmin, etat: row.etat }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-pencil"></i>
        </button>
        <button class="btn btn-link-primary btn-disable"
                title="Disable"
                id="disable-${row.idCategorie}"
                data-info='${JSON.stringify({ nom: row.nom, prenoms: row.prenoms, email: row.email, telephone: row.telephone, idProfil: row.idProfil, idAdmin: row.idAdmin, etat: row.etat }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-check"></i>
        </button>
        <button class="btn btn-link-primary btn-delete"
                title="Delete"
                id="delete-${row.idCategorie}"
                data-info='${JSON.stringify({ nom: row.nom, prenoms: row.prenoms, email: row.email, telephone: row.telephone, idProfil: row.idProfil, idAdmin: row.idAdmin, etat: row.etat }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-trash"></i>
        </button>
    `
        }
      ],
      responsive: true,
      select: false,


    };


  }

  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then(() => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#utilisateurTable tbody').on('click', '.btn-edit', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');
        this.clientForm?.patchValue({
          nom: row['nom'],
          prenoms: row['prenoms'],
          idProfil: row['idProfil'],
          telephone: row['telephone'],
          email: row['email'],
          idAdmin: row['idAdmin']
        });
        this.onPlusClick();
      });
      $('#utilisateurTable tbody').on('click', '.btn-disable', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');
        if (row['etat'] == "ACTIVATED") {
          this.confirmDisable(row['idAdmin'], "DEACTIVATED");
        } else {
          this.confirmDisable(row['idAdmin'], "ACTIVATED");
        }

      });
      $('#utilisateurTable tbody').on('click', '.btn-delete', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');
        this.confirmSuppression(row['idAdmin']);
      });


    });
  }

  confirmSuppression(idAdmin: any) {
    alertConfirm('Voulez-vous supprimer cet utilisateur ?', () => {
      this.onSubmitDelete(idAdmin);
    });
  }

  confirmDisable(idAdmin: any, etat: any) {
    alertConfirm(etat == "ACTIVATED" ? 'Voulez-vous activer cet utilisateur ?' : 'Voulez-vous désactiver cet utilisateur ?', () => {
      this.onSubmitEdit(idAdmin, etat);
    });
  }

  onSubmitEdit(idAdmin: any, etat: any) {
    this.isLoading = true;

    this.clientForm.get('idAdmin')?.setValue("");
    const payload = { "etat": etat } as UtilisateurModele
    this.utilisateurService.update(payload, idAdmin).subscribe(
      (response: UtilisateurReponse) => {
        this.isLoading = false;
        this.hasSuccess = true;
        this.successMessage = 'Utilisateur modifiée avec succès.';
        window.location.reload();
      },
      (error: any) => {
        this.hasError = true;
        this.isLoading = false;
        this.hasSuccess = false;
        this.errorMessage = error.error.message;
        console.error("Erreur lors de la modification de l'Utilisateur", error);
      }
    );

  }

  onSubmitDelete(idAdmin: any) {
    this.isLoading = true;

    this.clientForm.get('idAdmin')?.setValue("");
    this.utilisateurService.delete(idAdmin).subscribe(
      (response: UtilisateurReponse) => {
        this.isLoading = false;
        this.hasSuccess = true;
        this.successMessage = 'Utilisateur Supprimée avec succès.';
        window.location.reload();
      },
      (error: any) => {
        this.hasError = true;
        this.isLoading = false;
        this.hasSuccess = false;
        this.errorMessage = error.error.message;
        console.error("Erreur lors de la suppression de l'Utilisateur", error);
      }
    );

  }


}

