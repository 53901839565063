// Angular import
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ChangePassword } from '../models/change.password';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-fr-password',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule],
  templateUrl: './fr-password.component.html',
  styleUrls: ['./fr-password.component.scss']
})
export class FrPasswordComponent {
  patternEmail = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  loginData: ChangePassword = new ChangePassword();
  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  successMessage: any;
  errorMessage: any;
  forgotForm: FormGroup;

  constructor(private loginService: LoginService, private fb: FormBuilder, private router: Router) {
    this.forgotForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(this.patternEmail)]),
    });
  }

  onSubmit() {
    this.isLoading = true;
    const payload = this.forgotForm.value;

    this.loginService.forgot(payload).subscribe({
      next: (data) => {
        this.hasError = false;
        this.hasSuccess = true;
        this.isLoading = false;
        this.successMessage = "Un email, vous êtes envoyé. veuillez verifier votre email";
        this.router.navigate(['/auth/check-mail'], {
          state: { email: this.forgotForm.get('email')?.value }
        });
      },
      error: (err) => {
        if (err.status == 401) {
          this.errorMessage = "Une erreur s'est produite, vérifiez votre email";
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
        } else {
          this.errorMessage = "Nous n'arrivons pas à traiter votre demande. Veuillez réessayer";
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
        }
      },
    });
  }
}
