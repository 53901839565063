import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFournisseurComponent } from "./table-fournisseur/table-fournisseur.component";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FournisseurService } from './service/fournisseur.service';
import { FournisseurReponse } from './reponse/fournisseur.reponse';
import { FournisseurModele } from './modeles/fournisseur.modele';
import { SharedModule } from 'src/app/theme/shared/shared.module';

@Component({
  selector: 'app-fournisseur-liste',
  standalone: true,
  imports: [CommonModule, TableFournisseurComponent, SharedModule, RouterModule],
  templateUrl: './fournisseur.component.html',
  styleUrl: './fournisseur.component.scss',
})
export class FournisseurComponent {
  fournisseurForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  showForm: boolean = false;
  buttonName: string = '+';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private fournisseurService: FournisseurService
  ) {
    this.fournisseurForm = this.fb.group({
      nom: ['', [Validators.required]],
      nomEntreprise: [''],
      prenoms: ['', [Validators.required]],
      email: [[''], [Validators.required]],
      adresse: [[''], [Validators.required]],
      telephone: [[''], [Validators.required]],
      idFournisseur: [''],
    });
  }

  onReset() {
    this.fournisseurForm.reset();
  }
  handlePlusClick() {
    this.showForm = !this.showForm;
    this.buttonName = this.showForm ? '-' : '+';
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    if (this.fournisseurForm.get('idFournisseur')?.value == "") {
      this.hasError = false;
      this.hasSuccess = false;
      if (this.fournisseurForm.valid) {
        this.isLoading = true;
        const payload: FournisseurModele = this.fournisseurForm.value;
        this.fournisseurService.create(payload).subscribe(
          (response: FournisseurReponse) => {
            this.isLoading = false;
            this.hasError = true;
            this.hasSuccess = true;
            this.successMessage = 'Fournisseur ajouté avec succès.';
            this.fournisseurForm.reset();
          },
          (error: any) => {
            this.hasError = true;
            this.hasSuccess = false;
            this.hasSuccess = false;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de la création du Fournisseur', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    }
    else {
      this.onSubmitEdit();
    }
  }


  onSubmitEdit() {

    this.hasError = false;
    this.hasSuccess = false;
    if (this.fournisseurForm.valid) {
      this.isLoading = true;
      const payload: FournisseurModele = this.fournisseurForm.value;
      this.fournisseurService.update(payload, this.fournisseurForm.get('idFournisseur')?.value).subscribe(
        (response: FournisseurReponse) => {
          this.isLoading = false;
          this.hasError = true;
          this.hasSuccess = true;
          this.successMessage = 'Fournisseur ajouté avec succès.';
          this.fournisseurForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du Fournisseur', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
