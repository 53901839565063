// Angular Import
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule, formatNumber } from '@angular/common';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CustomsThemeService } from 'src/app/theme/shared/service/customs-theme.service';

// Bootstrap Import
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

// third party
import ApexCharts from 'apexcharts';
import { NgApexchartsModule } from 'ng-apexcharts';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexGrid,
  ApexStroke,
  ApexTheme
} from 'ng-apexcharts';
import { DashBoardService } from '../service/dashboard.service';
import { AnnualStatsByYear, CurrencyResponse, Top5ArticlesByQuantiteTotal } from '../reponse/currency.reponse';
import { FormatNumberPipe, transform } from 'src/app/helpers/format-number';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  colors: string[];
  grid: ApexGrid;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  theme: ApexTheme;
};

@Component({
  selector: 'app-default',
  standalone: true,
  imports: [CommonModule, SharedModule, FormatNumberPipe, NgApexchartsModule],
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  currencyResponse: CurrencyResponse = {};
  top5Ventes: Top5ArticlesByQuantiteTotal[] = [];
  totalLocationMois: number = 0;
  totalVenteArticleMois: number = 0;
  totalAnnuel: number = 0;
  top5Qte: number = 0;
  top5Montant: number = 0;
  // private props
  @ViewChild('chart') chart!: ChartComponent;
  chartOptions: Partial<ChartOptions> = {};
  chartOptions1: Partial<ChartOptions> = {};
  monthChart!: ApexCharts;
  yearChart!: ApexCharts;
  growthChart = ['#d3eafd', '#2196f3', '#673ab7', '#ede7f6'];
  bajajChart = ['#673ab7'];
  dates: string[] = [];
  selectedDate: string = ''
  profileCard: any[] = [];
  // Constructor
  constructor(private theme: CustomsThemeService, private dashBoardService: DashBoardService) {
    this.generateDates();
    this.setCurrentDate();
    this.getByDate(new Date().toISOString().split('T')[0]);



  }

  // Life cycle events
  ngOnInit(): void {
    setTimeout(() => {
      this.monthChart = new ApexCharts(document.querySelector('#tab-chart-1'), this.monthOptions);
      this.monthChart.render();
    }, 500);

    this.theme.customsLayoutType.subscribe((themeMode) => {
      this.mangeChartColor(themeMode);
      this.chartOptions.theme!.mode = themeMode === true ? 'dark' : 'light';
      this.chartOptions1.theme!.mode = themeMode === true ? 'dark' : 'light';
    });
  }

  mangeChartColor(themeMode: boolean) {
    this.theme.customsTheme.subscribe((themeColor) => {
      if (themeColor == 'preset-1') {
        this.growthChart = themeMode === true ? ['#90caf9', '#1e88e5', '#7c4dff', '#d1c4e9'] : ['#90caf9', '#1e88e5', '#673ab7', '#ede7f6'];
        this.bajajChart = themeMode === true ? ['#7c4dff'] : ['#673ab7'];
      }
      if (themeColor == 'preset-2') {
        this.growthChart = themeMode === true ? ['#b0bec5', '#587583', '#009688', '#e0f2f1'] : ['#b0bec5', '#546E7A', '#009688', '#e0f2f1'];
        this.bajajChart = ['#009688'];
      }
      if (themeColor == 'preset-3') {
        this.growthChart = themeMode === true ? ['#b0b6c4', '#586580', '#ec407a', '#fde8ef'] : ['#909ab0', '#1c2f59', '#ec407a', '#fde8ef'];
        this.bajajChart = ['#ec407a'];
      }
      if (themeColor == 'preset-4') {
        this.growthChart = themeMode === true ? ['#8fbbbc', '#1b6f70', '#c77e23', '#f8f0e5'] : ['#8bacad', '#135152', '#c77e23', '#f8f0e5'];
        this.bajajChart = ['#c77e23'];
      }
      if (themeColor == 'preset-5') {
        this.growthChart = ['#8b9fa1', '#14383d', '#3fb0ac', '#e8f6f5'];
        this.bajajChart = ['#3fb0ac'];
      }
      if (themeColor == 'preset-6') {
        this.growthChart = ['#8591a1', '#091f3c', '#2ca58d', '#e6f4f1'];
        this.bajajChart = ['#2ca58d'];
      }
      if (themeColor == 'preset-7') {
        this.growthChart = ['#9FA8DA', '#3949AB', '#3F51B5', '#E8EAF6'];
        this.bajajChart = themeMode === true ? ['#7267ef'] : ['#3F51B5'];
      }
    });
  }

  // private Method
  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 1) {
      setTimeout(() => {
        this.monthChart = new ApexCharts(document.querySelector('#tab-chart-1'), this.monthOptions);
        this.monthChart.render();
      }, 200);
    }

    if (changeEvent.nextId === 2) {
      setTimeout(() => {
        this.yearChart = new ApexCharts(document.querySelector('#tab-chart-2'), this.yearOptions);
        this.yearChart.render();
      }, 200);
    }
  }

  ListGroup = [
    {
      name: 'Bajaj Finery',
      profit: '10% Profit',
      invest: '$1839.00',
      bgColor: 'bg-light-success',
      icon: 'ti ti-chevron-up',
      color: 'text-success'
    },
    {
      name: 'TTML',
      profit: '10% Loss',
      invest: '$100.00',
      bgColor: 'bg-light-danger',
      icon: 'ti ti-chevron-down',
      color: 'text-danger'
    },
    {
      name: 'Reliance',
      profit: '10% Profit',
      invest: '$200.00',
      bgColor: 'bg-light-success',
      icon: 'ti ti-chevron-up',
      color: 'text-success'
    },
    {
      name: 'ATGL',
      profit: '10% Loss',
      invest: '$189.00',
      bgColor: 'bg-light-danger',
      icon: 'ti ti-chevron-down',
      color: 'text-danger'
    },
    {
      name: 'Stolon',
      profit: '10% Profit',
      invest: '$210.00',
      bgColor: 'bg-light-success',
      icon: 'ti ti-chevron-up',
      color: 'text-success',
      space: 'pb-0'
    }
  ];

  monthOptions = {
    chart: {
      type: 'line',
      height: 90,
      sparkline: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#FFF'],
    stroke: {
      curve: 'smooth',
      width: 3
    },
    series: [
      {
        name: 'series1',
        data: [45, 66, 41, 89, 25, 44, 9, 54]
      }
    ],
    yaxis: {
      min: 5,
      max: 95
    },
    tooltip: {
      theme: 'dark',
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      marker: {
        show: false
      }
    }
  };

  yearOptions = {
    chart: {
      type: 'line',
      height: 90,
      sparkline: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: ['#FFF'],
    stroke: {
      curve: 'smooth',
      width: 3
    },
    series: [
      {
        name: 'series1',
        data: [35, 44, 9, 54, 45, 66, 41, 69]
      }
    ],
    yaxis: {
      min: 5,
      max: 95
    },
    tooltip: {
      theme: 'dark',
      fixed: {
        enabled: false
      },
      x: {
        show: false
      },
      marker: {
        show: false
      }
    }
  };


  getByDate(date: any) {
    this.dashBoardService.getByDate(date).subscribe((data) => {
      const seriesImpayes: any = [];
      const seriesPayes: any = [];
      let totalReste = 0;
      this.top5Ventes = data.top5ArticlesByQuantiteTotal ?? [];
      const seriesTop5Ventes: any = [];
      this.top5Ventes.forEach((element: Top5ArticlesByQuantiteTotal) => {
        seriesTop5Ventes.push(element.montantTotal);
        this.top5Qte += element.quantiteTotale ?? 0;
        this.top5Montant += element.montantTotal ?? 0;
      })
      this.chartOptions1 = {
        chart: {
          type: 'area',
          height: 95,
          stacked: true,
          sparkline: {
            enabled: true
          },
          background: 'transparent'
        },
        stroke: {
          curve: 'smooth',
          width: 1
        },
        series: [
          {
            data: seriesTop5Ventes
          }
        ],
        theme: {
          mode: 'light'
        }
      };
      this.totalLocationMois = data.totalLocationMois ?? 0;
      this.totalVenteArticleMois = data.totalVenteArticleMois ?? 0;
      this.totalAnnuel = (data.totalLocationAnnee ?? 0) + (data.totalVenteArticleAnnee ?? 0) + (data.totalVenteVehiculeAnnee ?? 0);
      data.monthlyStatsByYear?.forEach((element: AnnualStatsByYear) => {
        seriesImpayes.push(element.totalReste);
        seriesPayes.push(element.totalMontant);
        totalReste += element.totalReste ?? 0;
      })

      this.profileCard = [
        {
          style: 'bg-primary-dark text-white',
          background: 'bg-primary',
          value: transform(this.totalAnnuel),
          text: 'Total revenue annuel',
          color: 'text-white',
          value_color: 'text-white'
        },
        {
          background: 'bg-warning',
          avatar_background: 'bg-light-warning',
          value: transform(totalReste),
          text: 'Total à recouvrer',
          color: 'text-warning'
        }
      ];

      this.chartOptions = {
        series: [
          {
            name: 'Montant à recouvrer',
            data: seriesImpayes
          },
          {
            name: 'Montant total',
            data: seriesPayes
          }

        ],
        dataLabels: {
          enabled: false
        },
        chart: {
          type: 'bar',
          height: 480,
          stacked: true,
          toolbar: {
            show: true
          },
          background: 'transparent'
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%'
          }
        },
        xaxis: {
          type: 'category',
          categories: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc']
        },
        grid: {
          strokeDashArray: 4
        },
        theme: {
          mode: 'light'
        }
      };
    })
  }
  generateDates() {
    const startDate = new Date(2024, 0, 1); // 1er janvier 2000
    const endDate = new Date(); // Aujourd'hui
    const currentDate = startDate;

    while (currentDate <= endDate) {
      this.dates.push(this.formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); // Passer au jour suivant
    }
  }

  setCurrentDate() {
    const today = new Date();
    this.selectedDate = this.formatDate(today); // Formater la date actuelle
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ajouter un zéro devant si besoin
    const day = String(date.getDate()).padStart(2, '0'); // Ajouter un zéro devant si besoin
    return `${year}-${month}-${day}`; // Retourner la date au format yyyy-MM-dd
  }

  onDateChange(selectedDate: string) {
    this.selectedDate = selectedDate;
    console.log(selectedDate);
    this.getByDate(selectedDate);
  }
}
