import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import listStyleData from 'src/fake-data/list-style-1.json';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { VisiteDetailComponent } from '../visite-detail/visite-detail.component';
import { environment } from 'src/environments/environment';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SessionService } from '../../auth/services/session.service';


@Component({
  selector: 'app-liste-visite',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule, DataTablesModule],
  templateUrl: './liste-visite.component.html',
  styleUrl: './liste-visite.component.scss'
})
export class ListeVisiteComponent {
  baseUrl: any = environment.BaseUrl + '/documents/view/';
  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;

  // Constructor
  constructor(private modalService: NgbModal, private localStorageService: SessionService, private router: Router,) {
    this.initializeDataTable();
  }

  // private Method

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/visites/techniques`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        // URL to fetch data from
        dataSrc: '', // If the JSON response is an array, leave as an empty string
      },
      columns: [
        { title: 'Immatriculation', data: 'vehicule.immatriculationProvisoire' },
        { title: 'Centre', data: 'compagnie.nom' },
        { title: 'Observation', data: 'observation' },
        { title: 'Coût', data: 'cout' },
        { title: 'Date début', data: 'dateVisite' },
        { title: 'Date Fin', data: 'dateFin' },
        { title: 'Date prochaine echeance', data: 'prochaineEcheance' },
        { title: 'Etat', data: 'etat' },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: null,
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (row: any) => {
            if (row && row.length > 0) {
              return `
        <button class="btn btn-link-primary btn-view" title="View" id="${row[0].lien}">
          <i class="ti ti-download"></i>
        </button>
        </button>  <button class="btn btn-link-primary btn-edit" title="View" id="edit-${row.idVisiteTechnique}" data-info='${JSON.stringify({ idVisiteTechnique: row.idVisiteTechnique, idVehicule: row.idVehicule, idCompagnie: row.idCompagnie, motif: row.motif, cout: row.cout, dateMaintenance: row.dateVisite, dateFin: row.dateFin, etat: row.etat, observation: row.observation }).replace(/'/g, "&apos;")}'>
          <i class="ti ti-edit"></i>
        </button>
      `;
            }
            return `</button>  <button class="btn btn-link-primary btn-edit" title="View" id="edit-${row.idVisiteTechnique}" data-info='${JSON.stringify({ idVisiteTechnique: row.idVisiteTechnique, idVehicule: row.idVehicule, idCompagnie: row.idCompagnie, motif: row.motif, cout: row.cout, dateMaintenance: row.dateVisite, dateFin: row.dateFin, etat: row.etat, observation: row.observation }).replace(/'/g, "&apos;")}'>
          <i class="ti ti-edit"></i>
        </button> `;
          }
        }
      ],
      responsive: true,
      select: false,

    };


  }
  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#tableVisite tbody').on('click', '.btn-view', (event) => {
        //this.navigateToRoute(event.currentTarget.id);
        const url = this.baseUrl + event.currentTarget.id;
        // Open the URL in a new blank window/tab
        window.open(url, '_blank');
      });

      $('#tableVisite tbody').on('click', '.btn-edit', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');
        this.gotoEdit(row.idVisiteTechnique);
      });

    });
  }

  gotoEdit(id: any) {
    this.router.navigate(['/app/gestion-visite/update-visites', id]);
  }
}
