export function NumberToLetter(nombre: number | string, U: string | null = null, D: string | null = null): string {
  const letter: { [key: number]: string } = {
    0: "zéro", 1: "un", 2: "deux", 3: "trois", 4: "quatre", 5: "cinq",
    6: "six", 7: "sept", 8: "huit", 9: "neuf", 10: "dix", 11: "onze",
    12: "douze", 13: "treize", 14: "quatorze", 15: "quinze", 16: "seize",
    17: "dix-sept", 18: "dix-huit", 19: "dix-neuf", 20: "vingt",
    30: "trente", 40: "quarante", 50: "cinquante", 60: "soixante",
    70: "soixante-dix", 80: "quatre-vingt", 90: "quatre-vingt-dix",
  };

  // Validation de l'entrée
  if (typeof nombre === 'string') {
    nombre = parseFloat(nombre.replace(/ /g, ""));
  }

  if (nombre.toString().replace(/ /gi, "").length > 15) return "dépassement de capacité";
  if (isNaN(nombre)) return "Nombre non valide";

  let nb = parseFloat(nombre.toString().replace(/ /gi, ""));

  // Gestion des nombres à virgule
  if (Math.ceil(nb) !== nb) {
    const [whole, fraction] = nb.toString().split('.');
    return `${NumberToLetter(whole)} ${U ? U + ' ' : 'virgule '} ${NumberToLetter(fraction)}${D ? ' ' + D : ''}`;
  }

  let numberToLetter = '';
  const n = nb.toString().length;

  // Logique de conversion
  switch (n) {
    case 1:
      numberToLetter = letter[nb];
      break;
    case 2:
      if (nb > 19) {
        const quotient = Math.floor(nb / 10);
        const reste = nb % 10;
        if (reste === 0) numberToLetter = letter[quotient * 10];
        else if (reste === 1) numberToLetter = letter[quotient * 10] + "-et-" + letter[reste];
        else numberToLetter = letter[quotient * 10] + "-" + letter[reste];
      } else {
        numberToLetter = letter[nb];
      }
      break;
    case 3:
      const quotientHundred = Math.floor(nb / 100);
      const resteHundred = nb % 100;
      if (quotientHundred === 1 && resteHundred === 0) numberToLetter = "cent";
      else if (quotientHundred === 1) numberToLetter = "cent " + NumberToLetter(resteHundred);
      else if (resteHundred === 0) numberToLetter = letter[quotientHundred] + " cents";
      else numberToLetter = letter[quotientHundred] + " cent " + NumberToLetter(resteHundred);
      break;
    case 4:
    case 5:
    case 6:
      const quotientThousand = Math.floor(nb / 1000);
      const resteThousand = nb - quotientThousand * 1000;
      if (quotientThousand === 1 && resteThousand === 0) numberToLetter = "mille";
      else if (quotientThousand === 1) numberToLetter = "mille " + NumberToLetter(resteThousand);
      else if (resteThousand === 0) numberToLetter = NumberToLetter(quotientThousand) + " mille";
      else numberToLetter = NumberToLetter(quotientThousand) + " mille " + NumberToLetter(resteThousand);
      break;
    case 7:
    case 8:
    case 9:
      const quotientMillion = Math.floor(nb / 1000000);
      const resteMillion = nb % 1000000;
      if (quotientMillion === 1 && resteMillion === 0) numberToLetter = "un million";
      else if (quotientMillion === 1) numberToLetter = "un million " + NumberToLetter(resteMillion);
      else if (resteMillion === 0) numberToLetter = NumberToLetter(quotientMillion) + " millions";
      else numberToLetter = NumberToLetter(quotientMillion) + " millions " + NumberToLetter(resteMillion);
      break;
    case 10:
    case 11:
    case 12:
      const quotientBillion = Math.floor(nb / 1000000000);
      const resteBillion = nb % 1000000000;
      if (quotientBillion === 1 && resteBillion === 0) numberToLetter = "un milliard";
      else if (quotientBillion === 1) numberToLetter = "un milliard " + NumberToLetter(resteBillion);
      else if (resteBillion === 0) numberToLetter = NumberToLetter(quotientBillion) + " milliards";
      else numberToLetter = NumberToLetter(quotientBillion) + " milliards " + NumberToLetter(resteBillion);
      break;
    case 13:
    case 14:
    case 15:
      const quotientBillion2 = Math.floor(nb / 1000000000000);
      const resteBillion2 = nb % 1000000000000;
      if (quotientBillion2 === 1 && resteBillion2 === 0) numberToLetter = "un billion";
      else if (quotientBillion2 === 1) numberToLetter = "un billion " + NumberToLetter(resteBillion2);
      else if (resteBillion2 === 0) numberToLetter = NumberToLetter(quotientBillion2) + " billions";
      else numberToLetter = NumberToLetter(quotientBillion2) + " billions " + NumberToLetter(resteBillion2);
      break;
  }

  // Accord de "quatre-vingt"
  if (numberToLetter.endsWith("quatre-vingt")) {
    numberToLetter += "s";
  }

  return numberToLetter;
}
