import Swal from 'sweetalert2';

// Fonction exportable confirm()
export function alertConfirm(title: string, callback: () => void) {
  Swal.fire({
    title: title, // Utilisez le titre passé en paramètre
    showDenyButton: false,
    showCancelButton: true,
    cancelButtonText: 'Annuler',
    confirmButtonText: 'Valider',
    didOpen: () => {
      const swalTitle = document.querySelector('.swal2-title') as HTMLElement;
      const confirmButton = document.querySelector('.swal2-confirm') as HTMLElement;
      const cancelButton = document.querySelector('.swal2-cancel') as HTMLElement;
      const actionsContainer = document.querySelector('.swal2-actions') as HTMLElement;

      if (swalTitle) {
        swalTitle.style.fontWeight = 'bold';
        swalTitle.style.padding = '20px';
        swalTitle.style.fontSize = '18px'; // Ajustez la taille selon vos besoins
      }

      // Ajuster la taille de la police des boutons
      if (confirmButton) {
        confirmButton.style.fontSize = '14px';
        confirmButton.style.padding = '5px 20px'; // Ajustez la taille du bouton "Valider"
      }

      if (cancelButton) {
        cancelButton.style.fontSize = '14px';
        cancelButton.style.padding = '5px 20px'; // Ajustez la taille du bouton "Annuler"
      }

      // Placer les boutons à droite
      if (actionsContainer) {
        actionsContainer.style.display = 'flex';
        actionsContainer.style.justifyContent = 'flex-end';
        actionsContainer.style.width = '100%';
        actionsContainer.style.padding = '10px';
      }
    }
  }).then((result) => {
    if (result.isConfirmed) {
      callback(); // Appelle la fonction passée en paramètre
    }
  });
}
