import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TypeModele } from '../modeles/type.modele';
import { TypeReponse } from '../reponses/type.reponse';
import { SessionService } from 'src/app/Pages/auth/services/session.service';


@Injectable({
  providedIn: 'root'
})
export class TypeService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }


  create(payload: TypeModele): Observable<TypeReponse> {
    return this.http.post<any>(`${this.url}/types`, payload, this.getHttpOptions());
  }

  update(payload: TypeModele, id: any): Observable<TypeReponse> {
    return this.http.put<any>(`${this.url}/types/${id}`, payload, this.getHttpOptions());
  }

  delete(id: any): Observable<TypeReponse> {
    return this.http.delete<any>(`${this.url}/types/${id}`, this.getHttpOptions());
  }

  getById(id: any): Observable<TypeReponse> {
    return this.http.get<any>(`${this.url}/types/${id}`, this.getHttpOptions());
  }

  getAll(query?: any): Observable<TypeReponse[]> {
    const url = query ? `${this.url}/types?${query}` : `${this.url}/types`;
    return this.http.get<any>(url, this.getHttpOptions());
  }

}
