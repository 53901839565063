// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { TableCategorieComponent } from "../table-categorie/table-categorie.component";
import { CompagnieService } from '../../gestion-compagnie-assurance/service/compagnie.service';
import { CompagnieModele } from '../../gestion-compagnie-assurance/modeles/compagnie.modele';
import { CompagnieReponse } from '../../gestion-compagnie-assurance/reponse/compagnie.reponse';
import { CategorieService } from '../service/categorie.service';
import { CategorieModele } from '../modeles/categorie.modele';
import { CategorieReponse } from '../reponse/categorie.reponse';

@Component({
  selector: 'app-creation-categorie',
  standalone: true,
  imports: [CommonModule, SharedModule, TableCategorieComponent],
  templateUrl: './creation-categorie.component.html',
  styleUrl: './creation-categorie.component.scss'
})
export class CreationCategorieComponent implements OnInit {
  categorieForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private categorieService: CategorieService
  ) {
    this.categorieForm = this.fb.group({
      idCategorie: [''],
      libelle: ['', [Validators.required]],
      code: ['', [Validators.required]],
      description: [''],
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    if (this.categorieForm.get('idCategorie')?.value == "") {
      this.isLoading = true;
      this.hasError = false;
      this.hasSuccess = false;
      if (this.categorieForm.valid) {
        const payload: CategorieModele = this.categorieForm.value;
        this.categorieService.create(payload).subscribe(
          (response: CategorieReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.successMessage = 'Catégorie ajoutée avec succès.';
            this.categorieForm.reset();
          },
          (error: any) => {
            this.hasError = true;
            this.hasSuccess = false;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de la création de la catégorie', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    } else {
      this.onSubmitEdit();
    }
  }

  onSubmitEdit() {
    this.isLoading = true;
    if (this.categorieForm.valid) {
      const idMarque = this.categorieForm.get('idCategorie')?.value;
      this.categorieForm.get('idCategorie')?.setValue("");
      const payload: CategorieModele = this.categorieForm.value;
      payload.idCategorie = null;
      this.categorieService.update(payload, idMarque).subscribe(
        (response: CategorieReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Catégorie modifiée avec succès.';
          this.categorieForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la modification de la catégorie', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  onReset() {
    this.categorieForm.reset();
  }
  ngOnInit(): void {

  }
}
