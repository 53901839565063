<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Villes">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="categorieForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">

            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="libelle">Libellé <span class="text-danger">*</span></label>
                <input type="text" formControlName="libelle" class="form-control" id="libelle" [ngClass]="{
                        'is-invalid':
                          categorieForm.get('libelle')?.invalid &&
                          categorieForm.get('libelle')?.touched
                      }" />
                <div *ngIf="
                        categorieForm.get('libelle')?.invalid &&
                        categorieForm.get('libelle')?.touched
                      " class="invalid-feedback">
                  <div *ngIf="categorieForm.get('libelle')?.errors?.['required']">
                    Le libellé est requis.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="montantChauffeur">Montant Chauffeur</label>
                <input type="number" formControlName="montantChauffeur" class="form-control" id="montantChauffeur" />
              </div>
            </div>

          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "Enregistrer" -->
          <button type="submit" class="btn btn-primary" [disabled]="categorieForm.invalid"
            *ngIf="!isLoading && !categorieForm.get('idVille')?.value">
            Enregistrer
          </button>
          <!-- Bouton "Modifier" -->
          <div class="row">
            <div class="col text-right"> <!-- Added a column to align buttons to the right -->
              <button type="submit" class="btn btn-primary me-2" [disabled]="marqueForm.invalid || isLoading"
                *ngIf="categorieForm.get('idVille')?.value">
                Modifier
              </button>

              <button class="btn btn-secondary" type="button" (click)="onReset()"
                *ngIf="categorieForm.get('idVille')?.value && !isLoading">
                Annuler
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-ville [categorieForm]="categorieForm"></app-table-ville>
    </app-card>
  </div>
</div>