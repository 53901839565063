// Angular Import
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfilService } from '../services/profil.service';
import { MenuReponse, ProfilReponse, SousMenuReponse } from '../reponses/profil.reponse';
import { ProfilMenuModele, ProfilModele } from '../modeles/profil.modele';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/theme/shared/shared.module';
declare let $: any;

@Component({
  selector: 'app-update-profil',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './update-profil.component.html',
  styleUrls: ['./update-profil.component.scss'],

})
export class UpdateProfilComponent {
  profileFrom: FormGroup;
  actionFrom: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  // private props

  profilMenu: MenuReponse[] = [];
  hasError2: boolean = false;
  hasSuccess2: boolean = false;
  errorMessage2: any;
  successMessage2: any;

  number_List: any;
  getPage: any = '';

  PageSession: any = [];
  table: any;
  array2: any = [];
  dataJson: any = [];
  value: any;
  valueRemove: any;
  val: any;
  valRemove: any;

  arrayAction: any = [
    {
      label: 'Création',
      code: 1,
    },
    {
      label: 'Lecture',
      code: 2,
    },
    {
      label: 'Modification',
      code: 3,
    },
    {
      label: 'Suppression',
      code: 4,
    },
  ];
  permissionArray: any = [];
  arrayAction2: any = [];
  arrayMenu: any = [];
  dataMenu: any = [];
  tab: any = [];
  resutlMenuArray: any = [];
  profilePayload = new ProfilModele();
  dataPage: MenuReponse[] = [];
  result: ProfilReponse = {} as ProfilReponse;
  // Constructor
  constructor(private fb: FormBuilder, private profilService: ProfilService, private activatedRoute: ActivatedRoute) {
    this.findAllPage();
    this.findById();
    this.profileFrom = this.fb.group({
      libelle: ['', [Validators.required]],
      page_select: [''],
      page_select_remove: [''],
    });

    this.actionFrom = this.fb.group({
      action_select: [''],
      action_select_remove: [''],
    });

  }




  onCancel() {
    $('#exampleModalCenter').modal('hide');
  }

  onAdd() {
    this.value = this.profileFrom.get('page_select')?.value;
    this.hasError = false;
    if (this.value != '') {
      this.permissionArray = [];
      this.arrayAction2 = [];

      $('#exampleModalCenter').modal('show');
    } else {
      this.hasError = true;
      this.errorMessage = 'Veuillez selectionnnez une page';
    }
  }

  onAddAction() {
    this.hasError2 = false;
    this.val = this.actionFrom.get('action_select')?.value;
    if (this.val != '') {
      this.onAddArrayAction2(this.val);
    } else {
      this.hasError2 = true;
      this.errorMessage2 = 'Veuillez selectionnés une action';
    }
  }
  onRemoveArrayAction() {
    this.valRemove = this.actionFrom?.get('action_select_remove')?.value;
    var convert = this.valRemove.toString();
    var convertToarray: any = convert?.split(',');
    var isExist2 = false;

    if (this.arrayAction2.length > 0) {
      for (let index = 0; index < this.arrayAction2.length; index++) {
        if ('' + this.arrayAction2[index] === convertToarray[0] + '') {
          isExist2 = true;
        }
      }
    }
    if (isExist2) {
      this.removeItemOnce(this.arrayAction2, convertToarray[0]);
      this.removeItemOnce(this.permissionArray, convertToarray[1]);
    }
  }

  removeItemOnce(arr: any, value: any) {
    var index = arr.indexOf(value);
    console.log(index);

    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  onAddArrayAction2(val: any) {
    var convert = val?.toString();

    const convertToarray: any = convert?.split(',');

    var isExist2 = true;

    if (this.arrayAction2.length > 0) {
      for (let index = 0; index < this.arrayAction2.length; index++) {
        if ('' + this.arrayAction2[index] === convertToarray[0] + '') {
          isExist2 = false;
        }
      }
    } else {
      isExist2 = true;
    }
    if (isExist2) {
      this.arrayAction2.push(convertToarray[0]);
      this.permissionArray.push(convertToarray[1].toString());
    }

    this.permissionArray.filter((v: any, i: any, a: any) => a.indexOf(v) === i);
  }

  onRemoveArray() {
    this.valueRemove = this.profileFrom?.get('page_select_remove')?.value;
    var isExist = false;
    if (this.array2.length > 0) {
      for (let index = 0; index < this.array2.length; index++) {
        if ('' + this.array2[index] === this.valueRemove + '') {
          isExist = true;
        }
      }
    }
    if (isExist) {
      this.array2.forEach((item: any, index: any) => {
        if (item === this.valueRemove[0]) {
          this.array2.splice(index, 1);
        }
      });
      this.dataMenu.forEach((item: any, index: any) => {
        if (item.page === this.valueRemove[0]) {
          this.dataMenu.splice(index, 1);
        }
      });
    }
  }

  onAddEnd() {
    var isExist = true;
    var convert = this.value?.toString();
    const convertValue: any = convert?.split(',');
    if (this.array2.length > 0) {
      for (let index = 0; index < this.array2.length; index++) {
        if ('' + this.array2[index] === convertValue[1] + '') {
          isExist = false;
        }
      }
    } else {
      isExist = true;
    }
    if (this.arrayAction2.length == 0) {
      return
    }
    if (isExist) {
      this.array2.push(convertValue[1]);
      this.dataMenu.push({
        id: convertValue[0],
        page: convertValue[1],
        permission: this.permissionArray,
      });
    }

    $('#exampleModalCenter').modal('hide');
  }



  onSubmit() {
    this.tab = [];
    this.resutlMenuArray = [];

    const profilMenus = [] as ProfilMenuModele[];
    for (let index = 0; index < this.dataPage.length; index++) {
      this.dataMenu.forEach((item: any) => {
        if (
          this.dataPage[index].sousMenus?.length == 0
        ) {
          this.resutlMenuArray.push({
            libelle: item.page,
            idParent: item.id,
            icon: this.dataPage[index].icon,
            code: this.dataPage[index].code,
            permission: item.permission.toString(),
            sousmenus: null,
          });
        } else if (this.dataPage[index].sousMenus?.length != 0
        ) {
          this.dataPage[index].sousMenus?.forEach((sous: any) => {
            if ('' + sous.libelle == item.page + '') {
              this.tab.push({
                libelle: sous.libelle,
                id: sous.idSousMenu,
                idParent: sous.idMenu,
                icon: sous.icon,
                code: sous.code,
                sidebar: sous.sidebar,
                permission: item.permission.toString(),
                path: sous.path,
              });
            }
          });
        }
      });


      this.resutlMenuArray.push({
        idParent: this.dataPage[index].idMenu,
        libelle: this.dataPage[index].libelle,
        icon: this.dataPage[index].icon,
        code: this.dataPage[index].code,
        permission: this.dataPage[index].permission,
        path: null,
        sousmenus: this.tab,
      });

      this.tab = [];
    }

    this.dataMenu.forEach((elt2: any) => {
      this.resutlMenuArray.forEach((it: any) => {
        if (elt2.page == it.libelle && (it.sousmenus == null || it.sousmenus.length == 0)) {
          const current = {
            idMenu: it.idParent,
            idSousMenu: it.id,
            permission: it.permission,
          }
          if (!profilMenus.includes(current)) {
            profilMenus.push(current);
          }
        } else {
          it.sousmenus.forEach((item: any) => {
            if (elt2.page == item.libelle) {
              const current = {
                idMenu: it.idParent,
                idSousMenu: item.id,
                permission: item.permission,
              }
              if (!profilMenus.includes(current)) {
                profilMenus.push(current);
              }

            }
          })
        }
      });

    });

    if (this.profileFrom.valid && profilMenus.length != 0) {
      this.isLoading = true;
      const payload: ProfilModele = this.profileFrom.value;
      payload.profilMenus = profilMenus;
      this.profilService.update(payload, this.activatedRoute.snapshot.params['id']).subscribe(
        (response: ProfilReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Profil modifié avec succès.';
          window.location.reload();
        },
        (error: any) => {
          console.error('Erreur lors de la mise à jour du profil', error);
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;

        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  findAllPage() {
    this.profilService.getAllMenu().subscribe((data: MenuReponse[]) => {
      data.forEach((item: MenuReponse) => {
        this.dataPage = data
        if (item.sousMenus?.length == 0) {
          this.arrayMenu.push({
            idParent: item.idMenu,
            libelle: item.libelle,
            icon: item.icon,
            code: item.code,
            sidebar: true,
            path: item.lien,
          });
        } else {
          item.sousMenus?.forEach((sous: SousMenuReponse) => {
            this.arrayMenu.push({
              idParent: item.idMenu,
              id: sous.idSousMenu,
              libelle: sous.libelle,
              icon: sous.icon,
              code: item.code,
              sidebar: true,
              path: sous.lien,
            });
          });
        }
      });

    })
  }

  findById() {

    // this.dataMenu.splice(0);
    this.profilService.getById(this.activatedRoute.snapshot.params['id']).subscribe({
      next: (data) => {
        this.result = data;
        this.profileFrom.get('libelle')?.setValue(this.result.libelle);
        this.onPageUser(this.result.menus ?? []);
      },
      error: (err) => {

        console.log(err);
      },
    });
  }

  onPageUser(page: MenuReponse[]) {
    this.dataPage.forEach((pageData: MenuReponse) => {
      page.forEach((item: MenuReponse) => {
        if (item.sousMenus?.length == 0) {
          if (pageData.libelle == item.libelle) {
            if (!this.array2.includes(pageData.libelle)) {
              this.array2.push(pageData.libelle);
              this.dataMenu.push({
                idParent: pageData.idMenu,
                page: pageData.libelle,
                permission: item.permission.split(','),
              });
            }

          }
        } else {
          pageData.sousMenus?.forEach((sousPageData: SousMenuReponse) => {
            item.sousMenus?.forEach((sous: SousMenuReponse) => {
              if (sousPageData.libelle == sous.libelle) {
                if (!this.array2.includes(sousPageData.libelle)) {
                  this.array2.push(sousPageData.libelle);
                  this.dataMenu.push({
                    idParent: pageData.idMenu,
                    id: sousPageData.idSousMenu,
                    page: sousPageData.libelle,
                    permission: sous.permission.split(','),
                  });
                }

              }

            })

          });

        }
      });

    });

  }


}
