// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { TableCentreComponent } from "../table-centre/table-centre.component";
import { CompagnieService } from '../../gestion-compagnie-assurance/service/compagnie.service';
import { CompagnieModele } from '../../gestion-compagnie-assurance/modeles/compagnie.modele';
import { CompagnieReponse } from '../../gestion-compagnie-assurance/reponse/compagnie.reponse';

@Component({
  selector: 'app-creation-centre',
  standalone: true,
  imports: [CommonModule, SharedModule, TableCentreComponent, TableCentreComponent],
  templateUrl: './creation-centre.component.html',
  styleUrl: './creation-centre.component.scss'
})
export class CreationCentreComponent implements OnInit {
  clientForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private compagnieService: CompagnieService
  ) {
    this.clientForm = this.fb.group({
      nom: ['', [Validators.required]],
      email: [[''], [Validators.required]],
      adresse: [[''], [Validators.required]],
      telephone: [[''], [Validators.required]],
      description: [['']],
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.clientForm.valid) {
      const payload: CompagnieModele = this.clientForm.value;
      payload.type = "CENTRE";
      this.compagnieService.create(payload).subscribe(
        (response: CompagnieReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Centre ajouté avec succès.';
          this.clientForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du centre', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
