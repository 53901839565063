import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { environment } from 'src/environments/environment';
import { VenteVehiculeReponse } from '../../vente-vehicule/reponse/vente.vehicule.reponse';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-liste-location-vehicule',
  standalone: true,
  imports: [CommonModule, DataTablesModule, SharedModule, RouterModule],
  templateUrl: './liste-location-vehicule.component.html',
  styleUrl: './liste-location-vehicule.component.scss'
})
export class ListeLocationVehiculeComponent {

  dtTrigger: Subject<any> = new Subject()
  selectedVehicule: any;
  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;
  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }
  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/commandes/type/L_VEHICULE`;

    this.dtRouterLinkOptions = {
      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        dataSrc: function (json: VenteVehiculeReponse[]) {
          // Flatten the detailCommandes array
          const flatData = json.flatMap((command: any) =>
            command.detailCommandes.map((detail: any) => ({
              marque: detail.vehicule.marque.libelle,
              modele: detail.vehicule.modele.libelle,
              type: detail.vehicule.type.libelle,
              idCommande: command.idCommande,
              numero: command.numero,
              montant: command.montant,
              reste: command.reste,
              immatriculationDefinitive: detail.vehicule.immatriculationDefinitive,
              immatriculationProvisoire: detail.vehicule.immatriculationProvisoire,
              etat: command.etat,
              dateCreation: command.dateCreation
            }))
          );
          return flatData;
        }
      },
      columns: [
        { title: 'Numéro', data: 'numero' },
        { title: 'Marque', data: 'marque' },
        { title: 'Modele', data: 'modele' },
        { title: 'Type', data: 'type' },
        { title: 'IMT Définitive', data: 'immatriculationDefinitive' },
        { title: 'IMT Provisoire', data: 'immatriculationProvisoire' },
        {
          title: 'Etat',
          data: 'etat',
          render: function (data: string, type: string, row: any) {
            // Customize badge class based on 'etat' value
            let badgeClass = 'badge bg-light-success rounded f-12';

            // You can add more conditions here if needed
            // For example, different colors for different states
            if (data === 'PENDING') {
              badgeClass = 'badge bg-warning rounded f-12';
            } else if (data === 'ACTIVATED') {
              badgeClass = 'badge bg-light-success rounded f-12';
            } else if (data === 'Canceled') {
              badgeClass = 'badge bg-danger rounded f-12';
            }
            else if (data === 'DELETING') {
              badgeClass = 'badge bg-danger rounded f-12';
            }
            if (data == 'ACTIVATED') {
              data = 'VALIDE'
            } else if (data == 'UPDATED') {
              data = 'EN ATTENTE DE MISE A JOUR'
            } else if (data == 'RENTAL') {
              data = 'EN MISSION'
            } else if (data == 'PENDING') {
              data = 'EN ATTENTE DE VERIFICATION'
            } else if (data == 'PRE_CONFIRMED') {
              data = 'EN ATTENTE DE CONFIRMATION'
            } else if (data == 'DELETING') {
              data = 'EN ATTENTE DE SUPPRESSION'
            }

            // Return the HTML for the badge
            return `<span class="${badgeClass}">${data}</span>`;
          }
        },
        {
          title: 'Etat Paiement',
          data: null,
          render: function (data: string, type: string, row: any) {
            // Customize badge class based on 'etat' value
            let badgeClass = 'badge bg-light-success rounded f-12';

            // You can add more conditions here if needed
            // For example, different colors for different states
            if (row.reste == 0) {
              badgeClass = 'badge bg-success rounded f-12';
              data = 'SOLDE'
            } else if (row.reste > 0 && row.reste < row.montant) {
              badgeClass = 'badge bg-light-warning rounded f-12';
              data = 'PARTIELLEMENT SOLDE'
            } else if (row.reste == row.montant) {
              badgeClass = 'badge bg-danger rounded f-12';
              data = 'NON SOLDE'
            }
            // Return the HTML for the badge
            return `<span class="${badgeClass}">${data}</span>`;
          }
        },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idCommande',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => {
            return `
      <button class="btn btn-link-primary btn-view" title="View" id="${data}">
          <i class="ti ti-eye"></i>
      </button>
      ${row.etat == 'UPDATED'
                ? `<button class="btn btn-link-primary btn-edit" title="Edit" id="${data}">
             <i class="ti ti-pencil"></i>
           </button>`
                : ''
              }
    `;
          }
        }
      ],
      responsive: true,
      select: false,
    };

  }
  navigateToRoute(data: any) {
    this.router.navigate(['/app/gestion-location-vente/location-vehicule-detail', data]);
  }
  navigateToRouteEdit(data: any) {
    this.router.navigate(['/app/gestion-location-vente/modification-location-vehicule', data]);
  }

  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#tableVehicule tbody').on('click', '.btn-view', (event) => {
        this.navigateToRoute(event.currentTarget.id);
      });
      $('#tableVehicule tbody').on('click', '.btn-edit', (event) => {

        this.navigateToRouteEdit(event.currentTarget.id);
      });
    });
  }

}

