import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { NotificationModele } from '../modeles/notification.modele';
import { NotificationReponse } from '../reponse/notification.reponse';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }


  update(payload: NotificationModele, id: any): Observable<NotificationReponse> {
    return this.http.put<any>(
      `${this.url}/notifications/${id}`,
      payload,
      this.getHttpOptions()
    );
  }



  getAll(query?: any): Observable<NotificationReponse[]> {
    const url = query ? `${this.url}/notifications?${query}` : `${this.url}/notifications`;
    return this.http.get<any>(
      url,
      this.getHttpOptions()
    );
  }

  getAllByEtat(etat: string, query?: any): Observable<NotificationReponse[]> {
    const url = query ? `${this.url}/notifications/etat/${etat}?${query}` : `${this.url}/notifications/etat/${etat}`;
    return this.http.get<any>(
      url,
      this.getHttpOptions()
    );
  }
}
