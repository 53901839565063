import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { Subject } from 'rxjs';
import { VenteVehiculeReponse } from '../../gestion-location-vente/vente-vehicule/reponse/vente.vehicule.reponse';
import { rest } from 'lodash';

@Component({
  selector: 'app-table-vente',
  standalone: true,
  imports: [CommonModule, DataTablesModule],
  templateUrl: './table-vente.component.html',
  styleUrl: './table-vente.component.scss',
})
export class TableVenteComponent implements OnInit {


  dtTrigger: Subject<any> = new Subject()
  selectedVehicule: any;
  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;
  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }
  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/commandes/type/V_PRODUIT`;

    this.dtRouterLinkOptions = {
      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        dataSrc: function (json: VenteVehiculeReponse[]) {
          // Flatten the detailCommandes array
          const flatData = json.flatMap((command: any) =>
          ({

            idCommande: command.idCommande,
            numero: command.numero,
            etat: command.etat,
            reste: command.reste,
            montant: command.montant,
            dateCreation: command.dateCreation
          })
          );
          return flatData;
        }
      },
      columns: [
        { title: 'Numéro', data: 'numero' },
        {
          title: 'Etat',
          data: 'etat',
          render: function (data: string, type: string, row: any) {
            // Customize badge class based on 'etat' value
            let badgeClass = 'badge bg-light-success rounded f-12';

            // You can add more conditions here if needed
            // For example, different colors for different states
            if (data === 'PENDING') {
              badgeClass = 'badge bg-warning rounded f-12';
            } else if (data === 'ACTIVATED') {
              badgeClass = 'badge bg-light-success rounded f-12';
            } else if (data === 'Canceled') {
              badgeClass = 'badge bg-danger rounded f-12';
            }
            if (data == 'ACTIVATED') {
              data = 'VALIDE'
            } else if (data == 'UPDATED') {
              data = 'EN ATTENTE DE MISE A JOUR'
            } else if (data == 'RENTAL') {
              data = 'EN MISSION'
            } else if (data == 'PENDING') {
              data = 'EN ATTENTE DE VERIFICATION'
            } else if (data == 'PRE_CONFIRMED') {
              data = 'EN ATTENTE DE CONFIRMATION'
            }

            // Return the HTML for the badge
            return `<span class="${badgeClass}">${data}</span>`;
          }
        },
        {
          title: 'Etat Paiement',
          data: null,
          render: function (data: string, type: string, row: any) {
            // Customize badge class based on 'etat' value
            let badgeClass = 'badge bg-light-success rounded f-12';


            // You can add more conditions here if needed
            // For example, different colors for different states
            if (row.reste == 0) {
              badgeClass = 'badge bg-success rounded f-12';
              data = 'SOLDE'
            } else if (row.reste > 0 && row.reste < row.montant) {
              badgeClass = 'badge bg-light-warning rounded f-12';
              data = 'PARTIELLEMENT SOLDE'
            } else if (row.reste == row.montant) {
              badgeClass = 'badge bg-danger rounded f-12';
              data = 'NON SOLDE'
            }
            // Return the HTML for the badge
            return `<span class="${badgeClass}">${data}</span>`;
          }
        },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idCommande',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => {
            return `
      <button class="btn btn-link-primary btn-view" title="View" id="${data}">
          <i class="ti ti-eye"></i>
      </button>
    `;
          }
        }
      ],
      responsive: true,
      select: false,
    };

  }
  navigateToRoute(data: any) {
    this.router.navigate(['/app/gestion-vente-piece/detail-vente-piece', data]);
  }

  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#tableVehicule tbody').on('click', '.btn-view', (event) => {

        this.navigateToRoute(event.currentTarget.id);
      });
      $('#tableVehicule tbody').on('click', '.btn-edit', (event) => {

        // this.navigateToRouteEdit(event.currentTarget.id);
      });
    });
  }

}

