import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { alertConfirm } from 'src/app/helpers/alert-confirm';
import { VehiculeReponse } from '../../reponses/vehicule.reponse';
import { VehiculeService } from '../../services/vehicule.service';

@Component({
  selector: 'app-validation-vehicule-detail',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './validation-vehicule-detail.component.html',
  styleUrl: './validation-vehicule-detail.component.scss',
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class ValidationVehiculeDetailComponent implements OnInit {
  // public props
  id: string | null = null;
  vehicule$!: Observable<VehiculeReponse | null>;
  anableUpdate: boolean = false;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;

  constructor(public router: Router, private route: ActivatedRoute,
    private vehiculeService: VehiculeService,
    private offcanvasService: NgbOffcanvas) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.findVehicule();
  }


  findVehicule() {
    this.vehicule$ = this.vehiculeService.getById(this.id);

  }

  // public method
  productData(content: TemplateRef<string>) {
    this.offcanvasService.open(content, { position: 'end' });
  }

  onConfirm() {
    alertConfirm('Voulez-vous valider la modification ?', () => {
      this.updateState();
    });
  }

  updateState() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;

    this.vehiculeService.updateState(this.id).subscribe(
      (response: VehiculeReponse) => {
        this.isLoading = false;
        this.hasError = false;
        this.hasSuccess = true;
        this.successMessage = 'Validation effectuée avec succès.';
        this.findVehicule();
      },
      (error: any) => {
        this.hasError = true;
        this.isLoading = false;
        this.hasSuccess = false;
        this.errorMessage = error.error.message;
        console.error('Erreur lors de la validation du Vehicule', error);
      }
    );

  }
}
