import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'vehicules',
        loadChildren: () => import('./vehicule/vehicule.module').then(m => m.VehiculeModule)
      },
      {
        path: 'marques',
        loadChildren: () => import('./marque/marque.module').then(m => m.MarqueModule)
      },
      {
        path: 'models',
        loadChildren: () => import('./models/models.module').then(m => m.ModelsModule)
      },
      {
        path: 'types',
        loadChildren: () => import('./type/type.module').then(m => m.TypeModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionVehiculeRoutingModule { }
