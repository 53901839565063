import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { Subject } from 'rxjs';

import { FormGroup } from '@angular/forms';
import { VenteVehiculeReponse } from '../../../vente-vehicule/reponse/vente.vehicule.reponse';

@Component({
  selector: 'app-table-bon-impression-livraison',
  standalone: true,
  imports: [CommonModule, DataTablesModule],
  templateUrl: './table-bon-impression.component.html',
  styleUrl: './table-bon-impression.component.scss',
})
export class TableBonImpressionComponent implements OnInit, AfterViewInit {

  dtTrigger: Subject<any> = new Subject();
  selectedVehicule: any;
  // public props
  dtRouterLinkOptions: object = {};

  @ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;


  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable(ref?: any) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formatDate = (date: Date | string): string => {
      const d = new Date(date);
      return d.toISOString().split('T')[0]; // Returns "2025-02-06"
    };
    if (ref) {
      // Reload the page if ref is truthy
      window.location.reload();
    }

    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/commandes/bon/date/${formatDate(yesterday)}/${formatDate(new Date())}`;

    this.dtRouterLinkOptions = {
      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },

        error: function (xhr: any, error: any, thrown: any) {
          console.error('AJAX error:', xhr, error, thrown);
        },
        dataSrc: function (json: VenteVehiculeReponse[]) {
          // Flatten the detailCommandes array
          const flatData = json
            .filter((command: any) => command.etat === 'ACTIVATED')
            .flatMap((command: any) => ({
              idCommande: command.idCommande,
              numero: command.numero,
              numeroLivraison: command.numeroLivraison,
              etat: command.etat,
              dateCreation: command.dateCreation
            }));
          return flatData;
        }
      },
      columns: [
        { title: 'Numéro bon', data: 'numeroLivraison' },
        { title: 'Numéro commande', data: 'numero' },
        {
          title: 'Etat',
          data: 'etat',
          render: function (data: string, type: string, row: any) {
            // Customize badge class based on 'etat' value
            let badgeClass = 'badge bg-light-success rounded f-12';

            // You can add more conditions here if needed
            // For example, different colors for different states
            if (data === 'Pending') {
              badgeClass = 'badge bg-warning rounded f-12';
            } else if (data === 'ACTIVATED') {
              badgeClass = 'badge bg-light-success rounded f-12';
            } else if (data === 'Canceled') {
              badgeClass = 'badge bg-danger rounded f-12';
            }
            if (data == 'ACTIVATED') {
              data = 'VALIDE'
            } else if (data == 'UPDATED') {
              data = 'EN ATTENTE DE MISE A JOUR'
            } else if (data == 'RENTAL') {
              data = 'EN MISSION'
            } else if (data == 'PENDING') {
              data = 'EN ATTENTE DE VERIFICATION'
            } else if (data == 'PRE_CONFIRMED') {
              data = 'EN ATTENTE DE CONFIRMATION'
            }
            // Return the HTML for the badge
            return `<span class="${badgeClass}">${data}</span>`;
          }
        },

        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idCommande',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => {
            return `
       <button class="btn btn-link-primary btn-edit"
                title="Edit"
                id="edit-${row.idCommande}"
                data-info='${JSON.stringify({ idCommande: row.idCommande, numero: row.numero }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-printer"></i>
        </button>
    `;
          }
        }
      ],
      responsive: true,
      select: false,
    };

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(this.dtRouterLinkOptions);
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      $('#paiementTable tbody').on('click', '.btn-edit', (event) => {
        const $target = $(event.currentTarget);
        const row = $target.data('info');
        this.router.navigate([`/app/gestion-location-vente/impression-bon-livraison/${row['idCommande']}`]);
      });
    });
  }




}

