<div class="row">
  <div class="col-md-12">
    <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
      <p>{{successMessage}}</p>
    </div>
    <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
      <p>{{errorMessage}}</p>
    </div>
    <form [formGroup]="assuranceForm" (ngSubmit)="onSubmit()">
      <app-card cardTitle="Ajouter Assurance">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="idCompagnieAssurance">Référence au véhicule<span class="text-danger">*</span></label>
              <ng-container *ngIf="assuranceForm">
                <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                  [items]="vehicules$ | async" [selectOnTab]="true" (change)="selectClient($event)"
                  bindValue="idVehicule" labelForId="idVehicule" formControlName="idVehicule" [ngClass]="{
                                                      'is-invalid':
                                                        assuranceForm.get('idVehicule')?.invalid &&
                                                        assuranceForm.get('idVehicule')?.touched
                                                    }">
                  <!-- Template pour le label affiché dans le champ sélectionné -->
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.immatriculationProvisoire }} {{ item.modele?.libelle }} {{ item.marque?.libelle }}
                  </ng-template>

                  <!-- Template pour les options dans la liste déroulante -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.immatriculationProvisoire }} {{ item.modele?.libelle }} {{ item.marque?.libelle }}
                  </ng-template>

                </ng-select>
              </ng-container>

              <div
                *ngIf="assuranceForm.get('idVehicule')?.errors?.['required'] && assuranceForm.get('idVehicule')?.touched"
                class="invalid-feedback">
                Le vehicule est requis.
              </div>

            </div>
            <div class="form-group">
              <label class="form-label" for="dateFin">Date début de la police d'assurance<span
                  class="text-danger">*</span></label>
              <input type="date" id="dateDebut" formControlName="dateDebut" class="form-control" required />
              <div
                *ngIf="assuranceForm.get('dateDebut')?.errors?.['required'] && assuranceForm.get('dateDebut')?.touched"
                class="invalid-feedback">
                La date debut est requise.
              </div>

            </div>
            <div class="form-group">
              <label class="form-label" for="numeroPolice">Numéro de la police d'assurance <span
                  class="text-danger">*</span></label>
              <input type="text" id="numeroPolice" formControlName="numeroPolice" class="form-control" required />
              <div
                *ngIf="assuranceForm.get('numeroPolice')?.errors?.['required'] && assuranceForm.get('numeroPolice')?.touched"
                class="invalid-feedback">
                Le numéro de la police est requise.
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="idCompagnie">Nom de la compagnie d'assurance<span class="text-danger">*</span></label>
              <ng-container *ngIf="assuranceForm">
                <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                  [items]="compagnies$ | async" [selectOnTab]="true" (change)="selectClient($event)"
                  bindValue="idCompagnie" labelForId="idCompagnie" formControlName="idCompagnie" [ngClass]="{
                                                                    'is-invalid':
                                                                      assuranceForm.get('idCompagnie')?.invalid &&
                                                                      assuranceForm.get('idCompagnie')?.touched
                                                                  }">
                  <!-- Template pour le label affiché dans le champ sélectionné -->
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.nom }}
                  </ng-template>

                  <!-- Template pour les options dans la liste déroulante -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.nom }}
                  </ng-template>
                </ng-select>
              </ng-container>

              <div
                *ngIf="assuranceForm.get('idCompagnie')?.errors?.['required'] && assuranceForm.get('idCompagnie')?.touched"
                class="invalid-feedback">
                La compagnie est requise.
              </div>

            </div>
            <div class="form-group">
              <label class="form-label" for="dateDebut">Date de fin de la police d'assurance<span
                  class="text-danger">*</span></label>
              <input type="date" id="dateFin" formControlName="dateFin" class="form-control" required />
              <div *ngIf="assuranceForm.get('dateFin')?.errors?.['required'] && assuranceForm.get('dateFin')?.touched"
                class="invalid-feedback">
                La date fin est requise.
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="codeAssurance">Numéro de l'assurance <span
                  class="text-danger">*</span></label>
              <input type="text" id="codeAssurance" formControlName="codeAssurance" class="form-control" required />
              <div
                *ngIf="assuranceForm.get('codeAssurance')?.errors?.['required'] && assuranceForm.get('codeAssurance')?.touched"
                class="invalid-feedback">
                Le numéro de l'assurance est requis.
              </div>
            </div>
          </div>

          <div class="clo-sm-12">
            <div class="form-group">
              <label class="form-label" for="fileAssurance">Fichier de l'assurance <span
                  class="text-danger">*</span></label>
              <input type="file" id="lien" formControlName="lien" (change)="fileChangeEvent($event)"
                class="form-control" required />
              <div *ngIf="assuranceForm.get('lien')?.errors?.['required'] && assuranceForm.get('lien')?.touched"
                class="invalid-feedback">
                Le fichier de l'assurance est requis.
              </div>
            </div>
          </div>
          <div class="card-footer text-end">
            <div class="d-flex justify-content-end my-3">
              <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status"></span>
                Traitement...
              </button>

              <!-- Bouton "Enregistrer" -->
              <button type="submit" class="btn btn-primary" [disabled]="assuranceForm.invalid" *ngIf="!isLoading">
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </app-card>

    </form>
  </div>
</div>