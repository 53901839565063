import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationParametreComponent } from './creation/creation-parametre.component';

const routes: Routes = [

  {
    path: 'liste-parametres',
    component: CreationParametreComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionParametreRoutingModule { }
