import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionCentreRoutingModule } from './gestion-centre-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionCentreRoutingModule
  ]
})
export class GestionCentreModule { }
