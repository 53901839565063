import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationCentreComponent } from './creation-centre/creation-centre.component';

const routes: Routes = [

  {
    path: 'ajout-centre',
    component: CreationCentreComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionCentreRoutingModule { }
