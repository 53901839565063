// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { TableHistoriqueComponent } from './table-historique/table-historique.component';
import { PaiementReponse } from 'src/app/Pages/gestion-caisse/reponse/paiement.reponse';
import { ImportationModele } from '../modeles/vehicule.modele';
import { VehiculeService } from '../services/vehicule.service';

@Component({
  selector: 'app-importation',
  standalone: true,
  imports: [CommonModule, SharedModule, TableHistoriqueComponent],
  templateUrl: './importation.component.html',
  styleUrl: './importation.component.scss'
})
export class ImportationComponent implements OnInit {

  importationForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  numFacture: any = ""


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private vehiculeService: VehiculeService
  ) {

    this.importationForm = this.fb.group({
      file: ['', [Validators.required]],
      fileName: ['', [Validators.required]],
      fileExtension: ['', [Validators.required]],
    });

  }


  fileChangeEvent(event: any): void {

    // Récupérer le fichier sélectionné
    const file = event.target.files[0];
    console.log(file);

    if (file) {


      // Extraire l'extension du fichier
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      this.importationForm.get('fileExtension')?.setValue(fileExtension);
      this.importationForm.get('fileName')?.setValue(fileName);
      // Utiliser FileReader pour obtenir le contenu Base64
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64String = e.target.result.split(',')[1]; // Récupère le contenu Base64 sans le préfixe
        this.importationForm.get('file')?.setValue(base64String);
      };
      reader.readAsDataURL(file); // Lecture du fichier en tant que URL Base64
    }
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.importationForm.valid) {
      const payload: ImportationModele = {} as ImportationModele;


      payload.file = this.importationForm.get('file')?.value;
      payload.fileName = this.importationForm.get('fileName')?.value;
      payload.type = "VEHICULE"


      this.vehiculeService.createImportation(payload).subscribe(
        (response: PaiementReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Importation ajoutée avec succès.';
          this.importationForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
