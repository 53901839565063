<div class="offcanvas-body">
  <form [formGroup]="documentForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label class="form-label">Type de document<span class="text-danger">*</span></label>
      <select formControlName="typeDocument" class="form-select" [ngClass]="{
                                                    'is-invalid':
                                                      documentForm.get('typeDocument')?.invalid &&
                                                     documentForm.get('typeDocument')?.touched
                                                  }">
        <option value="">Sélectionner</option>
        <option *ngFor="let param of (parametreReponse$ | async)" [value]="param.valeur">
          {{ param.libelle }}
        </option>
      </select>
      <div *ngIf="documentForm.get('typeDocument')?.errors?.['required'] && documentForm.get('typeDocument')?.touched"
        class="invalid-feedback">
        Le type de document est requis.
      </div>
    </div>

    <div class="row">
      <div class="input-group">
        <input type="file" placeholder="Choisir un fichier" (change)="fileChangeEvent($event)" class="form-control"
          [ngClass]="{
                                                    'is-invalid':
                                                      documentForm.get('lien')?.invalid &&
                                                     documentForm.get('lien')?.touched
                                                  }" />
        <div *ngIf="documentForm.get('lien')?.errors?.['required'] && documentForm.get('lien')?.touched"
          class="invalid-feedback">
          Le fichier est requis.
        </div>
      </div>

      <div class="mt-3 me-3">
        <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />

      </div>
      <div class="form-group">

        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3" [resizeToWidth]="256"
          [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
          [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"></image-cropper>
      </div>

      <div class="text-end">
        <button class="btn btn-primary me-2">Enregistrer</button>
      </div>
    </div>
  </form>
</div>