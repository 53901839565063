<div class="row">
  <div class="col-sm-12">
    <app-card
      cardTitle="Suppression de la facture N° {{paiementForm.get('numFacture')?.value}}  Montant Total: {{paiementForm.get('oldMontant')?.value}}">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form *ngIf="paiementForm.get('numFacture')?.value" [formGroup]="paiementForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="motifSuppression">Motif de suppression <span class="text-danger">*</span></label>
                <select formControlName="motifSuppression" class="form-control" [ngClass]="{
                'is-invalid': paiementForm.get('motifSuppression')?.invalid && paiementForm.get('motifSuppression')?.touched
              }">
                  <option value="">Sélectionner le motif</option>
                  <option *ngFor="let param of params$ | async" [value]="param.valeur">
                    {{ param.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="paiementForm.get('motifSuppression')?.errors?.['required'] && paiementForm.get('motifSuppression')?.touched"
                  class="invalid-feedback">
                  Le motif de suppression est requis.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="descriptionMotif">Description du motif<span class="text-danger">*</span></label>
                <input type="text" formControlName="descriptionMotif" class="form-control" id="descriptionMotif"
                  [ngClass]="{
                                                            'is-invalid':
                                                              paiementForm.get('descriptionMotif')?.invalid &&
                                                              paiementForm.get('descriptionMotif')?.touched
                                                          }" />
                <div *ngIf="
                                                            paiementForm.get('descriptionMotif')?.invalid &&
                                                            paiementForm.get('descriptionMotif')?.touched
                                                          " class="invalid-feedback">
                  <div *ngIf="paiementForm.get('descriptionMotif')?.errors?.['required']">
                    La description du motif est requise.
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>
          <!-- Bouton "Annuler" -->
          <button (click)="onSubmitForDelete()" class="btn btn-primary me-2"
            [disabled]="paiementForm.invalid&&paiementForm.get('numFacture')?.value"
            *ngIf="!isLoading &&paiementForm.get('numFacture')?.value">
            Annuler la suppression
          </button>

          <button type="submit" class="btn btn-secondary" [disabled]="paiementForm.invalid"
            *ngIf="!isLoading&&paiementForm.get('numFacture')?.value">
            {{ paiementForm.get('etat')?.value === 'DELETING' ? 'Soumettre pour suppression' : "Confirmer la
            suppression" }}
          </button>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-suppression [paiementForm]="paiementForm"></app-table-suppression>
    </app-card>
  </div>
</div>