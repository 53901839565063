import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VisiteDetailComponent } from './visite-detail/visite-detail.component';
import { CreationVisiteComponent } from './creation-visite/creation-visite.component';
import { ListeVisiteComponent } from './liste-visite/liste-visite.component';
import { UpdateVisiteComponent } from './update-visite/update-visite.component';

const routes: Routes = [
  {
    path: 'liste-visites',
    component: ListeVisiteComponent
  },
  {
    path: 'ajout-visites',
    component: CreationVisiteComponent
  },
  {
    path: 'detail-visites/:id',
    component: VisiteDetailComponent
  },
  {
    path: 'update-visites/:id',
    component: UpdateVisiteComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionVisiteRoutingModule { }
