import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ModelService } from '../services/model.service';
import { Observable } from 'rxjs';
import { MarqueService } from '../../marque/services/marque.service';
import { MarqueReponse } from '../../marque/reponses/marque.reponse';
import { ListeMdComponent } from "../liste-md/liste-md.component";
import { ModelModele } from '../modeles/model.modele';
import { ModelReponse } from '../reponses/model.reponse';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-creation-md',
  standalone: true,
  imports: [CommonModule, ListeMdComponent, NgSelectModule, SharedModule, RouterModule],
  templateUrl: './creation-md.component.html',
  styleUrl: './creation-md.component.scss'
})
export class CreationMdComponent implements OnInit {
  modelForm: FormGroup;
  isLoading = false;
  marques: MarqueReponse[] = [];
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  showForm: boolean = false;
  buttonName: string = '+';

  constructor(private fb: FormBuilder, private modelService: ModelService, private marqueService: MarqueService) {
    this.modelForm = this.fb.group({
      idModele: [''],
      libelle: ['', [Validators.required, Validators.minLength(3)]],
      description: ['', Validators.required],
      idMarque: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.findMarques().subscribe(
      response => {
        this.marques = response;
      }
    );
  }

  handlePlusClick() {
    this.showForm = !this.showForm;
    this.buttonName = this.showForm ? '-' : '+';
  }
  selectMarque(marqueReponse: MarqueReponse): void {
    this.modelForm.get('idMarque')?.setValue(marqueReponse.idMarque);
  }
  customSearch(term: string, item: any): boolean {
    if (!term) return true;

    term = term.toLowerCase();

    const searchableFields = ['libelle', 'nom', 'prenoms', 'immatriculationProvisoire', 'valeur']; // Liste des champs à rechercher

    return searchableFields.some(field =>
      item[field] && item[field].toLowerCase().includes(term)
    );
  }
  onSubmit() {
    if (this.modelForm.get('idModele')?.value == "") {
      this.isLoading = true;
      if (this.modelForm.valid) {
        const payload = this.modelForm.value;
        payload.idModele = null;
        this.createModel(payload).subscribe(
          response => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.successMessage = 'Modele de véhicule ajouté avec succès.';
            this.modelForm.reset();
          },
          error => {
            this.hasError = true;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de l\'enregistrement du modèle', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    } else {
      this.onSubmitEdit();
    }
  }


  onReset() {
    this.modelForm.reset();
  }



  onSubmitEdit() {
    this.isLoading = true;
    if (this.modelForm.valid) {
      const idModele = this.modelForm.get('idModele')?.value;
      this.modelForm.get('idMarque')?.setValue("");
      const payload: ModelModele = this.modelForm.value;
      payload.idModele = null;
      this.modelService.update(payload, idModele).subscribe(
        (response: ModelReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Modele de véhicule modifié avec succès.';
          this.modelForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la modification du modele', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête de création du modèle
  createModel(payload: any): Observable<any> {
    return this.modelService.create(payload);
  }

  findMarques(query?: any): Observable<any> {
    return this.marqueService.getAll(query);
  }
}
