// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { FrPasswordComponent } from './fr-password/fr-password.component';
import { CheckMailComponent } from './check-mail/check-mail.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CodeVerifyComponent } from './code-verify/code-verify.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'forget',
        component: FrPasswordComponent
      },
      {
        path: 'check-mail',
        component: CheckMailComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },


    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
