<div class="row">
  <!-- [ sample-page ] start -->
  <div class="col-xl-4 col-md-6 border-black-1">
    <div class="card bg-secondary-dark dashnum-card text-white overflow-hidden">
      <span class="round small"></span>
      <span class="round big"></span>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="avtar avtar-lg">
              <i class="text-white ti ti-credit-card"></i>
            </div>
          </div>
          <div class="col-auto">
            <div class="btn-group" ngbDropdown>
              <a type="button" class="avtar bg-secondary dropdown-toggle arrow-none" ngbDropdownToggle>
                <i class="ti ti-dots"></i>
              </a>
            </div>
          </div>
        </div>
        <span class="text-white d-block f-34 f-w-500 my-2">{{ totalLocationMois| formatNumber }} <i
            class="ti ti-arrow-up-right-circle opacity-50"></i></span>
        <p class="mb-0 opacity-50">Montant total des locations du mois</p>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-md-6">
    <div class="card bg-primary-dark dashnum-card text-white overflow-hidden">
      <span class="round small"></span>
      <span class="round big"></span>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="avtar avtar-lg">
              <i class="text-white ti ti-credit-card"></i>
            </div>
          </div>
          <div class="col-auto">
            <div class="btn-group" ngbDropdown>
              <a type="button" class="avtar bg-primary dropdown-toggle arrow-none" ngbDropdownToggle>
                <i class="ti ti-dots"></i>
              </a>
            </div>
          </div>
        </div>
        <span class="text-white d-block f-34 f-w-500 my-2">{{ totalVenteArticleMois| formatNumber }} <i
            class="ti ti-arrow-up-right-circle opacity-50"></i></span>
        <p class="mb-0 opacity-50">Montant total des ventes de pièces du mois</p>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-md-12">
    @for (item of profileCard; track item) {
    <div class="card dashnum-card dashnum-card-small overflow-hidden {{ item.style }}">
      <span class="round {{ item.background }} small"></span>
      <span class="round {{ item.background }} big"></span>
      <div class="card-body p-3">
        <div class="d-flex align-items-center">
          <div class="avtar avtar-lg {{ item.avatar_background }}">
            <i class="{{ item.color }} ti ti-credit-card"></i>
          </div>
          <div class="ms-2">
            <h4 class="{{ item.value_color }} mb-1">{{ item.value }} <i
                class="ti ti-arrow-up-right-circle opacity-50"></i></h4>
            <p class="mb-0 opacity-50 text-sm">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    }
  </div>

  <div class="col-xl-8 col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row mb-3 align-items-center">
          <div class="col">
            <small>Total</small>
            <h3>{{ totalAnnuel | formatNumber}}</h3>
          </div>
          <div class="col-auto">
            <select class="form-select p-r-35" title="Sélectionner" [(ngModel)]="selectedDate"
              (change)="onDateChange($event.target.value)">
              <option *ngFor="let date of dates" [value]="date">{{ date }}</option>
            </select>
          </div>
        </div>
        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions" [responsive]="chartOptions.responsive" [xaxis]="chartOptions.xaxis"
          [colors]="growthChart" [grid]="chartOptions.grid" [tooltip]="chartOptions.tooltip"
          [theme]="chartOptions.theme"></apx-chart>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row mb-3 align-items-center">
          <div class="col">
            <h4>Top 5 pièes vendues</h4>
          </div>
          <div class="col-auto"> </div>
        </div>
        <div class="rounded bg-light-secondary overflow-hidden mb-3">
          <div class="px-3 pt-3">
            <div class="row mb-1 align-items-start">
              <div class="col">
                <h5 class="text-secondary mb-0">Top</h5>
                <small class="text-muted">QTE {{top5Qte | formatNumber}}</small>
              </div>
              <div class="col-auto">
                <h4 class="mb-0">{{top5Montant | formatNumber}}</h4>
              </div>
            </div>
          </div>
          <apx-chart [series]="chartOptions1.series" [chart]="chartOptions1.chart"
            [dataLabels]="chartOptions1.dataLabels" [plotOptions]="chartOptions1.plotOptions"
            [responsive]="chartOptions1.responsive" [colors]="bajajChart" [stroke]="chartOptions1.stroke"
            [theme]="chartOptions1.theme"></apx-chart>
        </div>
        <ul class="list-group list-group-flush">
          @for (list of top5Ventes; track list) {
          <li class="list-group-item px-0 {{ list.space }}">
            <div class="row align-items-start">
              <div class="col">
                <h5 class="mb-0">{{ list.article }}</h5>
                <small class="text-success">QTE {{ list.quantiteTotale }}</small>
              </div>
              <div class="col-auto">
                <h4 class="mb-0">
                  {{ list.montantTotal | formatNumber
                  }}<span class="ms-2 align-top avtar avtar-xxs bg-light-success"><i
                      class="ti ti-chevron-up text-success"></i></span>
                </h4>
              </div>
            </div>
          </li>
          }
        </ul>

      </div>
    </div>
  </div>
  <!-- [ sample-page ] end -->
</div>