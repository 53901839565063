<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Ajout d'articles" showButton="true" (plusClicked)="handlePlusClick()"
      buttonName="{{buttonName}}">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form *ngIf="showForm" [formGroup]="produitForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="idCategorie">Catégorie<span class="text-danger">*</span></label>
                <ng-container *ngIf="produitForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                    [items]="categories$ | async" [selectOnTab]="true" (change)="selectMarque($event)"
                    bindValue="idCategorie" labelForId="idCategorie" formControlName="idCategorie" [ngClass]="{
                                                  'is-invalid':
                                                    produitForm.get('idCategorie')?.invalid &&
                                                    produitForm.get('idCategorie')?.touched
                                                }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                  </ng-select>
                </ng-container>

                <div
                  *ngIf="produitForm.get('idCategorie')?.errors?.['required'] && produitForm.get('idCategorie')?.touched"
                  class="invalid-feedback">
                  La Catégorie est requise.
                </div>

              </div>
              <div class="form-group">
                <label for="code">Code article<span class="text-danger">*</span></label>
                <input type="text" formControlName="code" class="form-control" id="code" [ngClass]="{
                    'is-invalid':
                      produitForm.get('code')?.invalid &&
                      produitForm.get('code')?.touched
                  }" />
                <div *ngIf="
                    produitForm.get('code')?.invalid &&
                    produitForm.get('code')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="produitForm.get('code')?.errors?.['required']">
                    Le code est requis.
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="libelle">Libellé <span class="text-danger">*</span></label>
                <input type="text" formControlName="libelle" class="form-control" id="libelle" [ngClass]="{
                                      'is-invalid':
                                        produitForm.get('libelle')?.invalid &&
                                        produitForm.get('libelle')?.touched
                                    }" />
                <div *ngIf="
                                      produitForm.get('libelle')?.invalid &&
                                      produitForm.get('libelle')?.touched
                                    " class="invalid-feedback">
                  <div *ngIf="produitForm.get('libelle')?.errors?.['required']">
                    Le libellé est requis.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="description">Description</label>
                <textarea type="text" formControlName="description" class="form-control" id="description"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "Enregistrer" -->
          <button type="submit" class="btn btn-primary" [disabled]="produitForm.invalid"
            *ngIf="!produitForm.get('idProduit')?.value&&!isLoading">
            Enregistrer
          </button>

          <!-- Bouton "Modifier" -->
          <div class="row">
            <div class="col text-right"> <!-- Added a column to align buttons to the right -->
              <button type="submit" class="btn btn-primary me-2" [disabled]="produitForm.invalid || isLoading"
                *ngIf="produitForm.get('idProduit')?.value">
                Modifier
              </button>

              <button class="btn btn-secondary" type="button" (click)="onReset()"
                *ngIf="produitForm.get('idProduit')?.value && !isLoading">
                Annuler
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-article [produitForm]="produitForm" (handlePlusClick)="handlePlusClick()"></app-table-article>
    </app-card>

  </div>
</div>