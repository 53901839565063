<div class="row">
  <!-- [ sample-page ] start -->
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>
      <form [formGroup]="visiteForm" n (ngSubmit)="onSubmit()">
        <app-card cardTitle="Modification de visite technique">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="idCompagnieAssurance">Référence au véhicule<span class="text-danger">*</span></label>
                <ng-container *ngIf="visiteForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                    [items]="vehicules$ | async" [selectOnTab]="true" (change)="selectClient($event)"
                    bindValue="idVehicule" labelForId="idVehicule" formControlName="idVehicule" [ngClass]="{
                                                                          'is-invalid':
                                                                            visiteForm.get('idVehicule')?.invalid &&
                                                                            visiteForm.get('idVehicule')?.touched
                                                                        }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.immatriculationProvisoire }} {{ item.modele?.libelle }} {{ item.marque?.libelle }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.immatriculationProvisoire }} {{ item.modele?.libelle }} {{ item.marque?.libelle }}
                    </ng-template>

                  </ng-select>
                </ng-container>
                <div *ngIf="visiteForm.get('idVehicule')?.errors?.['required'] && visiteForm.get('idVehicule')?.touched"
                  class="invalid-feedback">
                  Le vehicule est requis.
                </div>

              </div>
              <div class="form-group">
                <label class="form-label" for="dateFin">Date début de la visite<span
                    class="text-danger">*</span></label>
                <input type="date" id="dateVisite" formControlName="dateVisite" class="form-control" required />
                <div *ngIf="visiteForm.get('dateVisite')?.errors?.['required'] && visiteForm.get('dateVisite')?.touched"
                  class="invalid-feedback">
                  La date debut est requise.
                </div>

              </div>
              <div class="form-group">
                <label class="form-label" for="numeroPolice">Date de la prochaine echeance </label>
                <input type="date" id="prochaineEcheance" formControlName="prochaineEcheance" class="form-control">

              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="idCompagnie">Nom du centre<span class="text-danger">*</span></label>
                <ng-container *ngIf="visiteForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #clientSelect
                    [items]="compagnies$ | async" [selectOnTab]="true" (change)="selectClient($event)"
                    bindValue="idCompagnie" labelForId="idCompagnie" formControlName="idCompagnie" [ngClass]="{
                                                                                    'is-invalid':
                                                                                      visiteForm.get('idCompagnie')?.invalid &&
                                                                                      visiteForm.get('idCompagnie')?.touched
                                                                                  }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.nom }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.nom }}
                    </ng-template>
                  </ng-select>
                </ng-container>
                <div
                  *ngIf="visiteForm.get('idCompagnie')?.errors?.['required'] && visiteForm.get('idCompagnie')?.touched"
                  class="invalid-feedback">
                  Le centre est requis.
                </div>

              </div>
              <div class="form-group">
                <label class="form-label" for="dateDebut">Date de fin de la visite</label>
                <input type="date" id="dateFin" formControlName="dateFin" class="form-control" />

              </div>
              <div class="form-group">
                <label class="form-label" for="cout">Coût de la visite </label>
                <input type="number" id="cout" formControlName="cout" class="form-control" />

              </div>
            </div>


            <div class="col-sm-12">
              <div class="form-group">
                <label class="form-label" for="cout">Observation</label>
                <textarea id="observation" rows="5" formControlName="observation" class="form-control"></textarea>

              </div>
            </div>
            <div class="clo-sm-12">
              <div class="form-group">
                <label class="form-label" for="fileAssurance">Facture de la visite</label>
                <input type="file" id="lien" formControlName="lien" (change)="fileChangeEvent($event)"
                  class="form-control" />

              </div>
            </div>
            <div class="card-footer text-end">
              <div class="d-flex justify-content-end my-3">
                <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
                  <span class="spinner-grow spinner-grow-sm" role="status"></span>
                  Traitement...
                </button>

                <!-- Bouton "Enregistrer" -->
                <button type="submit" class="btn btn-primary" [disabled]="visiteForm.invalid" *ngIf="!isLoading">
                  Modification
                </button>
              </div>
            </div>
          </div>
        </app-card>

      </form>
    </div>
  </div>
  <!-- [ sample-page ] end -->
</div>