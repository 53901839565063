<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Rapport">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="paiementForm" (ngSubmit)="onSubmit(selectedButton)">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="categorie">Categorie <span class="text-danger">*</span></label>
                <select type="text" formControlName="categorie" class="form-control" [ngClass]="{
                                                      'is-invalid':
                                                        paiementForm.get('categorie')?.invalid &&
                                                        paiementForm.get('categorie')?.touched
                                                    }">
                  <option value="">Sélectionner la catégorie</option>
                  <option *ngFor="let param of params$ | async" [value]="param.valeur">
                    {{ param.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="paiementForm.get('categorie')?.errors?.['categorie'] && paiementForm.get('categorie')?.touched"
                  class="invalid-feedback">
                  La catégorie est requise.
                </div>

              </div>

            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="dateDebut">Date debut<span class="text-danger">*</span></label>
                    <input type="date" formControlName="dateDebut" class="form-control" id="dateDebut" [ngClass]="{
                                'is-invalid':
                                  paiementForm.get('dateDebut')?.invalid &&
                                  paiementForm.get('dateDebut')?.touched
                              }" />
                    <div *ngIf="
                                paiementForm.get('dateDebut')?.invalid &&
                                paiementForm.get('dateDebut')?.touched
                              " class="invalid-feedback">
                      <div *ngIf="paiementForm.get('dateDebut')?.errors?.['required']">
                        La date debut est requise.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="dateFin">Date fin<span class="text-danger">*</span></label>
                    <input type="date" formControlName="dateFin" class="form-control" id="dateFin" [ngClass]="{
                                                'is-invalid':
                                                  paiementForm.get('dateFin')?.invalid &&
                                                  paiementForm.get('dateFin')?.touched
                                              }" />
                    <div *ngIf="
                                                paiementForm.get('dateFin')?.invalid &&
                                                paiementForm.get('dateFin')?.touched
                                              " class="invalid-feedback">
                      <div *ngIf="paiementForm.get('dateFin')?.errors?.['required']">
                        La date fin est requise.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="categorie">Etat <span class="text-danger">*</span></label>
                    <select type="text" formControlName="etat" class="form-control" [ngClass]="{
                                                                        'is-invalid':
                                                                          paiementForm.get('etat')?.invalid &&
                                                                          paiementForm.get('etat')?.touched
                                                                      }">
                      <option value="">Sélectionner l'etat</option>
                      <option *ngFor="let param of params2$ | async" [value]="param.valeur">
                        {{ param.libelle }}
                      </option>
                    </select>
                    <div *ngIf="paiementForm.get('etat')?.errors?.['etat'] && paiementForm.get('etat')?.touched"
                      class="invalid-feedback">
                      La catégorie est requise.
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "EXCEL" -->
          <button type="submit" class="btn btn-primary ms-2" [disabled]="paiementForm.invalid" *ngIf="!isLoading"
            (click)="selectedButton = 'xlsx'">
            EXCEL
          </button>

          <!-- Bouton "PDF" -->
          <button type="submit" class="btn btn-secondary ms-2" [disabled]="paiementForm.invalid" *ngIf="!isLoading"
            (click)="selectedButton = 'pdf'">
            PDF
          </button>
        </div>
      </form>
      <div class="mb-5"></div>
      <iframe *ngIf="pdfUrl" [src]="pdfUrl" width="100%" height="500px"></iframe>
    </app-card>
  </div>
</div>