import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationChaufComponent } from './creation-chauf/creation-chauf.component';
import { ChaufDetailsComponent } from './chauf-details/chauf-details.component';

const routes: Routes = [

  {
    path: 'ajout-chauffeur',
    component: CreationChaufComponent
  },
  {
    path: 'detail-chauffeur',
    component: ChaufDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionChauffeurRoutingModule { }
