// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// project import

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { LoginService } from '../services/login.service';
import { SessionService } from '../services/session.service';
import { Login } from '../models/login';
import { LoginReponse } from '../reponses/login.response';
import { FcmService } from 'src/app/helpers/fcm-service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  classList!: { toggle: (arg0: string) => void };
  patternEmail = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  loginData: Login = new Login();
  pagesPermiteds: any = [];
  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  isLoadingForget: boolean = false;
  errorMessage: any;
  successMessage: any;
  loginPayload = new Login();
  BaseUrl: any = window.location.origin;
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.patternEmail)]),
    password: new FormControl('', [Validators.required])
  });
  viewForget: boolean = false;

  forgetForm = new FormGroup({
    emailForget: new FormControl('', [Validators.required, Validators.pattern(this.patternEmail)])
  });
  array: any;

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: SessionService,
    private fcmService: FcmService
  ) {
    this.localStorageService.clearData();
    this.fcmService.requestPermission();
    this.fcmService.currentMessage.subscribe(message => {
      console.log("message", message);
    });
  }

  ngOnInit(): void {
    const togglePassword = document.querySelector('#togglePassword');
    const password = document.querySelector('#password');

    togglePassword?.addEventListener('click', () => {
      // toggle the type attribute
      const type = password?.getAttribute('type') === 'password' ? 'text' : 'password';
      password?.setAttribute('type', type);
      togglePassword?.setAttribute('class', type === 'password' ? 'ti ti-eye-off' : 'ti ti-eye');
      // toggle the icon
      //this.classList.toggle('ti-eye-off');
    });
    //this.checkLogin();
  }

  get emailForget() {
    return this.forgetForm?.get('emailForget');
  }

  get email() {
    return this.loginForm?.get('email');
  }
  get password() {
    return this.loginForm?.get('password');
  }

  forget() {
    this.viewForget = true;
  }

  onBack() {
    this.viewForget = false;
  }

  onReset() {
    this.isLoadingForget = true;
    var emailForgetpaylaod = this.forgetForm?.get('emailForget')?.value;
    var email = { email: emailForgetpaylaod };
    this.loginService.reset(email).subscribe({
      next: (data) => {
        this.hasError = false;
        this.hasSuccess = true;
        this.isLoadingForget = false;
        this.successMessage = "Un email, vous êtes envoyé. veuillez verifier votre email";
      },
      error: (err) => {
        if (err.status == 401) {
          this.errorMessage = "Une erreur s'est produite, vérifiez votre email";
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoadingForget = false;
        } else {
          this.errorMessage = "Nous n'arrivons pas à traiter votre demande. Veuillez réessayer";
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoadingForget = false;
        }
      },
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.loginPayload.username = this.loginForm?.get('email')?.value;
    this.loginPayload.password = this.loginForm?.get('password')?.value;

    this.loginService.login(this.loginPayload).subscribe({
      next: (data) => {
        this.hasError = false;
        this.isLoading = false;


        const loginResposne: LoginReponse = data;
        const menus = loginResposne.profil?.menus ?? [];
        const sortedMenus = menus.sort((a: any, b: any) => (a.ordre ?? 0) - (b.ordre ?? 0));
        this.localStorageService.saveData('user', JSON.stringify(loginResposne));
        this.localStorageService.saveData('token', loginResposne.token!);
        this.localStorageService.refresh();


        for (const element of sortedMenus) {
          if (!element.lien && element.sousMenus?.length) {
            // Naviguer vers le lien du premier sous-menu
            this.router.navigate([element.sousMenus[0].lien]).then(() => {

              window.location.reload();
            });
            break; // Sortir de la boucle après la navigation
          } else if (element.lien) {
            // Naviguer vers le lien principal
            this.router.navigate([element.lien]).then(() => {

              window.location.reload();
            });
            break; // Sortir de la boucle après la navigation
          }
        }

        // this.router.navigate(['/app/dashboard/default'])

        /*  window.location.assign(this.BaseUrl + '/#/'+ this.array[0].path); */
      },
      error: (err) => {
        if (err.status == 401) {
          this.errorMessage = "Vous n'êtes pas autorisé à vous connecter";
          this.hasError = true;
          this.isLoading = false;
        } else {
          this.errorMessage = "Nous n'arrivons pas à traiter votre demande. Veuillez réessayer";
          this.hasError = true;
          this.isLoading = false;
        }
      },
    });
  }
  checkLogin() {
    if (this.localStorageService.isLoggedIn) {
      if (this.pagesPermiteds != 0) {
        this.router.navigate(['/' + this.pagesPermiteds[0].path])
          .then(() => {
            window.location.reload();
          })
      } else {
        //window.location.assign(this.BaseUrl + '/#/login');
        this.router.navigate(['/login'])
          .then(() => {
            window.location.reload();
          })
      }
    }
  }



}

