<div class="ms-auto">
  <ul class="list-unstyled">
    <li appToggleFullScreen class="pc-h-item">
      <a href="javascript:" title="click to theme view in full-screen"
        class="full-screen pc-head-link head-link-secondary me-0 mobile-menu"><i class="feather icon-maximize"></i></a>
    </li>
    <li>
      <div class="dropdown pc-h-item pc-mega-menu" ngbDropdown>
        <a class="pc-head-link head-link-secondary dropdown-toggle arrow-none me-0" href="javascript:"
          ngbDropdownToggle>
          <i class="ti ti-access-point"></i>
        </a>
        <div class="dropdown-menu pc-h-dropdown pc-mega-dmenu" ngbDropdownMenu>
          <div class="row g-0">
            <div class="col text-center image-block">
              <img src="assets/images/pages/img-megamenu.svg" alt="image" class="img-fluid rounded" />
            </div>
            @for (section of componentSections; track section) {
            <div class="col">
              <h6 class="mega-title">{{ section.title }}</h6>
              <ul class="pc-mega-list">
                @for (item of section.items; track item) {
                <li>
                  <a [routerLink]="[item.url]" class="dropdown-item"><i class="fas fa-circle"></i> {{ item.title }}</a>
                </li>
                }
              </ul>
            </div>
            }
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="dropdown pc-h-item" ngbDropdown>
        <a class="pc-head-link head-link-primary dropdown-toggle arrow-none me-0" href="javascript:" ngbDropdownToggle>
          <i class="ti ti-language"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
          <a (click)="useLanguage('en')" class="dropdown-item">
            <span>English <small>(UK)</small></span>
          </a>
          <a (click)="useLanguage('fr')" class="dropdown-item">
            <span>français <small>(French)</small></span>
          </a>
          <a (click)="useLanguage('ro')" class="dropdown-item">
            <span>Română <small>(Romanian)</small></span>
          </a>
          <a (click)="useLanguage('cn')" class="dropdown-item">
            <span>中国人 <small>(Chinese)</small></span>
          </a>
        </div>
      </div>
    </li>
    <li>
      <div class="dropdown pc-h-item" ngbDropdown>
        <a class="pc-head-link head-link-secondary dropdown-toggle arrow-none me-0" href="javascript:"
          ngbDropdownToggle>
          <i class="ti ti-bell"></i>
          <span class="notification-badge">{{ unseenNotificationsCount }}</span>
        </a>
        <div class="dropdown-menu dropdown-notification dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
          <div class="dropdown-header">
            <a href="javascript:!" class="link-primary float-end text-decoration-underline"
              (click)="updateNotificationsByEtat()">Marquer comme tous lus</a>
            <h5>
              Notifications
              <span class="badge bg-warning rounded-pill ms-1">{{ unseenNotificationsCount }}</span>
            </h5>
          </div>
          <ng-scrollbar style="height: calc(100vh - 215px)" visibility="hover">
            <div class="dropdown-header px-0 text-wrap">
              <div class="list-group list-group-flush w-100">
                <div class="list-group-item">
                  <select class="form-select" (change)="onNotificationChange($event)">
                    <option value="ALL">Toutes les notifications</option>
                    <option value="UNSEEN">Nouvelles</option>
                  </select>
                </div>
                @for (item of notifications; track item) {
                <a class="list-group-item list-group-item-action">
                  <div class="d-flex">
                    <div class="flex-shrink-0">
                      <img src="assets/images/user/icons8-avatar-50.png" alt="user-image" class="user-avtar" />

                    </div>
                    <div class="flex-grow-1 ms-1">
                      <span class="float-end text-muted">{{ item.dateCreation | timeAgo }}</span>
                      <h5>{{ item.titre }}</h5>
                      <p class="text-muted fs-6">{{ item.description }} </p>
                      @if (item.etat === 'UNSEEN'){
                      <div class="badge rounded-pill bg-light-warning">Nouvelle</div>
                      }@else{
                      <div class="badge rounded-pill bg-light-danger">Lu</div>
                      }


                    </div>
                  </div>
                </a>
                }
              </div>
            </div>
          </ng-scrollbar>
          <div class="dropdown-divider"></div>
          <div class="text-center py-2">
            <a href="javascript:!" (click)="updateNotificationsByEtat()" class="link-primary">Marquer comme tous lus</a>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="dropdown pc-h-item header-user-profile" ngbDropdown>
        <a class="pc-head-link head-link-primary dropdown-toggle arrow-none me-0" href="javascript:" ngbDropdownToggle>
          <img src="assets/images/user/avatar-2.jpg" alt="user-image" class="user-avtar" />
          <span>
            <i class="ti ti-settings"></i>
          </span>
        </a>
        <div class="dropdown-menu dropdown-user-profile dropdown-menu-end pc-h-dropdown" ngbDropdownMenu>
          <div class="dropdown-header">

            <h4> Utilisateur: <span class="small text-muted"> {{userName}}</span> </h4>

            <hr />
            <ng-scrollbar style="height: calc(100vh - 280px)" visibility="hover">
              <div class="upgradeplan-block bg-light-warning rounded">
                <h4> Profil: <span class="small text-muted"> {{profile}}</span> </h4>

              </div>
              <hr />


              <a [routerLink]="['/app/gestion-compte/profile']" class="dropdown-item">
                <i class="ti ti-settings"></i>
                <span>Mes informations</span>
              </a>

              <a class="dropdown-item pe-auto" href="javascript:" (click)="logout()">
                <i class="ti ti-logout"></i>
                <span>Se déconnecter</span>
              </a>
            </ng-scrollbar>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
