// Angular import
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { LoginService } from '../services/login.service';
import { ChangePassword } from '../models/change.password';
import { Form, FormControl, FormGroup, Validators } from '@angular/forms';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-check-mail',
  standalone: true,
  imports: [CommonModule, SharedModule, ResetPasswordComponent],
  templateUrl: './check-mail.component.html',
  styleUrls: ['./check-mail.component.scss']
})
export class CheckMailComponent {
  email: string | null = null;
  patternEmail = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  loginData: ChangePassword = new ChangePassword();
  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  successMessage: any;
  errorMessage: any;
  forgotForm: FormGroup;
  isReset: boolean = false;
  constructor(private loginService: LoginService, private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    this.email = navigation?.extras.state?.['email'] || null;
    this.forgotForm = new FormGroup({
      code: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(this.patternEmail)]),
    });
    this.forgotForm.get('email')?.setValue(this.email);
  }

  onSubmit() {
    this.isLoading = true;
    const payload: ChangePassword = this.forgotForm.value;
    payload.token = this.forgotForm.get('code')?.value;
    this.loginService.verify(payload).subscribe({
      next: (data) => {
        this.hasError = false;
        this.hasSuccess = true;
        this.isLoading = false;
        this.router.navigate(['/auth/reset-password'],
          {
            state: { email: this.forgotForm.get('email')?.value, code: this.forgotForm.get('code')?.value },
          }
        );
        this.successMessage = "Email vérifié avec succès";
      },
      error: (err) => {
        if (err.status == 401) {
          this.errorMessage = "Une erreur s'est produite, vérifiez votre email";
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
        } else {
          this.errorMessage = "Nous n'arrivons pas à traiter votre demande. Veuillez réessayer";
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
        }
      },
    });
  }
}
