import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { CurrencyResponse } from '../reponse/currency.reponse';
import { Stat2Response } from '../reponse/stat2.reponse';

@Injectable({
  providedIn: 'root',
})
export class DashBoardService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }





  getByDate(date: any): Observable<CurrencyResponse> {
    return this.http.get<any>(`${this.url}/statistiques/vente/${date}`, this.getHttpOptions());
  }
  getStatByDate(date: any): Observable<Stat2Response> {
    return this.http.get<any>(`${this.url}/statistiques/count/${date}`, this.getHttpOptions());
  }




}
