<div *ngIf="!isReset" class="auth-main">
  <div class="auth-wrapper v3">
    <div class="auth-form">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="d-flex justify-content-center">
              <div class="text-center">

                <p class="f-16 mt-3">
                  Nous vous avons envoyez les informations de restaurations de votre mot de passe par mail.</p>
                <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
                  <p>{{successMessage}}</p>
                </div>
                <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
                  <p>{{errorMessage}}</p>
                </div>
                <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
                  <div class="form-floating mb-2">
                    <input type="text" class="form-control" id="floatingInput" formControlName="code" />
                    <label for="floatingInput">Code</label>
                    <div *ngIf="forgotForm.get('code')?.invalid && forgotForm.get('code')?.touched"
                      class="invalid-feedback">
                      Le code est requis
                    </div>
                  </div>
                  <div class="d-grid mt-4">

                    <button *ngIf="isLoading" class="btn btn-secondary" type="button" disabled>
                      <span class="spinner-grow spinner-grow-sm" role="status"></span>
                      Traitement...
                    </button>
                    <button *ngIf="!isLoading" class="btn btn-secondary "
                      [disabled]="!forgotForm.valid">Valider</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>