import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { Subject } from 'rxjs';
import { VenteVehiculeReponse } from '../../gestion-location-vente/vente-vehicule/reponse/vente.vehicule.reponse';
import { FormGroup } from '@angular/forms';
import { Config } from 'src/app/helpers/config';

@Component({
  selector: 'app-table-suppression',
  standalone: true,
  imports: [CommonModule, DataTablesModule],
  templateUrl: './table-suppression.component.html',
  styleUrl: './table-suppression.component.scss',
})
export class TableSuppressionComponent implements OnInit, AfterViewInit {
  @Input() paiementForm: FormGroup | any;
  dtTrigger: Subject<any> = new Subject();
  selectedVehicule: any;
  // public props
  dtRouterLinkOptions: object = {};

  @ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;


  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable(ref?: any) {
    if (ref) {
      // Reload the page if ref is truthy
      window.location.reload();
    }
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/commandes/workflow/deleting/processing`;
    this.dtRouterLinkOptions = {
      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },

        error: function (xhr: any, error: any, thrown: any) {
          console.error('AJAX error:', xhr, error, thrown);
        },
        dataSrc: function (json: VenteVehiculeReponse[]) {
          // Flatten the detailCommandes array
          const flatData = json.flatMap((command: any) =>
            command.detailCommandes.map((detail: any) => ({
              idCommande: command.idCommande,
              numero: command.numero,
              montant: command.montant,
              reste: command.reste,
              etat: command.etat,
              motifSuppression: command.motifSuppression,
              descriptionMotif: command.descriptionMotif,
              dateCreation: command.dateCreation
            }))
          );

          return flatData;
        }
      },
      columns: [
        { title: 'Numéro', data: 'numero' },
        { title: 'Montant', data: 'montant' },
        { title: 'Reste à payer', data: 'reste' },

        {
          title: 'Etat',
          data: 'etat',
          render: function (data: string, type: string, row: any) {
            return Config.customizeBadgeClass(data);
          }
        },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idCommande',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => {
            return `
       <button class="btn btn-link-primary btn-edit"
                title="Edit"
                id="edit-${row.idCommande}"
                data-info='${JSON.stringify({ idCommande: row.idCommande, montant: row.montant, reste: row.reste, numero: row.numero, etat: row.etat, motifSuppression: row.motifSuppression, descriptionMotif: row.descriptionMotif }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-pencil"></i>
        </button>
    `;
          }
        }
      ],
      responsive: true,
      select: false,
    };

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(this.dtRouterLinkOptions);
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      $('#paiementTable tbody').on('click', '.btn-edit', (event) => {
        const $target = $(event.currentTarget);
        const row = $target.data('info');
        this.paiementForm?.patchValue({
          montant: row['montant'],
          reste: row['reste'],
          oldMontant: row['montant'],
          numFacture: row['numero'],
          idCommande: row['idCommande'],
          etat: row['etat'],
          motif: row['motifSuppression'],
          motifSppression: row['motifSuppression'],
          descriptionMotif: row['descriptionMotif']
        });

      });
    });
  }




}

