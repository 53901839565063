import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionArticleRoutingModule } from './gestion-article-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionArticleRoutingModule
  ]
})
export class GestionArticleModule { }
