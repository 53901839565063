// Angular import
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { Router, RouterModule } from '@angular/router';
import { ChangePassword } from '../models/change.password';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  patternEmail = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  loginData: ChangePassword = new ChangePassword();
  hasError: boolean = false;
  hasSuccess: boolean = false;
  isLoading: boolean = false;
  successMessage: any;
  errorMessage: any;
  forgotForm: FormGroup;
  email: string | null = null;
  code: string | null = null;

  constructor(private router: Router, private fb: FormBuilder, private loginService: LoginService) {
    const navigation = this.router.getCurrentNavigation();
    this.forgotForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(this.patternEmail)]),
      code: new FormControl('', [Validators.required]),
      token: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    }, { validators: this.passwordMatchValidator() });

    this.email = navigation?.extras.state?.['email'] || null;
    this.code = navigation?.extras.state?.['code'] || null;
    this.forgotForm.get('email')?.setValue(this.email);
    this.forgotForm.get('code')?.setValue(this.code);
    this.forgotForm.get('token')?.setValue(this.code);
  }
  passwordMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password')?.value;
      const confirmPassword = control.get('confirmPassword')?.value;

      return password === confirmPassword ? null : { passwordMismatch: true };
    }
  }

  onSubmit() {
    this.isLoading = true;
    const payload = this.forgotForm.value;
    this.loginService.reset(payload).subscribe({
      next: (data) => {
        this.hasError = false;
        this.hasSuccess = true;
        this.isLoading = false;
        this.successMessage = "Mot de passe mis à jour";
        this.router.navigate(['/auth/login']);
      },
      error: (err) => {
        if (err.status == 401) {
          this.errorMessage = "Une erreur s'est produite, vérifiez votre email";
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
        } else {
          this.errorMessage = "Nous n'arrivons pas à traiter votre demande. Veuillez réessayer";
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
        }
      },
    });
  }
}
