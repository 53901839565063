import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  private pagesPermitted: string[] = [];
  private userConnected: any;

  constructor(
    private router: Router,
    private sessionService: SessionService
  ) {
    const pagesData = this.sessionService.getData('pages');
    const userData = this.sessionService.getData('user');

    this.pagesPermitted = pagesData ? JSON.parse(pagesData) : [];
    this.userConnected = userData ? JSON.parse(userData) : null;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: string = state.url;
    return this.checkUserLogin(next, url);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  private checkUserLogin(route: ActivatedRouteSnapshot, url: string): boolean | UrlTree {
    if (this.userConnected) {
      return true;
    }

    // Redirige vers la page de login
    return this.router.createUrlTree(['/auth/login']);
  }
}
