// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { TableParametreComponent } from "../table-parametre/table-parametre.component";
import { ParametreService } from '../service/parametre.service';
import { ParametreModele } from '../modeles/parametre.modele';
import { ParametreReponse } from '../reponse/parametre.reponse';

@Component({
  selector: 'app-creation-parametre',
  standalone: true,
  imports: [CommonModule, SharedModule, TableParametreComponent],
  templateUrl: './creation-parametre.component.html',
  styleUrl: './creation-parametre.component.scss'
})
export class CreationParametreComponent {
  categorieForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private parametreService: ParametreService
  ) {
    this.categorieForm = this.fb.group({
      idParametre: [''],
      libelle: ['', [Validators.required]],
      valeur: ['', [Validators.required]],
      etat: ['', [Validators.required]],
    });
    this.categorieForm.get('libelle')?.disable();

  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.onSubmitEdit();

  }

  onSubmitEdit() {
    this.isLoading = true;
    if (this.categorieForm.valid) {
      const idParametre = this.categorieForm.get('idParametre')?.value;
      const payload: ParametreModele = this.categorieForm.value;
      payload.idParametre = null;
      this.parametreService.update(payload, idParametre).subscribe(
        (response: ParametreReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Paramètre modifié avec succès.';
          this.categorieForm.get('idParametre')?.setValue("");
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la modification du paramètre', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  onReset() {
    this.categorieForm.reset();
  }

}
