import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MarqueModele } from '../modeles/marque.modele';
import { MarqueService } from '../services/marque.service';
import { MarqueReponse } from '../reponses/marque.reponse';
import { Observable } from 'rxjs';
import { ListeMComponent } from '../liste-m/liste-m.component';


@Component({
  selector: 'app-creation-m',
  standalone: true,
  imports: [CommonModule, ListeMComponent, SharedModule, RouterModule, FileUploadModule, ReactiveFormsModule],
  templateUrl: './creation-m.component.html',
  styleUrl: './creation-m.component.scss'
})
export class CreationMComponent implements OnInit {
  marqueForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  showForm: boolean = false;
  buttonName: string = '+';
  constructor(private fb: FormBuilder, private marqueService: MarqueService) {
    this.marqueForm = this.fb.group({
      idMarque: [''],
      libelle: ['', [Validators.required, Validators.minLength(1)]],
      description: ['', Validators.required]
    });
  }

  handlePlusClick() {
    this.showForm = !this.showForm;
    this.buttonName = this.showForm ? '-' : '+';
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    if (this.marqueForm.get('idMarque')?.value == "") {
      this.isLoading = true;
      if (this.marqueForm.valid) {
        const payload: MarqueModele = this.marqueForm.value;
        payload.idMarque = null;
        this.createMarque(payload).subscribe(
          (response: MarqueReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.successMessage = 'Marque de véhicule ajoutée avec succès.';
            this.marqueForm.reset();
          },
          (error: any) => {
            this.hasError = true;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de la création de la marque', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    } else {
      this.onSubmitEdit();
    }
  }

  // Méthode pour envoyer la requête
  createMarque(payload: MarqueModele): Observable<MarqueReponse> {
    return this.marqueService.create(payload);
  }

  ngOnInit(): void {

  }


  onReset() {
    this.marqueForm.reset();
  }

  onSubmitEdit() {
    this.isLoading = true;
    if (this.marqueForm.valid) {
      const idMarque = this.marqueForm.get('idMarque')?.value;
      this.marqueForm.get('idMarque')?.setValue("");
      const payload: MarqueModele = this.marqueForm.value;
      payload.idMarque = null;
      this.marqueService.update(payload, idMarque).subscribe(
        (response: MarqueReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Marque de véhicule modifiée avec succès.';
          this.marqueForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la modification de la marque', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

}
