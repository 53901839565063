import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ClientService } from './service/client.service';
import { ClientModele } from './modeles/client.modele';
import { ClientReponse } from './reponse/client.reponse';
import { TableClientComponent } from "./table-client/table-client.component";

@Component({
  selector: 'app-client-liste',
  standalone: true,
  imports: [CommonModule, TableClientComponent, SharedModule, RouterModule],
  templateUrl: './client.component.html',
  styleUrl: './client.component.scss',
})
export class ClientComponent implements OnInit {
  clientForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  showForm: boolean = false;
  buttonName: string = '+';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private clientService: ClientService
  ) {
    this.clientForm = this.fb.group({
      nom: [''],
      nomEntreprise: [''],
      prenoms: [''],
      email: [[''], [Validators.required]],
      adresse: [''],
      telephone: [[''], [Validators.required]],
      idClient: [''],
      categorie: ['0', [Validators.required]],
    });
  }
  onReset() {
    this.clientForm.reset();
    this.clientForm.get('categorie')?.setValue('0');
  }
  handlePlusClick() {
    this.showForm = !this.showForm;
    this.buttonName = this.showForm ? '-' : '+';
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    if (this.clientForm.get('idClient')?.value == "") {
      this.hasError = false;
      this.hasSuccess = false;
      if (this.clientForm.valid) {
        this.isLoading = true;
        const payload: ClientModele = this.clientForm.value;
        this.clientService.create(payload).subscribe(
          (response: ClientReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.hasError = false;
            this.successMessage = 'Client ajouté avec succès.';
            this.clientForm.reset();
          },
          (error: any) => {
            this.hasError = true;
            this.hasSuccess = false;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de la création du Client', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    }
    else {
      this.onSubmitEdit();
    }
  }

  // Méthode pour envoyer la requête

  onSubmitEdit() {

    this.hasError = false;
    this.hasSuccess = false;
    if (this.clientForm.valid) {
      this.isLoading = true;
      const payload: ClientModele = this.clientForm.value;
      this.clientService.update(payload, this.clientForm.get('idClient')?.value).subscribe(
        (response: ClientReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.hasError = false;
          this.successMessage = 'Client modifiée avec succès.';
          this.clientForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du Client', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }


  ngOnInit(): void {

  }
}
