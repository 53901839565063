// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { TableArticleComponent } from "../table-article/table-article.component";
import { CompagnieService } from '../../gestion-compagnie-assurance/service/compagnie.service';
import { CompagnieModele } from '../../gestion-compagnie-assurance/modeles/compagnie.modele';
import { CompagnieReponse } from '../../gestion-compagnie-assurance/reponse/compagnie.reponse';
import { CategorieReponse } from '../../gestion-categorie/reponse/categorie.reponse';
import { EMPTY, Observable } from 'rxjs';
import { CategorieService } from '../../gestion-categorie/service/categorie.service';
import { lib } from 'crypto-js';
import { ProduitService } from '../service/produit.service';
import { ProduitReponse } from '../reponse/produit.reponse';
import { ProduitModele } from '../modeles/produit.modele';

@Component({
  selector: 'app-creation-article',
  standalone: true,
  imports: [CommonModule, SharedModule, TableArticleComponent],
  templateUrl: './creation-article.component.html',
  styleUrl: './creation-article.component.scss'
})
export class CreationArticleComponent implements OnInit {
  produitForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  categories$: Observable<CategorieReponse[]> = EMPTY;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private categorieService: CategorieService,
    private produitService: ProduitService
  ) {
    this.categories$ = this.categorieService.getAll();
    this.produitForm = this.fb.group({
      libelle: ['', [Validators.required]],
      code: [[''], [Validators.required]],
      idCategorie: [[''], [Validators.required]],
      description: [''],
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.produitForm.valid) {
      const payload: ProduitModele = this.produitForm.value;

      this.produitService.create(payload).subscribe(
        (response: ProduitReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Produit ajouté avec succès.';
          this.produitForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du produit', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
