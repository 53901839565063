import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { environment } from 'src/environments/environment';
import { VenteVehiculeReponse } from '../reponse/vente.vehicule.reponse';

@Component({
  selector: 'app-liste-vente-vehicule',
  standalone: true,
  imports: [CommonModule, DataTablesModule, SharedModule, RouterModule],
  templateUrl: './liste.vente.vehicule.component.html',
  styleUrl: './liste.vente.vehicule.component.scss'
})
export class ListeVenteVehiculeComponent {

  selectedVehicule: any;
  // public props
  dtRouterLinkOptions: object = {};

  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }
  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/commandes/type/V_VEHICULE`;

    this.dtRouterLinkOptions = {
      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        dataSrc: function (json: VenteVehiculeReponse[]) {
          // Flatten the detailCommandes array
          const flatData = json.flatMap((command: any) =>
            command.detailCommandes.map((detail: any) => ({
              marque: detail.vehicule.marque.libelle,
              modele: detail.vehicule.modele.libelle,
              type: detail.vehicule.type.libelle,
              idCommande: command.idCommande,
              numero: command.numero,
              immatriculationDefinitive: detail.vehicule.immatriculationDefinitive,
              immatriculationProvisoire: detail.vehicule.immatriculationProvisoire,
              etat: detail.vehicule.etat,
              dateCreation: command.dateCreation
            }))
          );
          return flatData;
        }
      },
      columns: [
        { title: 'Numéro', data: 'numero' },
        { title: 'Marque', data: 'marque' },
        { title: 'Modele', data: 'modele' },
        { title: 'Type', data: 'type' },
        { title: 'IMT Définitive', data: 'immatriculationDefinitive' },
        { title: 'IMT Provisoire', data: 'immatriculationProvisoire' },
        {
          title: 'Etat',
          data: 'etat',
          render: function (data: string, type: string, row: any) {
            // Customize badge class based on 'etat' value
            let badgeClass = 'badge bg-light-success rounded f-12';

            // You can add more conditions here if needed
            // For example, different colors for different states
            if (data === 'Pending') {
              badgeClass = 'badge bg-warning rounded f-12';
            } else if (data === 'ACTIVATED') {
              badgeClass = 'badge bg-light-success rounded f-12';
            } else if (data === 'Canceled') {
              badgeClass = 'badge bg-danger rounded f-12';
            }

            // Return the HTML for the badge
            return `<span class="${badgeClass}">${data}</span>`;
          }
        },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: null,
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: function () {
            return '<span><button class="btn btn-link-primary btn-view" title="View"><i class="ti ti-eye"></i></button></span> <span><button class="btn btn-link-primary" title="Delete"><i class="ti ti-trash"></i></button></span> <button class="btn btn btn-link-secondary" title="Edit"><i class="ti ti-pencil"></i></button>';
          }
        }
      ],
      responsive: true,
      select: true,
      drawCallback: () => {
        const component = this; // Capture the Angular component context
        // Handle the click event on the 'View' button
        $('.btn-view').on('click', function () {
          const rowElement = $(this).closest('tr');
          const rowData = $('#tableVehicule').DataTable().row(rowElement).data();
          const data = JSON.stringify(rowData, null, 2);
          console.log(data);

          // Call the Angular route function
          component.navigateToRoute(data); // Ensure correct context
        });
      }
    };
  }
  navigateToRoute(data: any) {
    this.router.navigate(['/app/gestion-location-vente/vente-vehicule-detail', JSON.parse(data).idCommande]);
  }



}

