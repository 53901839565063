import { Injectable } from '@angular/core';
import { messaging } from 'firebase.config';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from '../Pages/auth/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  currentMessage = new BehaviorSubject(null);

  constructor(private localStorageService: SessionService) {
    messaging.onMessage((incomingMessage) => {
      console.log(incomingMessage);

      this.currentMessage.next(incomingMessage);
    })
  }

  requestNotificationPermission() {
    if ('Notification' in window && Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.error('Notification permission denied.');
        }
      });
    }
  }

  requestPermission() {
    messaging.getToken({ vapidKey: environment.firebaseConfig.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.localStorageService.saveData('addressPusher', currentToken);
          console.log(currentToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log(err);
      });
  }


}
