// Angular import
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { CategorieReponse } from '../../gestion-categorie/reponse/categorie.reponse';
import { EMPTY, Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { matches, set } from 'lodash';
import { LoginService } from '../services/login.service';
import { ChangePassword } from '../models/change.password';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent {
  pwForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  categories$: Observable<CategorieReponse[]> = EMPTY;

  userName: string = '';
  profile: string = '';
  email: string = '';
  tel: string = '';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private loginService: LoginService,
    private localStorageService: SessionService
  ) {
    const user = this.localStorageService.getData('user');
    this.userName = JSON.parse(user).nom + ' ' + JSON.parse(user).prenoms;
    this.profile = JSON.parse(user).profil?.libelle;
    this.email = JSON.parse(user).email;
    this.tel = JSON.parse(user).telephone;

    this.pwForm = fb.group({
      oldPw: fb.control('', [Validators.required]),
      pw: fb.control('', [Validators.required]),
      check: fb.control('', [Validators.required])
    });

    const pwControl = this.pwForm.get('pw');
    const checkControl = this.pwForm.get('check');

    if (pwControl && checkControl) {
      this.pwForm.addValidators(this.matchValidator(pwControl, checkControl));
    }
  }





  matchValidator(control: AbstractControl, controlTwo: AbstractControl): ValidatorFn {
    return () => {
      if (control.value !== controlTwo.value) return { match_error: 'Les mot de passes ne correspondent pas' };
      return matches;
    };
  }


  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    const pwControl = this.pwForm.get('pw');


    if (this.pwForm.valid) {
      this.pwForm.addValidators(this.matchValidator(this.pwForm.get('pw')!, this.pwForm.get('check')!));
      const payload: ChangePassword = {
        email: this.email,
        oldPassword: this.pwForm.value.oldPw,
        password: this.pwForm.value.pw
      };

      this.loginService.change(payload).subscribe(
        (response: any) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Modification effectuée avec succès.';
          this.pwForm.reset();
          setTimeout(() => {
            this.localStorageService.clearData();
            this.router.navigate(['/auth/login']);
          }, 1000);

        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la modification', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }


}
