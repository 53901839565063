<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Actions Workflow">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="workflowForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="typeVehicule">Profils<span class="text-danger">*</span></label>
                <select formControlName="profileId" class="form-select" [ngClass]="{
                                                  'is-invalid':
                                                    workflowForm.get('profileId')?.invalid &&
                                                    workflowForm.get('profileId')?.touched
                                                }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let profil of profils" [value]="profil.idProfil">
                    {{ profil.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="workflowForm.get('profileId')?.errors?.['required'] && workflowForm.get('profileId')?.touched"
                  class="invalid-feedback">
                  Le profil est requis.
                </div>
              </div>
              <div class="form-group">
                <label for="workflowState">Etats<span class="text-danger">*</span></label>
                <select formControlName="stepId" class="form-select" [ngClass]="{
                                                                  'is-invalid':
                                                                    workflowForm.get('stepId')?.invalid &&
                                                                    workflowForm.get('stepId')?.touched
                                                                }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let reponse of workflowStateReponse" [value]="reponse.idWorkflowState">
                    {{ reponse.description }}
                  </option>
                </select>
                <div *ngIf="workflowForm.get('stepId')?.errors?.['required'] && workflowForm.get('stepId')?.touched"
                  class="invalid-feedback">
                  L'etat est requis.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="libelle">Ordre <span class="text-danger">*</span></label>
              <input type="number" formControlName="stateOrder" class="form-control" id="stateOrder" [ngClass]="{
                  'is-invalid':
                    workflowForm.get('stateOrder')?.invalid &&
                    workflowForm.get('stateOrder')?.touched
                }" />
              <div *ngIf="
                  workflowForm.get('stateOrder')?.invalid &&
                  workflowForm.get('stateOrder')?.touched
                " class="invalid-feedback">
                <div *ngIf="workflowForm.get('stateOrder')?.errors?.['required']">
                  L'ordre est requis.
                </div>

              </div>

              <div class="d-flex justify-content-end mt-3">
                <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
                  <span class="spinner-grow spinner-grow-sm" role="status"></span>
                  Traitement...
                </button>

                <!-- Bouton "Enregistrer" -->
                <button type="submit" class="btn btn-primary" [disabled]="workflowForm.invalid" *ngIf="!isLoading">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="mb-5"></div>
      <app-detail-workflow></app-detail-workflow>
    </app-card>
  </div>
</div>