<div class="row">
  <!-- [ sample-page ] start -->
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header pb-0">
        <ul ngbNav aria-busy="true" #nav="ngbNav" class="nav-tabs nav profile-tabs">

          <li class="nav-item" [ngbNavItem]="1">
            <a href="javascript:" ngbNavLink class="nav-link"> <i
                class="material-icons-two-tone me-2">library_books</i>Mes
              informations </a>
            <ng-template ngbNavContent>
              <app-card cardTitle="Infos">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="Username">Utilisateur <span class="text-danger">*</span></label>
                      <input type="text" id="Username" class="form-control" value="{{userName}}" disabled />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="Account Email">Email <span class="text-danger">*</span></label>
                      <input type="text" id="Account Email" class="form-control" value="{{email}}" disabled />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="Téléphone">Téléphone <span class="text-danger">*</span></label>
                      <input type="text" id="Téléphone" class="form-control" value="{{tel}}" disabled />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="form-label" for="Profil">Profil <span class="text-danger">*</span></label>
                      <input type="text" id="Profil" class="form-control" value="{{profile}}" disabled />
                    </div>
                  </div>

                </div>
              </app-card>
            </ng-template>
          </li>
          <li class="nav-item" [ngbNavItem]="2">
            <a href="javascript:" ngbNavLink class="nav-link"> <i class="material-icons-two-tone me-2">lock</i>Changer
              de mot de passe</a>
            <ng-template ngbNavContent>
              <form [formGroup]="pwForm" (ngSubmit)="onSubmit()">
                <div class="alert alert-warning" role="alert">
                  <h5 class="alert-heading"> <i class="feather icon-alert-circle me-2"></i>Avertissement! </h5>
                  <p> Votre mot de passe ne doit pas contenir votre nom d'utilisateur. </p>
                  <hr />
                  <p class="mb-0"><b>Ne communiquez pas votre mot de passe</b></p>
                </div>
                <div class="card border">
                  <div class="card-header">
                    <h5>Changer</h5>
                  </div>
                  <div class="card-body">
                    <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
                      <p>{{successMessage}}</p>
                    </div>
                    <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
                      <p>{{errorMessage}}</p>
                    </div>
                    <div class="row" [formGroup]="pwForm">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="form-label">Mot de passe actuel <span class="text-danger">*</span></label>
                          <input type="password" class="form-control" placeholder="Entrez votre mot de passe actuel"
                            formControlName="oldPw" />
                        </div>
                      </div>
                    </div>
                    <div class="row" [formGroup]="pwForm">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="form-label">Nouveau mot de passe <span class="text-danger">*</span></label>
                          <input type="password" class="form-control" placeholder="Entrez votre nouveau mot de passe"
                            formControlName="pw" />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="form-label">Confirmez votre mot de passe <span
                              class="text-danger">*</span></label>
                          <input type="password" class="form-control" placeholder="Confirmez votre mot de passe"
                            formControlName="check" />
                        </div>
                      </div>
                    </div>
                    @if(pwForm.errors != null){
                    {{ pwForm.errors | json }}
                    }

                  </div>
                  <div class="card-footer text-end">
                    <div class="d-flex justify-content-end">
                      <button *ngIf="isLoading" class="btn btn-Changer" type="submit" disabled>
                        <span class="spinner-grow spinner-grow-sm" role="status"></span>
                        Traitement...
                      </button>

                      <!-- Bouton "Enregistrer" -->
                      <button type="submit" class="btn btn-danger" [disabled]="pwForm.invalid" *ngIf="!isLoading">
                        Changer
                      </button>
                    </div>

                  </div>
                </div>
              </form>
            </ng-template>
          </li>


        </ul>
      </div>
      <div class="card-body">
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
  <!-- [ sample-page ] end -->
</div>
