<div class="auth-main">
  <div class="auth-wrapper v3">
    <div class="auth-form">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="d-flex justify-content-center">
              <div class="text-center">
                <h2 class="text-secondary mt-5"><b>Réinitialiser votre mot de passe</b></h2>
                <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
                  <p>{{successMessage}}</p>
                </div>
                <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
                  <p>{{errorMessage}}</p>
                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
            <div class="form-floating mt-2">
              <input type="password" class="form-control" id="password" formControlName="password" />
              <label for="password">Mot de passe</label>
              <div *ngIf="forgotForm.get('password')?.invalid && forgotForm.get('password')?.touched"
                class="invalid-feedback">
                Le mot de passe est requis
              </div>
            </div>
            <div class="form-floating mt-3">
              <input type="password" class="form-control" id="floatingInput" formControlName="confirmPassword" />
              <label for="floatingInput">Confirmer votre mot de passe</label>
              <div *ngIf="forgotForm.get('confirmPassword')?.invalid && forgotForm.get('confirmPassword')?.touched"
                class="invalid-feedback">
                Le mot de passe est requis
              </div>
            </div>
            <div class="d-grid mt-4">

              <button *ngIf="isLoading" class="btn btn-secondary" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status"></span>
                Traitement...
              </button>
              <button *ngIf="!isLoading" class="btn btn-secondary "
                [disabled]="!forgotForm.valid">Réinitialiser</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>