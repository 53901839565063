// angular import
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// bootstrap import
import { SharedModule } from 'src/app/theme/shared/shared.module';

// third party
import { Router, RouterModule } from '@angular/router';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { environment } from 'src/environments/environment';
import { Profil } from '../../auth/reponses/login.response';
import { ProfilReponse } from '../reponses/profil.reponse';

@Component({
  selector: 'app-liste-profil',
  standalone: true,
  imports: [CommonModule, DataTablesModule, SharedModule, RouterModule],
  templateUrl: './liste-profil.component.html',
  styleUrl: './liste-profil.component.scss'
})
export class ListeProfilComponent implements OnInit {

  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;
  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/profils`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        dataSrc: function (json: ProfilReponse[]) {
          // Filter out items where libelle is 'MASTER'
          return json.filter(item => item.libelle !== 'MASTER');
        }

      },
      columns: [
        { title: 'Libellé', data: 'libelle' },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: null,
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => `
        <button class="btn btn-link-primary btn-view"
                title="view"
                id="view-${row.idProfil}"
                data-info='${JSON.stringify({ idProfil: row.idProfil }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-eye"></i>
        </button>
           <button class="btn btn-link-primary btn-delete"
                title="delete"
                id="delete-${row.idProfil}"
                data-info='${JSON.stringify({ idProfil: row.idProfil }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-trash"></i>
        </button>
</button>
           <button class="btn btn-link-primary btn-edit"
                title="edit"
                id="edit-${row.idProfil}"
                data-info='${JSON.stringify({ idProfil: row.idProfil }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-pencil"></i>
        </button>
    `

        }
      ],
      responsive: true,
      select: false,

    };


  }


  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then(() => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#profilTable tbody').on('click', '.btn-edit', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');

      });

    });
  }

}
