// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party
import { matches } from 'lodash';
import { ChauffeurModele } from '../modeles/chauffeur.modele';
import { ChauffeurReponse } from '../reponse/chauffeur.reponse';
import { ChauffeurService } from '../service/chauffeur.service';
import { Router } from '@angular/router';
import { TableChauffeurComponent } from "../table-chauffeur/table-chauffeur.component";

@Component({
  selector: 'app-creation-chauf',
  standalone: true,
  imports: [CommonModule, SharedModule, TableChauffeurComponent],
  templateUrl: './creation-chauf.component.html',
  styleUrl: './creation-chauf.component.scss'
})
export class CreationChaufComponent implements OnInit {
  clientForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  showForm: boolean = false;
  buttonName: string = '+';

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private chauffeurService: ChauffeurService
  ) {
    this.clientForm = this.fb.group({
      nom: ['', [Validators.required]],
      numeroPieceIdentite: [''],
      numeroPermis: ['', [Validators.required]],
      prenoms: ['', [Validators.required]],
      email: [[''], [Validators.required]],
      adresse: [[''], [Validators.required]],
      telephone: [[''], [Validators.required]],
      idChauffeur: [''],
    });
  }
  onReset() {
    this.clientForm.reset();
  }
  handlePlusClick() {
    this.showForm = !this.showForm;
    this.buttonName = this.showForm ? '-' : '+';
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    if (this.clientForm.get('idChauffeur')?.value == "") {
      this.hasError = false;
      this.hasSuccess = true;
      if (this.clientForm.valid) {
        this.isLoading = true;
        const payload: ChauffeurModele = this.clientForm.value;
        this.chauffeurService.create(payload).subscribe(
          (response: ChauffeurReponse) => {
            this.isLoading = false;
            this.hasSuccess = true;
            this.hasError = true;
            this.successMessage = 'Chauffeur ajouté avec succès.';
            this.clientForm.reset();
          },
          (error: any) => {
            this.hasError = true;
            this.isLoading = false;
            this.isLoading = false;
            this.errorMessage = error.error.message;
            console.error('Erreur lors de la création du chauffeur', error);
          }
        );
      } else {
        console.log('Le formulaire est invalide');
      }
    } else {
      this.onSubmitEdit();
    }
  }

  onSubmitEdit() {
    this.hasError = false;
    this.hasSuccess = true;
    if (this.clientForm.valid) {
      this.isLoading = true;
      const payload: ChauffeurModele = this.clientForm.value;
      this.chauffeurService.update(payload, this.clientForm.get('idChauffeur')?.value).subscribe(
        (response: ChauffeurReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.hasError = true;
          this.successMessage = 'Chauffeur modifiée avec succès.';
          this.clientForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la modification du chauffeur', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
