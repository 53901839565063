import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VenteVehiculeReponse } from '../../vente-vehicule/reponse/vente.vehicule.reponse';
import { VenteVehiculeService } from '../../vente-vehicule/service/vente.vehicule.service';
import { NumberToLetter } from 'src/app/helpers/nombre_en_lettre';
import { FormatNumberPipe } from 'src/app/helpers/format-number';
import { Config } from 'src/app/helpers/config';
import { RoundPipe } from 'src/app/helpers/rounded_number';
import Swal from 'sweetalert2';
import { alertConfirm } from 'src/app/helpers/alert-confirm';

@Component({
  selector: 'app-location-vehicule-detail',
  standalone: true,
  imports: [CommonModule, FormatNumberPipe, RoundPipe, SharedModule],
  templateUrl: './location-vehicule-detail.component.html',
  styleUrl: './location-vehicule-detail.component.scss',
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class LocationVehiculeDetailComponent implements OnInit {
  id: string | null = null;
  vehicule$!: Observable<VenteVehiculeReponse | null>;
  totalPrice: number = 0;
  totalHTTC: number = 0;
  isVisible = false;
  anableUpdate: boolean = false;
  anableDelete: boolean = false;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  emailEntreprise = "commercial@ivoireautoservices.net"
  totalPriceToLetter: string = "";

  constructor(public router: Router, private route: ActivatedRoute, private venteVehiculeService: VenteVehiculeService) { }



  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.findDetail();
    this.vehicule$.subscribe(vehicule => {
      this.anableUpdate = !vehicule?.etat.includes('UPDATE');
      this.anableDelete = !vehicule?.etat.includes('DELET');
      vehicule?.detailCommandes.forEach(detail => {
        this.totalPrice += Config.calculateTotalPrice(detail.prixUnitaire!, Config.calculateDaysBetweenDates(detail.dateDebut, detail.dateFin)) - Config.calculateTotalPrice(detail.prixUnitaire!, Config.calculateDaysBetweenDates(detail.dateDebut, detail.dateFin)) * (detail.remise! / 100);
        this.totalPrice += detail.montantChauffeur!;
      })
      this.totalHTTC = this.totalPrice + (this.totalPrice * vehicule?.tva! / 100)
      this.convertNumberToLetter(this.totalHTTC);

    })
  }

  calculateDaysBetweenDates(startDate: Date, endDate: Date): number {
    return Config.calculateDaysBetweenDates(startDate, endDate);
  }
  convertNumberToLetter(nombre: number) {
    this.totalPriceToLetter = NumberToLetter(nombre);
  }

  findDetail() {
    this.vehicule$ = this.venteVehiculeService.getById(this.id);
  }



  printDiv(divId: string): void {

    const elementsToHide = document.querySelectorAll('body > :not(#' + divId + ')');
    elementsToHide.forEach(element => (element as HTMLElement).style.display = 'none');

    const style = document.createElement('style');
    style.innerHTML = '@media print { @page { size: portrait; } }';
    document.head.appendChild(style);

    const printContent = document.getElementById(divId)?.cloneNode(true);
    const temporaryContainer = document.createElement('div');
    if (printContent) {
      temporaryContainer.appendChild(printContent);
      document.body.appendChild(temporaryContainer);
      window.print();
      temporaryContainer.remove();
      style.remove();
    }

    elementsToHide.forEach(element => (element as HTMLElement).style.display = '');
  }

  confirm() {
    alertConfirm('Voulez-vous modifier la reservation ?', () => {
      this.updateForModification();
    });

  }
  confirmSuppression() {
    alertConfirm('Voulez-vous supprimer la reservation ?', () => {
      this.updateForSuppression();
    });
  }

  updateForModification() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;

    this.venteVehiculeService.updateForModification(this.id, "L_VEHICULE").subscribe(
      (response: VenteVehiculeReponse) => {
        this.isLoading = false;
        this.hasError = false;
        this.hasSuccess = true;
        this.successMessage = 'Location mise en modification.';
        this.findDetail();
      },
      (error: any) => {
        this.hasError = true;
        this.isLoading = false;
        this.hasSuccess = false;
        this.errorMessage = error.error.message;
        console.error('Erreur lors de la mise en modification de la Location', error);
      }
    );

  }


  updateForSuppression() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;

    this.venteVehiculeService.updateForSuppression(this.id).subscribe(
      (response: VenteVehiculeReponse) => {
        this.isLoading = false;
        this.hasError = false;
        this.hasSuccess = true;
        this.successMessage = 'Location mise en suppression.';
        this.findDetail();
      },
      (error: any) => {
        this.hasError = true;
        this.isLoading = false;
        this.hasSuccess = false;
        this.errorMessage = error.error.message;
        console.error('Erreur lors de la mise en suppression de la Location', error);
      }
    );

  }
}

