import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListeProfilComponent } from './liste-profil/liste-profil.component';
import { CreationProfilComponent } from './creation-profil/creation-profil.component';
import { DetailProfilComponent } from './detail-profil/detail-profil.component';
import { UpdateProfilComponent } from './update-profil/update-profil.component';

const routes: Routes = [
  {
    path: 'liste-profil',
    component: ListeProfilComponent
  },
  {
    path: 'creation-profil',
    component: CreationProfilComponent
  },
  {
    path: 'detail-profil/:id',
    component: DetailProfilComponent
  },
  {
    path: 'update-profil/:id',
    component: UpdateProfilComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionProfilRoutingModule { }
