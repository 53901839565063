<div class="auth-main">
  <div class="auth-wrapper v3">
    <div class="auth-form">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="d-flex justify-content-center">
              <div class="auth-header text-center">
                <h2 class="text-secondary mt-5"><b>Mot de passe oublié ?</b></h2>
                <p class="f-16 mt-3"> Saisissez votre adresse électronique ci-dessous et nous vous enverrons l'OTP de
                  réinitialisation du mot de passe. </p>
              </div>
            </div>
          </div>
          <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
            <p>{{successMessage}}</p>
          </div>
          <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
            <p>{{errorMessage}}</p>
          </div>
          <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
            <div class="form-floating mb-2">
              <input type="email" class="form-control" id="floatingInput" formControlName="email" />
              <label for="floatingInput">Adresse Email</label>
              <div *ngIf="forgotForm.get('email')?.invalid && forgotForm.get('email')?.touched"
                class="invalid-feedback">
                L'email est requis
              </div>
            </div>
            <div class="d-grid mt-4">

              <button *ngIf="isLoading" class="btn btn-secondary" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status"></span>
                Traitement...
              </button>
              <button *ngIf="!isLoading" class="btn btn-secondary " [disabled]="!forgotForm.valid">Envoyer un
                mail</button>
            </div>
          </form>
          <hr />
          <h5 class="d-flex justify-content-center">
            <a [routerLink]="['/auth/login']"> Se connecter </a>
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>