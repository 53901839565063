<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Stock en cours">
      <form [formGroup]="stockForm">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="idCategorie">Catégorie<span class="text-danger">*</span></label>
                <select type="text" formControlName="idCategorie" class="form-control"
                  [ngClass]="{'is-invalid':stockForm.get('idCategorie')?.touched                                             }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let categorie of categories$ | async" [value]="categorie.idCategorie">
                    {{ categorie.libelle }}
                  </option>
                </select>
                <div *ngIf="stockForm.get('idCategorie')?.errors?.['required'] && stockForm.get('idCategorie')?.touched"
                  class="invalid-feedback">
                  La Catégorie est requise.
                </div>

              </div>

            </div>
            <div class="col-md-6">

            </div>
          </div>
        </div>

      </form>
      <div class="mb-5"></div>
      <div class="table-responsive">
        <table datatable [dtOptions]="dtRouterLinkOptions" id="stockTable" class="table  row-border table-hover">
        </table>
      </div>

    </app-card>
  </div>
</div>