// Angular Import
import { Component, Injectable, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfilService } from '../services/profil.service';
import { MenuReponse, ProfilReponse, SousMenuReponse } from '../reponses/profil.reponse';
import { ProfilMenuModele, ProfilModele } from '../modeles/profil.modele';

export class TodoItemNode {
  children!: TodoItemNode[];
  item!: string;
}
interface PermissionNode {
  Permissions: string[];
}

interface SelectedItems {
  item: string;
  permissions: string[]
};

interface TreeData {
  [key: string]: PermissionNode | TreeData;
}
/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item!: string;
  level!: number;
  expandable!: boolean;
}
let menus: MenuReponse[] = [];
let TREE_DATA: TreeData = {};
const permissionMap: { [key: number]: string } = {
  1: 'Création',
  2: 'Visualisation',
  3: 'Modification',
  4: 'Suppression',
};

const reversePermissionMap: { [key: string]: number } = Object.fromEntries(
  Object.entries(permissionMap).map(([key, value]) => [value, Number(key)])
);

function mapPermissions(
  items: (any)[],
  toString: boolean = true
): (any)[] {
  if (toString) {
    // Convert numbers to permission strings
    return items.map(item => permissionMap[item] || 'Unknown');
  } else {
    // Convert permission strings to numbers
    return items.map(item => reversePermissionMap[item as string] || 0);
  }
}

@Injectable()
export class ChecklistDatabase {
  // private Props
  dataChange = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  // Constructor
  constructor(private profilService: ProfilService) {
    this.initialize();
  }

  initialize() {
    this.getAllMenu();
    console.log('TREE_DATA', TREE_DATA);



  }
  getAllMenu() {
    this.profilService.getAllMenu().subscribe(
      response => {
        menus = response;
        // Dynamically build TREE_DATA from the response
        response.forEach((menu: MenuReponse) => {
          const menuName = menu.libelle as string; // Ensure menuName is a string

          // Initialize the menu entry in TREE_DATA
          TREE_DATA[menuName] = {};

          // If there are permissions for the main menu, add them
          if (!menu.sousMenus || menu.sousMenus?.length == 0) {
            (TREE_DATA[menuName] as unknown as PermissionNode) = {
              Permissions: mapPermissions(menu.permission.split(','))
            };
          }



          // Process sousMenus if they exist
          if (menu.sousMenus && Array.isArray(menu.sousMenus)) {
            menu.sousMenus.forEach((sousMenu: SousMenuReponse) => {
              const sousMenuName = sousMenu.libelle as string; // Ensure sousMenuName is a string

              // Initialize the submenu entry in TREE_DATA[menuName]
              if (!(TREE_DATA[menuName] as TreeData)[sousMenuName]) {
                (TREE_DATA[menuName] as TreeData)[sousMenuName] = {};
              }

              // If there are permissions for the submenu, add them

              (TREE_DATA[menuName] as TreeData)[sousMenuName] = {
                Permissions: mapPermissions(sousMenu.permission.split(','))
              };

            });
          }
        });

        //     file node as children.
        const data = this.buildFileTree(TREE_DATA, 0);

        // Notify the change.
        this.dataChange.next(data);
        console.log('Dynamically built TREE_DATA:', TREE_DATA);

      },
      error => {
        console.error('Error fetching menus:', error);
      }
    );
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  // eslint-disable-next-line
  buildFileTree(obj: { [key: string]: any }, level: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TodoItemNode();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1);
        } else {
          node.item = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  /** Add an item to to-do list */
  insertItem(parent: TodoItemNode, name: string) {
    if (parent.children) {
      parent.children.push({ item: name } as TodoItemNode);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: TodoItemNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-tree-view',
  templateUrl: './creation-profil.component.html',
  styleUrls: ['./creation-profil.component.scss'],
  providers: [ChecklistDatabase]
})
export class CreationProfilComponent implements OnInit {
  profilForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  // private props
  flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
  nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
  selectedParent: TodoItemFlatNode | null = null;
  newItemName = '';
  treeControl: FlatTreeControl<TodoItemFlatNode>;
  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);


  // Constructor
  constructor(private _database: ChecklistDatabase, private fb: FormBuilder, private profilService: ProfilService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
    this.profilForm = this.fb.group({
      libelle: ['', [Validators.required, Validators.minLength(1)]],
    });
  }
  ngOnInit(): void {


  }
  // private method
  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item ? existingNode : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: TodoItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    if (parentNode) {
      this._database.insertItem(parentNode, '');
    }
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    if (nestedNode) {
      this._database.updateItem(nestedNode, itemValue);
    }
  }


  onSubmit() {
    const selectedNodes = this.checklistSelection.selected;
    const selectedPermissions: TreeData = selectedNodes.reduce((acc: TreeData, node: TodoItemFlatNode) => {
      const parentNode = this.getParentNode(node);

      if (parentNode) {
        if (!acc[parentNode.item]) {
          acc[parentNode.item] = {};
        }

        if (TREE_DATA[parentNode.item] && (TREE_DATA[parentNode.item] as TreeData)[node.item]) {
          (acc[parentNode.item] as TreeData)[node.item] = (TREE_DATA[parentNode.item] as TreeData)[node.item];
        }
      } else {
        if (TREE_DATA[node.item]) {
          acc[node.item] = TREE_DATA[node.item];
        }
      }

      return acc;
    }, {});
    const profilMenu: ProfilMenuModele[] = [];
    menus.forEach((menu: MenuReponse) => {
      this.transformData(selectedPermissions).forEach((selected: SelectedItems) => {
        if (!menu.sousMenus || menu.sousMenus?.length == 0) {
          if (menu.libelle === selected.item) {
            profilMenu.push({ idSousMenu: null, idMenu: menu.idMenu, permission: mapPermissions(selected.permissions, false).join(',') })
          }
        } else {
          menu.sousMenus.forEach((sousMenu: SousMenuReponse) => {
            if (sousMenu.libelle === selected.item) {
              profilMenu.push({ idSousMenu: sousMenu.idSousMenu, idMenu: menu.idMenu, permission: mapPermissions(selected.permissions, false).join(',') })
            }
          })
        }
      })

    })
    if (this.profilForm.valid) {
      this.isLoading = true;
      const payload: ProfilModele = this.profilForm.value;
      payload.profilMenus = profilMenu;
      this.profilService.create(payload).subscribe(
        (response: ProfilReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Profil ajouté avec succès.';
          this.profilForm.reset();
        },
        (error: any) => {
          console.error('Erreur lors de la création du profil', error);
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;

        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }
  transformData(obj: any): any[] {
    const result: any[] = [];

    function traverse(currentObj: any) {
      for (const key in currentObj) {
        if (currentObj.hasOwnProperty(key)) {
          const item = key;
          const permissions = currentObj[key]?.Permissions || [];

          if (permissions.length > 0) {  // Only add items with permissions
            result.push({ item, permissions });
          }

          if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
            traverse(currentObj[key]);
          }
        }
      }
    }

    traverse(obj);
    return result;
  }


}
