<!DOCTYPE html>
<html lang="fr">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <title>Facture Proforma</title>
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">
  <style>
    @page {
      size: A4;
      margin: 0;
    }



    @media print {

      /* Appliquer la taille A4 pour l'impression */
      .page {
        width: 21cm;
        height: 29.7cm;
        margin: 0;
        padding: 0;
        position: relative;
      }

      content {
        padding-bottom: 100px;
        /* Crée de l'espace pour le pied de page */
      }

      .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 10px;
        background-color: #fff;
        /* Optionnel, couleur de fond du pied de page */
      }
    }

    @media screen {

      /* Optionnel : Définir la taille A4 pour l'affichage à l'écran */
      .page {
        width: 21cm;
        height: 29.7cm;
        margin: 0 auto;
        /* Centrer sur la page */
        padding: 1cm;
        /* Ajouter un peu de marge intérieure */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        /* Ombre pour un effet visuel */
        background: #fff;
        /* Fond blanc */
      }

      .footer {
        position: relative;
        padding: 10px;
        text-align: center;
      }
    }
  </style>
</head>

<body class="bg-light">
  <div class="container my-5">
    <div class="card">
      <div class="card-body">

        <div class="">
          <button class="btn btn-primary btn-sm" (click)="printDiv('invoice')">Imprimer</button>
        </div>

        <div class="" id="invoice">
          <div class="page">
            <div class="content">
              <div class="px-5 pt-4">
                <div class="text-start"><img src="/assets/images/logo.png" alt="Logo" width="150px"></div>
                <hr class="mt-4">
                <div class="d-flex justify-content-between" style="margin-top: 10px; margin-bottom: 10px;">
                  <div style="width: 40%;"><span class="fs-4 text-uppercase fw-bold">PRO FORMA</span></div>
                  <div style="width: 20%; font-size: small;"><span>Client :</span><br><span class="fw-bold">M.
                      SYLLA</span></div>
                  <div style="width: 20%; font-size: small;"><span>N° C.C. :</span><br><span class="fw-bold"></span>
                  </div>
                  <div style="width: 20%; font-size: small;"><span>Téléphone :</span><br><span
                      class="fw-bold">000</span></div>
                </div>
                <hr class="">
                <div class="row py-3">
                  <div class="offset-5 col-7" style="font-size: small;">
                    <div class="row">
                      <div class="col-4">
                        <span class="fw-bold">N° Pro forma</span>
                        <hr class="p-0" style="margin-top: 4px; margin-bottom: 4px;">
                        <span>DA/01/79/0001121<!-- 1 --></span>
                      </div>
                      <div class="col-4">
                        <span class="fw-bold">Date</span>
                        <hr class="p-0" style="margin-top: 4px; margin-bottom: 4px;">
                        <span>11/09/2024</span>
                      </div>
                      <div class="col-4">
                        <span class="fw-bold">Emetteur</span>
                        <hr class="p-0" style="margin-top: 4px; margin-bottom: 4px;">
                        <span>KPI Marie Ange</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-5" style="font-size: small;"><span class="fw-bold">Objet :</span> LOCATION DE VEHICULE
                </div>
                <div class="">
                  <table class="table mt-4 table-responsive" style="font-size: small;">
                    <thead style="border-bottom: 2px solid #3f3d8d;">
                      <tr>
                        <th>Référence</th>
                        <th style="width: 50%;">Désignation</th>
                        <th>P.U HT</th>
                        <th>Qté</th>
                        <th>Période</th>
                        <th>Rem.</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#</td>
                        <td style="width: 50%; white-space: normal; overflow-wrap: break-word;">Location d'un
                          véhicule
                          type BERLINE sans chauffeur du 03/09/2024 au
                          10/09/2024 soit 08 jours
                        </td>
                        <td>60 000</td>
                        <td>1</td>
                        <td>8 Jours</td>
                        <td>0%</td>
                        <td>480 000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="offset-7 col-5 text-end" style="font-size: small;">
                    <div class="d-flex">
                      <div class="" style="width: 30%;">Montant HT</div>
                      <div class="pe-2" style="width: 70%;">480 000</div>
                    </div>
                    <hr class="m-0 p-0">
                    <div class="d-flex">
                      <div class="" style="width: 30%;">TVA 18%</div>
                      <div class="pe-2" style="width: 70%;">86 400</div>
                    </div>
                    <hr class="m-0 p-0" style="border: 1px solid #3f3d8d;">
                    <div class="d-flex">
                      <div class="" style="width: 35%; color:  #3f3d8d;">Montant TTC</div>
                      <div class="pe-2" style="width: 70%; color:  #3f3d8d;">566 400</div>
                    </div>
                  </div>
                </div>
                <div class="fw-bold my-4" style="font-size: small;">Arrêté la présente facture pro forma à la somme de
                  cinq cent soixante six mille quatre cent francs CFA.</div>
                <div class="" style="font-size: small;">
                  <span class="fw-bold">CONDITIONS</span>
                  <hr class="m-0 p-0">
                  <table>
                    <tbody>
                      <tr>
                        <td><span>Conditions de paiement :</span></td>
                        <td><span class="fw-bold">ESPECES/CHEQUE</span></td>
                      </tr>
                      <tr>
                        <td><span>Delai de livraison :</span></td>
                        <td><span class="fw-bold">24 H</span></td>
                      </tr>
                      <tr>
                        <td><span>Validité de l'offre :</span></td>
                        <td><span class="fw-bold">1 mois</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="footer">
              <div class="px-5 text-center" style="font-size: x-small;">
                <hr>
                <span>Situé à Angré 8ème tranche non loin du carrefour de la prière.</span><br>
                <span>Tel : +225 : 07 07 93 97 12 / 07 07 94 08 08 /05 06 72 68 83 13 BP 1715 Abidjan 13</span><br>
                <span>N°CC : 1912797 L Réel Normal d’Imposition Centre des Impôts de la Djibi N° RC : CI-ABJ-2019-B-
                  02715 NSIA N° 035361963575</span><br>
                <span>IBAN : CI93 CI04 2012 - Compte Bancaire BIAO N° 03536196357524 - SWIFT Code :
                  BIAOCIABXXX</span><br>
                <span>Email :</span><br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>





  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script>



</body>

</html>