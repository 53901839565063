import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { VehiculeService } from '../services/vehicule.service';
import { MarqueReponse } from '../../marque/reponses/marque.reponse';
import { TypeReponse } from '../../type/reponses/type.reponse';
import { FournisseurReponse } from 'src/app/Pages/gestion-partenaire/fournisseur/reponse/fournisseur.reponse';
import { MarqueService } from '../../marque/services/marque.service';
import { TypeService } from '../../type/services/type.service';
import { FournisseurService } from 'src/app/Pages/gestion-partenaire/fournisseur/service/fournisseur.service';
import { ModelReponse } from '../../models/reponses/model.reponse';
import { ModelService } from '../../models/services/model.service';
import { VehiculeModele } from '../modeles/vehicule.modele';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { VehiculeReponse } from '../reponses/vehicule.reponse';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-modification-v',
  standalone: true,
  imports: [CommonModule, NgSelectModule, SharedModule],
  templateUrl: './modification-v.component.html',
  styleUrl: './modification-v.component.scss',
})
export class ModificationVComponent {
  id: string | null = null
  vehicule$!: Observable<VehiculeReponse | null>;

  vehiculeForm: FormGroup;
  formStep = 0;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  marques: MarqueReponse[] = [];
  types: TypeReponse[] = [];
  categories = [
    {
      libelle: "VENTE",
      id: "V_VEHICULE"
    },
    {
      libelle: "LOCATION",
      id: "L_VEHICULE"
    },
  ];
  usages = ['PUBLIQUE', 'PRIVEE'];
  fournisseurs: FournisseurReponse[] = [];
  modeles: ModelReponse[] = [];

  constructor(
    private fb: FormBuilder,
    private vehiculeService: VehiculeService,
    private marqueService: MarqueService,
    private typeService: TypeService,
    private route: ActivatedRoute,
    private fournisseurService: FournisseurService,
    private modelService: ModelService

  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.findVehiculeById();
    this.vehiculeForm = this.fb.group({
      // Etape 1
      idMarque: ['', Validators.required],
      idModele: ['', Validators.required],
      idType: ['', Validators.required],
      immatriculationProvisoire: ['', Validators.required],
      immatriculationDefinitive: [''],
      categorie: ['', Validators.required],
      idFournisseur: ['', Validators.required],
      nombrePlace: [''],

      // Etape 2
      anneeFabrication: [
        '',
        [Validators.required, Validators.min(1900), Validators.max(2100)],
      ],
      kilometrage: ['', Validators.required],
      couleur: [''],
      typeCarburant: ['', Validators.required],

      // Etape 3
      numeroCarteGrise: [''],
      chassis: ['', Validators.required],
      dateMiseCirculation: ['', Validators.required],
      dateCarteGrise: [''],
      puissanceFiscale: [''],
      usage: ['', Validators.required],
      mode: ['', Validators.required],

      // Etape 4

      montantTva: ['', Validators.required],
      dateAchat: ['', Validators.required],
      prixAchat: ['', Validators.required],
      prixVente: [''],
      prixLocation: [''],
    });
    this.vehiculeForm.get('idMarque')?.valueChanges.subscribe(value => {
      this.onMarqueChange(value);
    });

    this.subscribeToVehiculeChanges();
  }

  subscribeToVehiculeChanges(): void {
    this.vehicule$.subscribe({
      next: (vehicule) => this.updateVehiculeForm(vehicule),
      error: () => this.vehiculeForm.disable(),
    });
  }
  customSearch(term: string, item: any): boolean {
    if (!term) return true;

    term = term.toLowerCase();

    const searchableFields = ['libelle', 'nom', 'telephone', 'prenoms', 'immatriculationProvisoire', 'valeur']; // Liste des champs à rechercher

    return searchableFields.some(field =>
      item[field] && item[field].toLowerCase().includes(term)
    );
  }

  updateVehiculeForm(vehicule: any): void {
    if (vehicule) {
      this.vehiculeForm.patchValue({
        idMarque: vehicule.marque?.idMarque,
        idModele: vehicule.modele?.idModele,
        idType: vehicule.type?.idType,
        immatriculationProvisoire: vehicule.immatriculationProvisoire,
        immatriculationDefinitive: vehicule.immatriculationDefinitive,
        categorie: vehicule?.categorie,
        idFournisseur: vehicule?.fournisseur?.idFournisseur,
        nombrePlace: vehicule?.carteGrise?.nombrePlace,
        anneeFabrication: vehicule?.carteGrise?.annee,
        kilometrage: vehicule?.carteGrise?.kilometrage,
        couleur: vehicule?.carteGrise?.couleur,
        typeCarburant: vehicule?.carteGrise?.typeCarburant,
        numeroCarteGrise: vehicule?.carteGrise?.numeroCarteGrise,
        chassis: vehicule?.carteGrise?.chassis,
        dateMiseCirculation: vehicule?.carteGrise?.dateMiseEnCirculation,
        dateCarteGrise: vehicule?.carteGrise?.dateCarteGrise,
        puissanceFiscale: vehicule?.carteGrise?.puissanceFiscale,
        usage: vehicule?.carteGrise?.usage,
        mode: vehicule?.carteGrise?.mode,
        montantTva: vehicule?.tarifVehicule?.montantTva,
        dateAchat: vehicule?.dateAchat,
        prixAchat: vehicule?.tarifVehicule?.prixAchat,
        prixVente: vehicule?.tarifVehicule?.prixVente,
        prixLocation: vehicule?.tarifVehicule?.prixLocation,
        fraisDouane: vehicule?.tarifVehicule?.fraisDouane,
        fraisCarteGrise: vehicule?.tarifVehicule?.fraisCarteGrise,
        autreFrais: vehicule?.tarifVehicule?.autreFrais,
      });
    } else {
      this.vehiculeForm.disable();
    }
  }
  onMarqueChange(selectedId: number): void {
    this.marques.forEach((marque) => {
      if (marque.idMarque === selectedId) {
        this.modeles = marque.modeles;
      }
    })
  }
  ngOnInit(): void {
    this.marqueService.getAll().subscribe(
      response => {
        this.marques = response;
      }
    );

    this.fournisseurService.getAll().subscribe(
      response => {
        this.fournisseurs = response;
      }
    );

    this.typeService.getAll().subscribe(
      response => {
        this.types = response;
      }
    );
  }

  // Naviguer entre les étapes
  nextStep() {
    if (this.formStep < 3) {
      this.formStep++;
    }
  }

  previousStep() {
    if (this.formStep > 0) {
      this.formStep--;
    }
  }

  // Vérifie si l'étape actuelle est valide
  isStepValid(): boolean {
    const stepFields = [
      ['idMarque', 'idModele', 'idType', 'categorie', 'idFournisseur'], // Étape 0
      [
        'anneeFabrication',
        'kilometrage',
        'couleur',
        'nombrePorte',
        'carburant',
      ], // Étape 1
      [
        'numeroCarteGrise',
        'chassis',
        'cylindree',
        'nombreEssieux',
        'dateCirculation',
      ], // Étape 2
      [
        'fraisDouane',
        'montantTva',
        'fraisCarteGrise',
        'dateAchat',
        'prixAchat',
      ], // Étape 3
    ];

    return stepFields[this.formStep].every(
      (field) => this.vehiculeForm.get(field)?.valid
    );
  }

  // Soumettre le formulaire une fois que tout est rempli
  onSubmit() {
    this.isLoading = true
    if (this.vehiculeForm.valid) {
      const payload = this.vehiculeForm.value;
      const vehiculePayload: VehiculeModele = {
        idMarque: payload.idMarque,
        idModele: payload.idModele,
        idType: payload.idType,
        categorie: payload.categorie,
        immatriculationProvisoire: payload.immatriculationProvisoire,
        immatriculationDefinitive: payload.immatriculationDefinitive,
        dateAchat: payload.dateAchat,
        idFournisseur: payload.idFournisseur,
        tarifVehicule: {
          prixAchat: payload.prixAchat,
          prixVente: payload.prixVente,
          prixLocation: payload.prixLocation,
          fraisDouane: payload.fraisDouane,
          montantTva: payload.montantTva,
          fraisCarteGrise: payload.fraisCarteGrise,
          autreFrais: payload.autreFrais,
        },

        carteGrise: {
          annee: payload.anneeFabrication,
          kilometrage: payload.kilometrage,
          couleur: payload.couleur,
          nombrePortes: payload.nombrePorte,
          nombrePlace: payload.nombrePlace,
          boiteVitesse: payload.boiteVitesse,
          typeCarburant: payload.typeCarburant,
          carrosserie: payload.carrosserie,
          numeroCarteGrise: payload.numeroCarteGrise,
          dateCarteGrise: payload.dateCirculation,
          cylindree: payload.cylindree,
          puissanceFiscale: payload.puissanceFiscale,
          nombreEssieux: payload.nombreEssieux,
          dateMiseEnCirculation: payload.dateCirculation,
          mode: payload.mode,
          usage: payload.usage,
          chassis: payload.chassis,
        }
      };
      this.vehiculeService.update(vehiculePayload, this.id).subscribe(
        (response) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Véhicule modifié avec succès.';
          this.vehiculeForm.reset();
          this.subscribeToVehiculeChanges();
        },
        (error) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error("Erreur lors de la modification du véhicule", error);
        }
      );
    }
  }

  findVehiculeById() {
    this.vehicule$ = this.vehiculeService.getByIdForModification(this.id);
  }
}
