export class DetailCommande {
  constructor(
    public idDetailCommande: any,
    public prixUnitaire: any,
    public quantite: any,
    public idVehicule: any,
    public dateDebut: any = null,
    public dateFin: any = null,
    public idChauffeur: any = null,
    public montantChauffeur: any = null,
    public remise: any = null,
    public villeArrivee: any = null,
    public villeDepart: any = null,
    public driver: any = null,
    public idProduit: any = null
  ) { }
}

export class VenteVehiculeModele {
  constructor(
    public montant: any,
    public type: any,
    public reste: any,
    public idClient: any,
    public tva: any,
    public detailCommandes: DetailCommande[],
    descriptionMotif?: any,
    motifSuppression?: any
  ) { }
}
