import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { EMPTY, Observable } from 'rxjs';
import { VehiculeReponse } from '../../gestion-vehicule/vehicule/reponses/vehicule.reponse';
import { CompagnieReponse } from '../../gestion-compagnie-assurance/reponse/compagnie.reponse';
import { VehiculeService } from '../../gestion-vehicule/vehicule/services/vehicule.service';
import { CompagnieService } from '../../gestion-compagnie-assurance/service/compagnie.service';
import { VisiteService } from '../service/visite.service';
import { VisiteModele } from '../modeles/visite.modele';
import { VisiteReponse } from '../reponse/visite.reponse';
// third party

@Component({
  selector: 'app-creation-visite',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './creation-visite.component.html',
  styleUrl: './creation-visite.component.scss'
})
export class CreationVisiteComponent {
  visiteForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  vehicules$: Observable<VehiculeReponse[]> = EMPTY;
  compagnies$: Observable<CompagnieReponse[]> = EMPTY;
  constructor(private fb: FormBuilder,
    private vehiculeService: VehiculeService,
    private compagnieService: CompagnieService,
    private visiteService: VisiteService
  ) {
    this.visiteForm = this.fb.group({
      dateVisite: ['', [Validators.required]],
      dateFin: [''],
      observation: [''],
      idVehicule: [null, [Validators.required]],
      cout: [0],
      idCompagnie: [null, [Validators.required]],
      lien: [''],
      extension: [''],
      prochaineEcheance: ['']
    });
    this.compagnies$ = this.compagnieService.getAll("CENTRE");
    this.vehicules$ = this.vehiculeService.getAll();
  }


  fileChangeEvent(event: any): void {
    // Récupérer le fichier sélectionné
    const file = event.target.files[0];

    if (file) {
      // Extraire l'extension du fichier
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      this.visiteForm.get('extension')?.setValue(fileExtension);

      // Utiliser FileReader pour obtenir le contenu Base64
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64String = e.target.result.split(',')[1]; // Récupère le contenu Base64 sans le préfixe
        this.visiteForm.get('lien')?.setValue(base64String);
      };
      reader.readAsDataURL(file); // Lecture du fichier en tant que URL Base64
    }
  }


  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = true;
    const payload: VisiteModele = this.visiteForm.value;
    if (this.visiteForm.get('lien')?.value) {
      payload.document = {
        lien: this.visiteForm.get('lien')?.value,
        extension: this.visiteForm.get('extension')?.value
      }
    }
    this.visiteService.create(payload).subscribe(
      (response: VisiteReponse) => {
        this.isLoading = false;
        this.hasError = false;
        this.hasSuccess = true;
        this.successMessage = 'Visite ajoutée avec succès.';
        this.visiteForm.reset();
      },
      (error: any) => {
        this.hasError = true;
        this.hasSuccess = false;
        this.isLoading = false;
        this.errorMessage = error.error.message;
        console.error("Erreur lors de la création de la visite", error);
      }
    );

  }

}
