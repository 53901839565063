import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from '../models/login';
import { SessionService } from './session.service';
import { ChangePassword } from '../models/change.password';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private addressPusher: string = this.localStorageService.getData('addressPusher');
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;


  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'fr-FR',
        //'X-KEY': this.key
      },)
    };
  }
  private getHttpOptions2(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'fr-FR',
        'Authorization': 'Bearer ' + this.localStorageService.getData('token')
        //'X-KEY': this.key
      },)
    };
  }

  // Fonction de connexion
  login(payload: Login): Observable<any> {
    payload.pusher = this.addressPusher;
    return this.http.post<any>(`${this.url}/auth/admin`, payload, this.getHttpOptions());
  }
  change(payload: ChangePassword): Observable<any> {
    return this.http.put<any>(`${this.url}/password/admin/change`, payload, this.getHttpOptions2());
  }

  // Fonction de réinitialisation de mot de passe
  reset(payload: any): Observable<any> {
    return this.http.post<any>(`${this.url}/users/password/reset`, payload, this.getHttpOptions());
  }
}
