import { Component, OnInit } from '@angular/core';
import { VenteVehiculeReponse } from '../../vente-vehicule/reponse/vente.vehicule.reponse';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { VenteVehiculeService } from '../../vente-vehicule/service/vente.vehicule.service';
import { Config } from 'src/app/helpers/config';
import { NumberToLetter } from 'src/app/helpers/nombre_en_lettre';
import { FormatNumberPipe } from 'src/app/helpers/format-number';
import { CommonModule } from '@angular/common';
import { RoundPipe } from 'src/app/helpers/rounded_number';
import { SharedModule } from 'src/app/theme/shared/shared.module';

@Component({
  selector: 'app-invoice-location',
  standalone: true,
  imports: [CommonModule, FormatNumberPipe, RoundPipe, SharedModule],
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  id: string | null = null;
  vehicule$!: Observable<VenteVehiculeReponse | null>;
  totalPrice: number = 0;
  totalHTTC: number = 0;
  totalPriceChauffeur: number = 0;
  isVisible = false;
  anableUpdate: boolean = false;
  anableDelete: boolean = false;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  emailEntreprise = "commercial@ivoireautoservices.net"
  totalPriceToLetter: string = "";

  constructor(public router: Router, private route: ActivatedRoute, private venteVehiculeService: VenteVehiculeService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id);

    this.findDetail();
    this.vehicule$.subscribe(vehicule => {
      this.anableUpdate = !vehicule?.etat.includes('UPDATE');
      this.anableDelete = !vehicule?.etat.includes('DELET');
      vehicule?.detailCommandes.forEach(detail => {
        this.totalPrice += Config.calculateTotalPrice(detail.prixUnitaire!, Config.calculateDaysBetweenDates(detail.dateDebut, detail.dateFin)) - Config.calculateTotalPrice(detail.prixUnitaire!, Config.calculateDaysBetweenDates(detail.dateDebut, detail.dateFin)) * (detail.remise! / 100);
        this.totalPriceChauffeur += Config.calculateTotalPrice(detail.montantChauffeur!, Config.calculateDaysBetweenDates(detail.dateDebut, detail.dateFin));
      })
      this.totalHTTC = this.totalPrice + (this.totalPrice * vehicule?.tva! / 100)
      this.totalHTTC += this.totalPriceChauffeur
      this.convertNumberToLetter(this.totalHTTC);

    })

    this.printDiv('invoice');
  }

  calculateDaysBetweenDates(startDate: Date, endDate: Date): number {
    return Config.calculateDaysBetweenDates(startDate, endDate);
  }
  convertNumberToLetter(nombre: number) {
    this.totalPriceToLetter = NumberToLetter(nombre);
  }


  calculMontant(item: any) {
    return Config.calculateTotalPrice((parseFloat(item.prixUnitaire) || 0), Config.calculateDaysBetweenDates(item.dateDebut, item.dateFin));
  }

  calculMontantChauffeur(item: any) {
    return Config.calculateTotalPrice((parseFloat(item.montantChauffeur) || 0), Config.calculateDaysBetweenDates(item.dateDebut, item.dateFin));
  }
  nombreJour(item: any) {
    return Config.calculateDaysBetweenDates(item.dateDebut, item.dateFin)
  }

  findDetail() {
    this.vehicule$ = this.venteVehiculeService.getById(this.id);

  }
  getEtatPaiement(v: VenteVehiculeReponse) {
    let badgeClass = '';
    let data = '';
    if (v.reste == 0) {
      badgeClass = 'badge bg-success rounded f-12';
      data = 'SOLDE'
    } else if (v.reste > 0 && v.reste < v.montant) {
      badgeClass = 'badge bg-light-warning rounded f-12';
      data = 'PARTIELLEMENT SOLDE'
    } else if (v.reste == v.montant) {
      badgeClass = 'badge bg-danger rounded f-12';
      data = 'NON SOLDE'
    }
    return { badgeClass, data }
  }
  getEtat(etat: any) {
    if (etat === 'ACTIVATED') {
      return 'VALIDÉ';
    } else if (etat === 'PENDING') {
      return 'EN ATTENTE DE VERIFICATION';
    } else if (etat === 'PRE_CONFIRMED') {
      return 'EN ATTENTE DE CONFIRMATION';
    }
  }


  printDiv(divId: string): void {

    const elementsToHide = document.querySelectorAll('body > :not(#' + divId + ')');
    elementsToHide.forEach(element => (element as HTMLElement).style.display = 'none');

    const style = document.createElement('style');
    style.innerHTML = '@media print { @page { size: portrait; } }';
    document.head.appendChild(style);

    const printContent = document.getElementById(divId)?.cloneNode(true);
    const temporaryContainer = document.createElement('div');
    if (printContent) {
      temporaryContainer.appendChild(printContent);
      document.body.appendChild(temporaryContainer);
      window.print();
      temporaryContainer.remove();
      style.remove();
    }

    elementsToHide.forEach(element => (element as HTMLElement).style.display = '');
  }
}
