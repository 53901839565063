<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Création de véhicule par importation">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="importationForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="file">Fichier <span class="text-danger">*</span></label>
                    <input type="file" formControlName="file" (change)="fileChangeEvent($event)" class="form-control"
                      id="file" [ngClass]="{
                                'is-invalid':
                                  importationForm.get('file')?.invalid &&
                                  importationForm.get('file')?.touched
                              }" />
                    <div *ngIf="
                                importationForm.get('file')?.invalid &&
                                importationForm.get('file')?.touched
                              " class="invalid-feedback">
                      <div *ngIf="importationForm.get('file')?.errors?.['required']">
                        Le fichier est requis.
                      </div>
                    </div>
                  </div>

                </div>
                <div class="col-md-6">
                  <label for="datePaiement"><span class="text-danger"></span></label>
                  <div class="d-flex justify-content-end">
                    <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
                      <span class="spinner-grow spinner-grow-sm" role="status"></span>
                      Traitement...
                    </button>

                    <!-- Bouton "Enregistrer" -->
                    <button type="submit" class="btn btn-primary" [disabled]="importationForm.invalid"
                      *ngIf="!isLoading">
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-historique></app-table-historique>
    </app-card>
  </div>
</div>