import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationArticleComponent } from './creation-article/creation-article.component';

const routes: Routes = [

  {
    path: 'ajout-article',
    component: CreationArticleComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionArticleRoutingModule { }
