import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationVenteVehiculeComponent } from './vente-vehicule/creation/creation.vente.vehicule.component';
import { ListeVenteVehiculeComponent } from './vente-vehicule/liste/liste.vente.vehicule.component';
import { CreationLocationVehiculeComponent } from './location/creation/creation.location.vehicule.component';
import { ListeLocationVehiculeComponent } from './location/liste/liste-location-vehicule.component';
import { VenteVehiculeDetailComponent } from './vente-vehicule/detail/vente-vehicule-detail.component';
import { LocationVehiculeDetailComponent } from './location/detail/location-vehicule-detail.component';
import { ListeLocationProformaVehiculeComponent } from './location-proformat/liste/liste-location-proforma-vehicule.component';
import { LocationProformaVehiculeDetailComponent } from './location-proformat/detail/location-proforma-vehicule-detail.component';
import { CreationLocationProformatVehiculeComponent } from './location-proformat/creation/creation-location-proforma.vehicule.component';
import { ListeValidationLocationVehiculeComponent } from './location/validation/liste/liste-validation-location-vehicule.component';
import { ValidationLocationVehiculeDetailComponent } from './location/validation/detail/validation-location-vehicule-detail.component';
import { InvoiceComponent } from './location/invoice/invoice.component';
import { ModificationLocationVehiculeComponent } from './location/modification/modification.location.vehicule.component';

const routes: Routes = [

  {
    path: 'creation-vente-vehicule',
    component: CreationVenteVehiculeComponent
  },
  {
    path: 'liste-vente-vehicule',
    component: ListeVenteVehiculeComponent
  },
  {
    path: 'creation-location-vehicule',
    component: CreationLocationVehiculeComponent
  },
  {
    path: 'modification-location-vehicule/:id',
    component: ModificationLocationVehiculeComponent
  },
  {
    path: 'creation-location-proforma-vehicule',
    component: CreationLocationProformatVehiculeComponent
  },
  {
    path: 'liste-location-vehicule',
    component: ListeLocationVehiculeComponent
  },
  {
    path: 'liste-location-proforma-vehicule',
    component: ListeLocationProformaVehiculeComponent
  },
  {
    path: 'liste-validation-location-vehicule',
    component: ListeValidationLocationVehiculeComponent
  },

  {
    path: 'validation-location-vehicule-detail/:id',
    component: ValidationLocationVehiculeDetailComponent
  },

  {
    path: 'vente-vehicule-detail/:id',
    component: VenteVehiculeDetailComponent
  },
  {
    path: 'location-vehicule-detail/:id',
    component: LocationVehiculeDetailComponent
  },
  {
    path: 'location-vehicule-detail/invoice/:id',
    component: InvoiceComponent
  },
  {
    path: 'location-proforma-vehicule-detail/:id',
    component: LocationProformaVehiculeDetailComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionLocationVenteRoutingModule { }
