// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { CategorieReponse } from '../../gestion-categorie/reponse/categorie.reponse';
import { CategorieService } from '../../gestion-categorie/service/categorie.service';
import { SessionService } from '../../auth/services/session.service';
import { environment } from 'src/environments/environment';
import { DataTablesModule } from 'angular-datatables';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-stock-encours',
  standalone: true,
  imports: [CommonModule, NgSelectModule, SharedModule, DataTablesModule],
  templateUrl: './stock-encours.component.html',
  styleUrl: './stock-encours.component.scss'
})
export class StockEncoursComponent implements OnInit {
  stockForm: FormGroup;
  categories$: Observable<CategorieReponse[]> = EMPTY;
  // public props
  dtRouterLinkOptions: object = {};

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private localStorageService: SessionService,
    private categorieService: CategorieService
  ) {
    this.categories$ = this.categorieService.getAll();

    this.stockForm = this.fb.group({
      idCategorie: ['', [Validators.required]],
    });
    this.stockForm.get('idCategorie')?.valueChanges.subscribe((value) => {
      this.onChangeCategorie(value);
    })
  }

  selectCategorie(categorie: CategorieReponse): void {
    this.stockForm.get('idCategorie')?.setValue(categorie.idCategorie);
  }

  customSearch(term: string, item: any): boolean {
    if (!term) return true;

    term = term.toLowerCase();

    const searchableFields = ['libelle', 'nom', 'prenoms', 'immatriculationProvisoire', 'valeur']; // Liste des champs à rechercher

    return searchableFields.some(field =>
      item[field] && item[field].toLowerCase().includes(term)
    );
  }

  onChangeCategorie(idCategorie: any) {
    this.initializeDataTable(idCategorie);
  }

  // Méthode appelée à la soumission du formulaire

  // Méthode pour envoyer la requête


  ngOnInit(): void {
    //this.initializeDataTable();
  }


  initializeDataTable(idCategorie?: any) {
    const tableElement = $('#stockTable');
    // Vérifier si la table est déjà initialisée
    if ($.fn.dataTable.isDataTable('#stockTable')) {
      // Si la table est déjà initialisée, la détruire
      tableElement.DataTable().clear().destroy();
    }


    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/stocks/encours/${idCategorie}`;

    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        dataSrc: '',
        error: function (xhr: any, error: any, thrown: any) {
          console.error('AJAX error:', xhr, error, thrown);
        }
      },
      columns: [
        { title: 'Article', data: 'produit.libelle' },
        { title: "Prix Unitaire de vente", data: 'prixUnitaireVente' },
        { title: 'Quantité', data: 'quantite' },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },

      ],
      responsive: true,
      select: false
    };
    // Initialiser la DataTable après destruction
    tableElement.DataTable(this.dtRouterLinkOptions);
  }


}
