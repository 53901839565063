import { Component } from '@angular/core';
import { FcmService } from './helpers/fcm-service';

// third party
import { NotifierService } from 'angular-notifier';
import { AlertDialogComponent } from './Pages/alert/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',


})
export class AppComponent {
  title = 'parc_auto_app';

  // constructor

  constructor(private fcmService: FcmService,
    private notifier: NotifierService, private dialog: MatDialog,
    private toastr: ToastrService) {

  }

  ngOnInit() {
    this.notifier.getConfig().position = { horizontal: { position: 'right', distance: 12 }, vertical: { position: 'top', distance: 12, gap: 10 } };
    // Initialize FCM listener when the app starts
    this.fcmService.requestPermission();
    this.fcmService.requestNotificationPermission();
    this.fcmService.currentMessage.subscribe((message: any) => {
      const notification = message.notification || {};
      this.showNotification(notification.title, notification.body);
    })
  }


  showNotification(type: string, message: string): void {
    this.notifier.notify("warning", message);
  }


}
