// angular import
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// bootstrap import
import { SharedModule } from 'src/app/theme/shared/shared.module';

// third party
import { Router, RouterModule } from '@angular/router';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { environment } from 'src/environments/environment';
import { ProfilReponse } from '../reponses/profil.reponse';
import { alertConfirm } from 'src/app/helpers/alert-confirm';
import { ProfilService } from '../services/profil.service';

@Component({
  selector: 'app-liste-profil',
  standalone: true,
  imports: [CommonModule, DataTablesModule, SharedModule, RouterModule],
  templateUrl: './liste-profil.component.html',
  styleUrl: './liste-profil.component.scss'
})
export class ListeProfilComponent implements OnInit {
  isLoading = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;

  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;
  constructor(
    private localStorageService: SessionService,
    private profilService: ProfilService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/profils`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        dataSrc: function (json: ProfilReponse[]) {
          // Filter out items where libelle is 'MASTER'
          return json.filter(item => item.libelle !== 'MASTER');
        }

      },
      columns: [
        { title: 'Libellé', data: 'libelle' },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: null,
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => `
</button>
           <button class="btn btn-link-primary btn-edit"
                title="edit"
                id="edit-${row.idProfil}"
                data-info='${JSON.stringify({ idProfil: row.idProfil }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-pencil"></i>
        </button>
         <button class="btn btn-link-primary btn-delete"
                title="Delete"
                id="delete-${row.idProfil}"
                data-info='${JSON.stringify({ idProfil: row.idProfil }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-trash"></i>
        </button>
    `

        }
      ],
      responsive: true,
      select: false,

    };


  }


  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then(() => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#profilTable tbody').on('click', '.btn-edit', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');
        this.navigateToRouteEdit(row['idProfil']);
      });
      $('#profilTable tbody').on('click', '.btn-delete', (event) => {
        const $target = $(event.currentTarget); // Wrap currentTarget in jQuery
        const row = $target.data('info');
        this.confirmSuppression(row['idProfil'])
      });

    });
  }
  navigateToRouteEdit(data: any) {
    this.router.navigate(['/app/gestion-profil/update-profil', data]);
  }


  confirmSuppression(idModele: any) {
    alertConfirm('Voulez-vous supprimer ce profil ?', () => {
      this.onSubmitEdit(idModele);
    });
  }

  onSubmitEdit(idModele: any) {
    this.isLoading = true;
    this.profilService.delete(idModele).subscribe(
      (response: ProfilReponse) => {
        this.isLoading = false;
        this.hasSuccess = true;
        this.successMessage = 'Suppression effectuée avec succès.';
        window.location.reload();
      },
      (error: any) => {
        this.hasError = true;
        this.isLoading = false;
        this.hasSuccess = false;
        this.errorMessage = error.error.message;
        console.error("Erreur lors de la Suppression", error);
      }
    );

  }


}
