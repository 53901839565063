// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import packageInfo from '../../package.json';

export const environment = {
  appVersion: packageInfo.version,
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyATbMIwjUH6QPHKodtI6uH67DHo6JoEcgs",
    authDomain: "parcauto-2ed8e.firebaseapp.com",
    projectId: "parcauto-2ed8e",
    storageBucket: "parcauto-2ed8e.appspot.com",
    messagingSenderId: "84481129290",
    appId: "1:84481129290:web:db51708c0f1d86de095fb3",
    vapidKey: "BCbPcmponfOlL3QmGRTbUK9Yt6TSYstviuj1DE5OTHFKiWOHPjb0_sWsSNzuLP7_0XutCj7sMeD2t49XJ-TdJII"
  },
  apiUrl: 'https://mock-data-api-nextjs.vercel.app',
  key: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2NjAzMzcxNjksImV4cCI6MTY5MTg3MzE3NiwiYXVkIjoiZ2VzdGlvbmFwcCIsInN1YiI6Imdlc3Rpb25hcHAiLCJHaXZlbk5hbWUiOiJMYW5kcnkiLCJTdXJuYW1lIjoiRm9mYW5hIiwiRW1haWwiOiJkZXYubGFtYWduaWd1ZUBnbWFpbC5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.uNcHPGnZ6Z3GgGNxIn0AbdPBw6XXEnSJpu_1YMlRbYE",
  WebSocketChannel: "https://test-parc-auto.sds-techs.com",
  BaseUrl: "https://test-parc-auto.sds-techs.com/api/v1",

  //WebSocketChannel: "http://192.168.1.3:5200",
  //BaseUrl: "http://192.168.1.3:5200/api/v1",

  //BaseUrl: 'https://liked-stork-quiet.ngrok-free.app/api/v1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
