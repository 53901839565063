import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';

@Component({
  selector: 'app-table-stock',
  standalone: true,
  imports: [CommonModule, DataTablesModule],
  templateUrl: './table-stock.component.html',
  styleUrl: './table-stock.component.scss',
})
export class TableStockComponent implements OnInit {

  // public props
  dtRouterLinkOptions: object = {};

  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/stocks`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        dataSrc: '',
      },
      columns: [
        { title: 'Article', data: 'produit.libelle' },
        { title: "Prix Unitaire d'achat", data: 'prixUnitaireAchat' },
        { title: "Prix Unitaire de vente", data: 'prixUnitaireVente' },
        { title: 'Quantité', data: 'quantite' },
        { title: 'Date Stock', data: 'dateStock' },
        { title: 'Date Achat', data: 'dateAchat' },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },

      ],
      responsive: true,
      select: false
    };


  }


}

