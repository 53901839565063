// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { TableStockComponent } from "../table-stock/table-stock.component";
import { CategorieReponse } from '../../gestion-categorie/reponse/categorie.reponse';
import { EMPTY, Observable } from 'rxjs';
import { CategorieService } from '../../gestion-categorie/service/categorie.service';
import { FournisseurReponse } from '../../gestion-partenaire/fournisseur/reponse/fournisseur.reponse';
import { ProduitReponse } from '../../gestion-article/reponse/produit.reponse';
import { FournisseurService } from '../../gestion-partenaire/fournisseur/service/fournisseur.service';
import { ProduitService } from '../../gestion-article/service/produit.service';
import { StockService } from '../service/stock.service';
import { StockModele } from '../modeles/stock.modele';
import { StockReponse } from '../reponse/stock.reponse';
import { NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-creation-stock',
  standalone: true,
  imports: [CommonModule, SharedModule, NgSelectModule, TableStockComponent],
  templateUrl: './creation-stock.component.html',
  styleUrl: './creation-stock.component.scss'
})
export class CreationStockComponent implements OnInit {
  stockForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  categories$: Observable<CategorieReponse[]> = EMPTY;
  fournisseurs$: Observable<FournisseurReponse[]> = EMPTY;
  articles$: Observable<ProduitReponse[]> = EMPTY;



  constructor(
    private fb: FormBuilder,
    private stockService: StockService,
    private categorieService: CategorieService,
    private fournisseurService: FournisseurService,
    private produitService: ProduitService
  ) {
    this.categories$ = this.categorieService.getAll();
    this.fournisseurs$ = this.fournisseurService.getAll();
    this.articles$ = this.produitService.getAll();
    this.stockForm = this.fb.group({
      idCategorie: ['', [Validators.required]],
      idProduit: ['', [Validators.required]],
      prixUnitaireVente: [0, [Validators.required]],
      prixUnitaireAchat: [0, [Validators.required]],
      quantite: [0, [Validators.required]],
      idFournisseur: ['', [Validators.required]],
      dateAchat: ['', [Validators.required]],
      dateStock: ['', [Validators.required]],
      event: ['ENTREE'],
    });
    this.stockForm.get('idCategorie')?.valueChanges.subscribe((value) => {
      console.log(value);
      this.onChangeCategorie(value);
    })
  }

  selectCategorie(categorie: CategorieReponse): void {
    this.stockForm.get('idCategorie')?.setValue(categorie.idCategorie);
  }
  selectProduit(categorie: ProduitReponse): void {
    this.stockForm.get('idProduit')?.setValue(categorie.idProduit);
  }
  customSearch(term: string, item: any): boolean {
    if (!term) return true;

    term = term.toLowerCase();

    const searchableFields = ['libelle', 'nom', 'prenoms', 'immatriculationProvisoire', 'valeur']; // Liste des champs à rechercher

    return searchableFields.some(field =>
      item[field] && item[field].toLowerCase().includes(term)
    );
  }
  onChangeCategorie(idCategorie: any) {
    this.articles$ = this.produitService.getAllByCategorie(idCategorie);
  }
  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.stockForm.valid) {
      const payload: StockModele = this.stockForm.value;
      this.stockService.create(payload).subscribe(
        (response: StockReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Stock ajouté avec succès.';
          this.stockForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du stock', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
