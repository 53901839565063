export class ProfilModele{
    libelle?:any;
    codeProfil?:any;
    idProfil?:any;
    profilMenus?:ProfilMenuModele[]
}


export class ProfilMenuModele{
  idMenu?:any;
  idSousMenu?:any;
  permission?:any
}


