<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Ajout d'articles">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="produitForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="idCategorie">Catégorie<span class="text-danger">*</span></label>
                <select type="text" formControlName="idCategorie" class="form-control"
                  [ngClass]="{
                                                                  'is-invalid':
                                                                    produitForm.get('idCategorie')?.invalid &&
                                                                    produitForm.get('idCategorie')?.touched                                             }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let categorie of categories$ | async" [value]="categorie.idCategorie">
                    {{ categorie.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="produitForm.get('idCategorie')?.errors?.['required'] && produitForm.get('idCategorie')?.touched"
                  class="invalid-feedback">
                  La Catégorie est requise.
                </div>

              </div>
              <div class="form-group">
                <label for="code">Code article<span class="text-danger">*</span></label>
                <input type="text" formControlName="code" class="form-control" id="code" [ngClass]="{
                    'is-invalid':
                      produitForm.get('code')?.invalid &&
                      produitForm.get('code')?.touched
                  }" />
                <div *ngIf="
                    produitForm.get('code')?.invalid &&
                    produitForm.get('code')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="produitForm.get('code')?.errors?.['required']">
                    Le code est requis.
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="libelle">Libellé <span class="text-danger">*</span></label>
                <input type="text" formControlName="libelle" class="form-control" id="libelle" [ngClass]="{
                                      'is-invalid':
                                        produitForm.get('libelle')?.invalid &&
                                        produitForm.get('libelle')?.touched
                                    }" />
                <div *ngIf="
                                      produitForm.get('libelle')?.invalid &&
                                      produitForm.get('libelle')?.touched
                                    " class="invalid-feedback">
                  <div *ngIf="produitForm.get('libelle')?.errors?.['required']">
                    Le libellé est requis.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="description">Description</label>
                <textarea type="text" formControlName="description" class="form-control" id="description"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "Enregistrer" -->
          <button type="submit" class="btn btn-primary" [disabled]="produitForm.invalid" *ngIf="!isLoading">
            Enregistrer
          </button>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-article></app-table-article>
    </app-card>

  </div>
</div>