<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Ajout de stock">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>
      <form [formGroup]="stockForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="idCategorie">Catégorie<span class="text-danger">*</span></label>
                <ng-container *ngIf="stockForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #categorieSelect
                    [items]="categories$ | async" [selectOnTab]="true" (change)="selectCategorie($event)"
                    bindValue="idCategorie" labelForId="idCategorie" formControlName="idCategorie" [ngClass]="{
                                                    'is-invalid':
                                                      stockForm.get('idCategorie')?.invalid &&
                                                      stockForm.get('idCategorie')?.touched
                                                  }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                  </ng-select>
                </ng-container>

                <div *ngIf="stockForm.get('idCategorie')?.errors?.['required'] && stockForm.get('idCategorie')?.touched"
                  class="invalid-feedback">
                  La Catégorie est requise.
                </div>

              </div>
              <div class="form-group">
                <label for="prixUnitaireAchat">Prix unitaire d'achat <span class="text-danger">*</span></label>
                <input type="number" formControlName="prixUnitaireAchat" class="form-control" id="prixUnitaireAchat"
                  [ngClass]="{
                        'is-invalid':
                          stockForm.get('prixUnitaireAchat')?.invalid &&
                          stockForm.get('prixUnitaireAchat')?.touched
                      }" />
                <div *ngIf="
                        stockForm.get('prixUnitaireAchat')?.invalid &&
                        stockForm.get('prixUnitaireAchat')?.touched
                      " class="invalid-feedback">
                  <div *ngIf="stockForm.get('prixUnitaireAchat')?.errors?.['required']">
                    Le prix unitaire d'achat est requis.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="quantite">Quantité<span class="text-danger">*</span></label>
                <input type="number" formControlName="quantite" class="form-control" id="quantite" [ngClass]="{
                    'is-invalid':
                      stockForm.get('quantite')?.invalid &&
                      stockForm.get('quantite')?.touched
                  }" />
                <div *ngIf="
                    stockForm.get('quantite')?.invalid &&
                    stockForm.get('quantite')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="stockForm.get('quantite')?.errors?.['required']">
                    La quantité est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="dateAchat">Date d'achat<span class="text-danger">*</span></label>
                <input type="date" formControlName="dateAchat" class="form-control" id="dateAchat" [ngClass]="{
                    'is-invalid':
                      stockForm.get('dateAchat')?.invalid &&
                      stockForm.get('dateAchat')?.touched
                  }" />
                <div *ngIf="
                    stockForm.get('dateAchat')?.invalid &&
                    stockForm.get('dateAchat')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="stockForm.get('dateAchat')?.errors?.['required']">
                    La date d'achat est requise.
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="idProduit">Articles<span class="text-danger">*</span></label>
                <ng-container *ngIf="stockForm">
                  <ng-select [selectOnTab]="true" [closeOnSelect]="true" [searchFn]="customSearch" #produitSelect
                    [items]="articles$ | async" [selectOnTab]="true" (change)="selectProduit($event)"
                    bindValue="idProduit" labelForId="idProduit" formControlName="idProduit" [ngClass]="{
                                                                      'is-invalid':
                                                                        stockForm.get('idProduit')?.invalid &&
                                                                        stockForm.get('idProduit')?.touched
                                                                    }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.libelle }}
                    </ng-template>

                  </ng-select>
                </ng-container>
                <div *ngIf="stockForm.get('idProduit')?.errors?.['required'] && stockForm.get('idProduit')?.touched"
                  class="invalid-feedback">
                  L'article est requis.
                </div>

              </div>
              <div class="form-group">
                <label for="prixUnitaireVente">Prix unitaire de vente<span class="text-danger">*</span></label>
                <input type="number" formControlName="prixUnitaireVente" class="form-control" id="prixUnitaireVente"
                  [ngClass]="{
                              'is-invalid':
                                stockForm.get('prixUnitaireVente')?.invalid &&
                                stockForm.get('prixUnitaireVente')?.touched
                            }" />
                <div *ngIf="
                              stockForm.get('prixUnitaireVente')?.invalid &&
                              stockForm.get('prixUnitaireVente')?.touched
                            " class="invalid-feedback">
                  <div *ngIf="stockForm.get('prixUnitaireVente')?.errors?.['required']">
                    La prix unitaire de vente est requis.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="idFournisseur">Fournisseurs<span class="text-danger">*</span></label>
                <select type="text" formControlName="idFournisseur" class="form-control"
                  [ngClass]="{'is-invalid':stockForm.get('idFournisseur')?.touched                                             }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let fournisseur of fournisseurs$ | async" [value]="fournisseur.idFournisseur">
                    {{ fournisseur.nom}} {{fournisseur.prenoms }}
                  </option>
                </select>
                <div
                  *ngIf="stockForm.get('idFournisseur')?.errors?.['required'] && stockForm.get('idFournisseur')?.touched"
                  class="invalid-feedback">
                  Le fournisseur est requis.
                </div>
              </div>
              <div class="form-group">
                <label for="dateStock">Date Stock<span class="text-danger">*</span></label>
                <input type="date" formControlName="dateStock" class="form-control" id="dateStock" [ngClass]="{
                      'is-invalid':
                        stockForm.get('dateStock')?.invalid &&
                        stockForm.get('dateStock')?.touched
                    }" />
                <div *ngIf="
                      stockForm.get('dateStock')?.invalid &&
                      stockForm.get('dateStock')?.touched
                    " class="invalid-feedback">
                  <div *ngIf="stockForm.get('dateStock')?.errors?.['required']">
                    La date stock est requise.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "Enregistrer" -->
          <button type="submit" class="btn btn-primary" [disabled]="stockForm.invalid" *ngIf="!isLoading">
            Enregistrer
          </button>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-stock></app-table-stock>
    </app-card>
  </div>
</div>