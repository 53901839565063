export class Config {

  static calculateTotalPrice(price: number, days: number): number {
    return (price * days);
  }

  static calculateDaysBetweenDates(dateDebut: any, dateFin: any): number {
    const startDate: Date = new Date(dateDebut);
    const endDate: Date = new Date(dateFin);
    // Convertir les deux dates en millisecondes
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    // Calculer la différence en millisecondes
    const differenceInTime = endTime - startTime;

    // Convertir la différence en jours (1 jour = 24 * 60 * 60 * 1000 millisecondes)
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return Math.ceil(differenceInDays); // Arrondir au nombre de jours
  }

}
