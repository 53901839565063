<div class="row">
  <div class="col-sm-12">

    <div class="card border">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-6">
            <h5 class="mb-0">Details de la location</h5>
          </div>
          <div class="col-sm-6 text-sm-end" *ngIf="vehicule$ | async as v">
            <h5 class="mb-0">Date création : {{v.dateCreation | date}}</h5>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="vehicule$ | async as v">
        <div *ngIf="hasSuccess" class="alert alert-success mt-0 mb-2">
          <p>{{successMessage}}</p>
        </div>
        <div *ngIf="hasError" class="alert alert-danger mt-0 mb-2">
          <p>{{errorMessage}}</p>
        </div>
        <div class="row align-items-center">
          <div class="col-md-6">
            <p class="mb-1 mt-2"> <b>Numéro de location :</b> {{v.numero}}</p>
            <p><b>Montant:</b> {{v.montant | formatNumber}}</p>
            <p><b>Montant à payer:</b> {{totalPrice+(totalPrice*(v.tva/100))|formatNumber}}</p>
          </div>
          <div class="col-md-6 text-md-end">
            <p class="mb-1 mt-2 badge bg-light-success rounded f-12"> <b>Etat:</b> {{v.etat}} </p>

          </div>
        </div>

        <hr class="my-4" />
        <div class="row align-items-center">
          <div class="col-md-6">
            <p><b>Client</b></p>
            <p class="mb-1 mt-2"> <b>Nom :</b> {{v.client.nom}} {{v.client.prenoms}}</p>
            <p><b>Téléphone:</b> {{v.client.telephone}}</p>
            <p><b>Email:</b> {{v.client.email}}</p>
          </div>
          <div class="col-md-6">
            <p class="mb-1 mt-2"> <b>Tva :</b> {{v.tva}}%</p>
          </div>
        </div>
        <hr class="my-4" />
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Quantité</th>
                <th class="text-end">Remise</th>
                <th class="text-end">Montant</th>
              </tr>
            </thead>
            <tbody>
              @for (task of v.detailCommandes; track task) {
              <tr>
                <td>
                  <div>
                    <h5 class="mb-1">{{ task.produit.libelle }}</h5>

                  </div>
                </td>
                <td>{{ task.quantite }}</td>

                <td class="text-end">{{ task.remise }}%</td>
                <td class="text-end">{{ task.quantite*task.prixUnitaire |formatNumber}}</td>
              </tr>
              }
            </tbody>
          </table>
          <div class="d-flex justify-content-end my-3">
            <!-- Loading Button -->

            <!-- Print Button -->
            <button *ngIf="v.etat === 'ACTIVATED'" class="btn btn-primary btn-sm" (click)="printDiv('invoice')">
              Imprimer
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>


<div style="display: none;" *ngIf="vehicule$ | async as v">
  <div class="" id="invoice">
    <div class="page">
      <div class="content">
        <div class="px-5 pt-4">
          <div class="text-start"><img src="/assets/images/logo.png" alt="Logo" width="150px"></div>
          <hr class="mt-4">
          <div class="d-flex justify-content-between" style="margin-top: 10px; margin-bottom: 10px;">
            <div style="width: 40%;"><span class="fs-4 text-uppercase fw-bold">FACTURE</span></div>
            <div style="width: 20%; font-size: small;"><span>Client :</span><br><span class="fw-bold">{{v.client.nom}}
                {{v.client.prenoms}}</span></div>
            <div style="width: 20%; font-size: small;"><span>N° C.C. :</span><br><span class="fw-bold"></span>
            </div>
            <div style="width: 20%; font-size: small;"><span>Téléphone :</span><br><span
                class="fw-bold">{{v.client.telephone}}</span>
            </div>
          </div>
          <hr class="">
          <div class="row py-3">
            <div class="offset-5 col-7" style="font-size: small;">
              <div class="row">
                <div class="col-4">
                  <span class="fw-bold">N° Facture</span>
                  <hr class="p-0" style="margin-top: 4px; margin-bottom: 4px;">
                  <span>{{v.numero}}</span>
                </div>
                <div class="col-4">
                  <span class="fw-bold">Date</span>
                  <hr class="p-0" style="margin-top: 4px; margin-bottom: 4px;">
                  <span>{{v.dateCreation| date:'dd-MM-yyyy'}}</span>
                </div>
                <div class="col-4">
                  <span class="fw-bold">Emetteur</span>
                  <hr class="p-0" style="margin-top: 4px; margin-bottom: 4px;">
                  <span>{{v.creerPar}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5" style="font-size: small;"><span class="fw-bold">Objet :</span> VENTE DE
            PIECES
          </div>
          <div class="">
            <table class="table mt-4 table-responsive" style="font-size: small;">
              <thead style="border-bottom: 2px solid #3f3d8d;">
                <tr>
                  <th>Référence</th>
                  <th style="width: 50%;">Désignation</th>
                  <th>P.U HT</th>
                  <th>Qté</th>
                  <th>Rem.</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                @for (task of v.detailCommandes; track task) {
                <tr>
                  <td>#</td>
                  <td style="width: 50%; white-space: normal; overflow-wrap: break-word;">
                    {{task.produit.libelle}}
                  </td>
                  <td>{{task.prixUnitaire|round|formatNumber}}</td>
                  <td>{{task.quantite}}</td>

                  <td>{{task.remise}}%</td>
                  <td>
                    {{(task.prixUnitaire* task.quantite)-(task.prixUnitaire*
                    task.quantite)*(task.remise/100)|round|formatNumber}}
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="offset-7 col-5 text-end" style="font-size: small;">
              <div class="d-flex">
                <div class="" style="width: 30%;">Montant HT</div>
                <div class="pe-2" style="width: 70%;">{{totalPrice|round|formatNumber}}</div>
              </div>
              <hr class="m-0 p-0">
              <div class="d-flex">
                <div class="" style="width: 30%;">TVA {{v.tva}}%</div>
                <div class="pe-2" style="width: 70%;">{{totalPrice*v.tva/100|round|formatNumber}}</div>
              </div>
              <hr class="m-0 p-0" style="border: 1px solid #3f3d8d;">
              <div class="d-flex">
                <div class="" style="width: 35%; color:  #3f3d8d;">Montant TTC</div>
                <div class="pe-2" style="width: 70%; color:  #3f3d8d;">{{totalHTTC |round| formatNumber}}</div>
              </div>
            </div>
          </div>
          <div class="fw-bold my-4" style="font-size: small;">Arrêté la présente facture à
            la somme de {{totalPriceToLetter}} francs CFA.</div>
          <div class="" style="font-size: small;">
            <span class="fw-bold">CONDITIONS</span>
            <hr class="m-0 p-0">
            <table>
              <tbody>
                <tr>
                  <td><span>Conditions de paiement :</span></td>
                  <td><span class="fw-bold">ESPECES/CHEQUE</span></td>
                </tr>
                <tr>
                  <td><span>Delai de livraison :</span></td>
                  <td><span class="fw-bold">24 H</span></td>
                </tr>
                <tr>
                  <td><span>Validité de l'offre :</span></td>
                  <td><span class="fw-bold">1 mois</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="px-5 text-center" style="font-size: x-small;">
          <hr>
          <span>Situé à Angré 8ème tranche non loin du carrefour de la prière.</span><br>
          <span>Tel : +225 : 07 07 93 97 12 / 07 07 94 08 08 /05 06 72 68 83 13 BP 1715 Abidjan
            13</span><br>
          <span>N°CC : 1912797 L Réel Normal d’Imposition Centre des Impôts de la Djibi N° RC :
            CI-ABJ-2019-B-
            02715 NSIA N° 035361963575</span><br>
          <span>IBAN : CI93 CI04 2012 - Compte Bancaire BIAO N° 03536196357524 - SWIFT Code :
            BIAOCIABXXX</span><br>
          <span>Email : {{emailEntreprise}}</span><br>
        </div>
      </div>
    </div>
  </div>
</div>