<div>
  <div class="d-flex justify-content-end mb-3">
    <button type="button" (click)="rafraichir()" class="btn btn-secondary me-2">
      Rafraichir
    </button>
  </div>
  <div class="table-responsive">
    <table datatable [dtOptions]="dtRouterLinkOptions" [dtTrigger]="dtTrigger" id="paiementTable"
      class="table  row-border table-hover"></table>

  </div>
</div>