import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationCompagnieComponent } from './creation-compagnie/creation-compagnie.component';

const routes: Routes = [

  {
    path: 'ajout-compagnie',
    component: CreationCompagnieComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionCompagnieRoutingModule { }
