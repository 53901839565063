<div class="row">
  <!-- [ sample-page ] start -->
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h5>Créer une facture</h5>
      </div>
      <div class="card-body">
        <hr class="my-3" />
        <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
          <p>{{successMessage}}</p>
        </div>
        <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
          <p>{{errorMessage}}</p>
        </div>
        <form [formGroup]="venteForm" (ngSubmit)="onSubmit()">
          <div class="row d-flex justify-content-end">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="driver">Catégorie<span class="text-danger">*</span></label>

                <ng-select [selectOnTab]="true" #categorieSelect [items]="categorieList$ | async" [selectOnTab]="true"
                  [searchFn]="customSearch" (change)="selectCategorie($event)" bindValue="idCategorie"
                  labelForId="idCategorie" formControlName="idCategorie" [ngClass]="{
                                    'is-invalid':
                                      venteForm.get('idCategorie')?.invalid &&
                                      venteForm.get('idCategorie')?.touched
                                  }">
                  <!-- Template pour le label affiché dans le champ sélectionné -->
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.libelle }}
                  </ng-template>

                  <!-- Template pour les options dans la liste déroulante -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.libelle }}
                  </ng-template>

                </ng-select>
                <div *ngIf="
                                venteForm.get('idCategorie')?.invalid &&
                                venteForm.get('idCategorie')?.touched
                              " class="invalid-feedback">
                  <div *ngIf="clientForm.get('idCategorie')?.errors?.['required']">
                    La catégorie est requise.
                  </div>
                </div>
              </div>

            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="form-label">Client <span class="text-danger">*</span></label>
                <ng-container *ngIf="venteForm">
                  <ng-select [selectOnTab]="true" #clientSelect [items]="clientList$ | async" [selectOnTab]="true"
                    [searchFn]="customSearch" (change)="selectClient($event)" bindValue="idClient" labelForId="idClient"
                    formControlName="idClient" [ngClass]="{
                  'is-invalid':
                    venteForm.get('idClient')?.invalid &&
                    venteForm.get('idClient')?.touched
                }">
                    <!-- Template pour le label affiché dans le champ sélectionné -->
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.nom }} {{ item.prenoms }} {{ item.telephone }}
                    </ng-template>

                    <!-- Template pour les options dans la liste déroulante -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.nom }} {{ item.prenoms }} {{ item.telephone }}
                    </ng-template>

                  </ng-select>
                </ng-container>
                <div *ngIf="
              venteForm.get('idClient')?.invalid &&
              venteForm.get('idClient')?.touched
            " class="invalid-feedback">
                  <div *ngIf="clientForm.get('idClient')?.errors?.['required']">
                    Le client est requis.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="form-label">Article <span class="text-danger">*</span></label>
                <ng-container *ngIf="venteForm">
                  <ng-select #produitSelect [items]="produitList$ | async" [selectOnTab]="true"
                    [searchFn]="customSearch" (change)="selectProduit($event)" bindValue="idProduit"
                    labelForId="idProduit" formControlName="idProduit" [ngClass]="{
                      'is-invalid':
                        venteForm.get('idProduit')?.invalid &&
                        venteForm.get('idProduit')?.touched
                    }">

                    <ng-template ng-label-tmp let-item="item">
                      {{ item.produit?.libelle }}
                    </ng-template>

                    <!-- Template for dropdown options -->
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.produit?.libelle }}
                    </ng-template>

                  </ng-select>
                </ng-container>
                <div *ngIf="
              venteForm.get('idProduit')?.invalid &&
              venteForm.get('idProduit')?.touched
            " class="invalid-feedback">
                  <div *ngIf="clientForm.get('idProduit')?.errors?.['required']">
                    L'article est requis.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="montant">Montant<span class="text-danger">*</span></label>
                <input type="number" formControlName="montant" class="form-control" [ngClass]="{
                    'is-invalid':
                      venteForm.get('montant')?.invalid &&
                      venteForm.get('montant')?.touched
                  }" />
                <div *ngIf="
                    venteForm.get('montant')?.invalid &&
                    venteForm.get('montant')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="clientForm.get('montant')?.errors?.['required']">
                    Le montant location est requis.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label">Quantité</label>
                    <input type="number" class="form-control" formControlName="quantite" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label">Remise %</label>
                    <input type="number" maxlength="3" class="form-control" formControlName="remise" />
                  </div>
                </div>
              </div>
            </div>

            <hr class="my-3" />
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-link-primary" [disabled]="venteForm.invalid" (click)="addTodoList()">
                + Ajouter
              </button>
            </div>
            <div class="my-2"></div>
            <div class="col-sm-2">
              <div class="form-group">
                <label class="form-label">Tva %</label>
                <input type="number" maxlength="3" class="form-control" formControlName="tva" />
              </div>
            </div>

          </div>
          <hr class="my-3" />

          <div class="my-2"></div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th class="text-end">Remise</th>
                  <th class="text-end">P.U</th>
                  <th class="text-end">Total</th>
                  <th class="text-end"></th>
                </tr>
              </thead>
              <tbody>
                @for (task of todoList; track task) {
                <tr>
                  <td>
                    <div>
                      <h5 class="mb-1">{{ task.msg }}</h5>
                      <p class="mb-0 text-muted">{{ task.dis }}</p>
                    </div>
                  </td>

                  <td class="text-end">{{ formatNumberWithSpace(task.remise)}}%</td>
                  <td class="text-end">{{ formatNumberWithSpace(task.total) }}</td>
                  <td class="text-end">{{
                    formatNumberWithSpace((calculMontant(task)-(calculMontant(task)*(task.remise/100))))}}
                  </td>
                  <td class="text-end">
                    <div class="">
                      <a href="javascript:" title="Delete" class="delete_todolist text-danger"
                        (click)="removeItem(task.vId)"><i class="far fa-trash-alt"></i></a>
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
          <hr class="my-3" />
          <div class="rounded p-3 bg-light-primary">
            <div class="row justify-content-end">
              <div class="col-auto">
                <div class="table-responsive">
                  <table class="table table-borderless text-end mb-0">
                    <tbody>

                      <tr>
                        <th>Montant : </th>
                        <td> {{ formatNumberWithSpace(total)}}</td>
                      </tr>

                      <tr>
                        <th>Tva ({{venteForm.get('tva')?.value}} %): </th>
                        <td> {{ formatNumberWithSpace(venteForm.get('tva')?.value/100 * total) }}</td>
                      </tr>

                      <tr>
                        <td class="pe-0 pt-0">
                          <hr class="mb-3 mt-0" />
                          <h5 class="text-primary m-r-10">Total :</h5>
                        </td>
                        <td class="ps-0 pt-0">
                          <hr class="mb-3 mt-0" />
                          <h5 class="text-primary">
                            {{
                            formatNumberWithSpace(
                            totalRemse
                            )
                            }}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end my-3">
            <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
              <span class="spinner-grow spinner-grow-sm" role="status"></span>
              Traitement...
            </button>

            <!-- Bouton "Enregistrer" -->
            <button type="submit" class="btn btn-primary" [disabled]="todoList.length === 0" *ngIf="!isLoading">
              Enregistrer
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>
  <!-- [ sample-page ] end -->
</div>