import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { VenteVehiculeReponse } from '../../vente-vehicule/reponse/vente.vehicule.reponse';

@Component({
  selector: 'app-invoice-location',
  standalone: true,
  imports: [],
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent {
  @Input() data!: VenteVehiculeReponse;
  public printDiv(divId: string): void {
    const elementsToHide = document.querySelectorAll('body > :not(#' + divId + ')');
    elementsToHide.forEach(element => (element as HTMLElement).style.display = 'none');

    const style = document.createElement('style');
    style.innerHTML = '@media print { @page { size: portrait; } }';
    document.head.appendChild(style);

    const printContent = document.getElementById(divId)?.cloneNode(true);
    const temporaryContainer = document.createElement('div');
    if (printContent) {
      temporaryContainer.appendChild(printContent);
      document.body.appendChild(temporaryContainer);
      window.print();
      temporaryContainer.remove();
      style.remove();
    }

    elementsToHide.forEach(element => (element as HTMLElement).style.display = '');
  }
}
