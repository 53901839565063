// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party
import { matches } from 'lodash';
import { CompagnieModele } from '../modeles/compagnie.modele';
import { CompagnieReponse } from '../reponse/compagnie.reponse';
import { CompagnieService } from '../service/compagnie.service';
import { Router } from '@angular/router';
import { TableCompagnieComponent } from "../table-compagnie/table-compagnie.component";

@Component({
  selector: 'app-creation-compagnie',
  standalone: true,
  imports: [CommonModule, SharedModule, TableCompagnieComponent, TableCompagnieComponent],
  templateUrl: './creation-compagnie.component.html',
  styleUrl: './creation-compagnie.component.scss'
})
export class CreationCompagnieComponent implements OnInit {
  clientForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private compagnieService: CompagnieService
  ) {
    this.clientForm = this.fb.group({
      nom: ['', [Validators.required]],
      email: [[''], [Validators.required]],
      adresse: [[''], [Validators.required]],
      telephone: [[''], [Validators.required]],
      description: [['']],
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.clientForm.valid) {
      const payload: CompagnieModele = this.clientForm.value;
      payload.type = "ASSURANCE";
      this.compagnieService.create(payload).subscribe(
        (response: CompagnieReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Compagnie ajoutée avec succès.';
          this.clientForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création de la compagnie', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
