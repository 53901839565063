import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionUtilisateurRoutingModule } from './gestion-utilisateur-routing.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    GestionUtilisateurRoutingModule,
  ]
})
export class GestionUtilisateurModule { }
