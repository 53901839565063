import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party
import { matches } from 'lodash';
import { VehiculeReponse } from '../../gestion-vehicule/vehicule/reponses/vehicule.reponse';
import { CompagnieReponse } from '../../gestion-compagnie-assurance/reponse/compagnie.reponse';
import { VehiculeService } from '../../gestion-vehicule/vehicule/services/vehicule.service';
import { CompagnieService } from '../../gestion-compagnie-assurance/service/compagnie.service';
import { EMPTY, Observable } from 'rxjs';
import { AssuranceModele } from '../modeles/assurance.modele';
import { AssuranceService } from '../service/assurance.service';
import { AssuranceReponse } from '../reponse/assurance.reponse';
@Component({
  selector: 'app-ajout-ass',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './ajout-ass.component.html',
  styleUrl: './ajout-ass.component.scss'
})
export class AjoutAssComponent {
  assuranceForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  vehicules$: Observable<VehiculeReponse[]> = EMPTY;
  compagnies$: Observable<CompagnieReponse[]> = EMPTY;
  constructor(private fb: FormBuilder,
    private vehiculeService: VehiculeService,
    private compagnieService: CompagnieService,
    private assuranceService: AssuranceService
  ) {
    this.assuranceForm = this.fb.group({
      dateDebut: ['', [Validators.required]],
      dateFin: [[''], [Validators.required]],
      numeroPolice: [[''], [Validators.required]],
      codeAssurance: [[''], [Validators.required]],
      idVehicule: [[null], [Validators.required]],
      idCompagnie: [[null], [Validators.required]],
      lien: ['', [Validators.required]],
      extension: ['', [Validators.required]],
    });
    this.compagnies$ = this.compagnieService.getAll("ASSURANCE");
    this.vehicules$ = this.vehiculeService.getAll();
  }


  fileChangeEvent(event: any): void {
    // Récupérer le fichier sélectionné
    const file = event.target.files[0];

    if (file) {
      // Extraire l'extension du fichier
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      this.assuranceForm.get('extension')?.setValue(fileExtension);

      // Utiliser FileReader pour obtenir le contenu Base64
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64String = e.target.result.split(',')[1]; // Récupère le contenu Base64 sans le préfixe
        this.assuranceForm.get('lien')?.setValue(base64String);
      };
      reader.readAsDataURL(file); // Lecture du fichier en tant que URL Base64
    }
  }


  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = true;
    const payload: AssuranceModele = this.assuranceForm.value;
    payload.document = {
      lien: this.assuranceForm.get('lien')?.value,
      extension: this.assuranceForm.get('extension')?.value
    }
    this.assuranceService.create(payload).subscribe(
      (response: AssuranceReponse) => {
        this.isLoading = false;
        this.hasError = false;
        this.hasSuccess = true;
        this.successMessage = 'Assurance ajoutée avec succès.';
        this.assuranceForm.reset();
      },
      (error: any) => {
        this.hasError = true;
        this.hasSuccess = false;
        this.isLoading = false;
        this.errorMessage = error.error.message;
        console.error("Erreur lors de la création de l'assurance", error);
      }
    );

  }

}
