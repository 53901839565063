import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { Subject } from 'rxjs';
import { DetailCommande } from '../../gestion-location-vente/vente-vehicule/reponse/vente.vehicule.reponse';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-table-retour',
  standalone: true,
  imports: [CommonModule, DataTablesModule],
  templateUrl: './table-retour.component.html',
  styleUrl: './table-retour.component.scss',
})
export class TableRetourComponent implements OnInit, AfterViewInit {
  @Input() paiementForm: FormGroup | any;
  dtTrigger: Subject<any> = new Subject();
  selectedVehicule: any;
  // public props
  dtRouterLinkOptions: object = {};

  @ViewChild(DataTableDirective, { static: false }) datatableElement!: DataTableDirective;


  constructor(
    private localStorageService: SessionService,
    private router: Router,

  ) {

  }

  ngOnInit() {
    this.initializeDataTable();
  }

  initializeDataTable(ref?: any) {
    if (ref) {
      // Reload the page if ref is truthy
      window.location.reload();
    }
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/commandes/retour`;
    console.log(lien);

    this.dtRouterLinkOptions = {
      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },

        error: function (xhr: any, error: any, thrown: any) {
          console.error('AJAX error:', xhr, error, thrown);
        },
        dataSrc: function (json: DetailCommande[]) {
          // Flatten the detailCommandes array
          const flatData = json.flatMap((command: any) =>
          ({
            idCommande: command.idCommande,
            idDetailCommande: command.idDetailCommande,
            numero: command.numero,
            montant: command.montant,
            reste: command.reste,
            etat: command.etat,
            dateDebut: command.dateDebut,
            dateFin: command.dateFin,
            dateCreation: command.dateCreation
          })
          );

          return flatData;
        }
      },
      columns: [
        { title: 'Numéro', data: 'numero' },
        { title: 'Montant', data: 'montant' },
        { title: 'Reste à payer', data: 'reste' },

        {
          title: 'Etat',
          data: 'etat',
          render: function (data: string, type: string, row: any) {
            // Customize badge class based on 'etat' value
            let badgeClass = 'badge bg-light-success rounded f-12';

            // You can add more conditions here if needed
            // For example, different colors for different states
            if (data === 'Pending') {
              badgeClass = 'badge bg-warning rounded f-12';
            } else if (data === 'ACTIVATED') {
              badgeClass = 'badge bg-light-success rounded f-12';
            } else if (data === 'Canceled') {
              badgeClass = 'badge bg-danger rounded f-12';
            }
            if (data == 'ACTIVATED') {
              data = 'VALIDE'
            } else if (data == 'UPDATED') {
              data = 'EN ATTENTE DE MISE A JOUR'
            } else if (data == 'RENTAL') {
              data = 'EN MISSION'
            } else if (data == 'PENDING') {
              data = 'EN ATTENTE DE VERIFICATION'
            } else if (data == 'PRE_CONFIRMED') {
              data = 'EN ATTENTE DE CONFIRMATION'
            }

            // Return the HTML for the badge
            return `<span class="${badgeClass}">${data}</span>`;
          }
        },
        {
          title: 'Date Sortie',
          data: 'dateDebut',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Date Retour',
          data: 'dateFin',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'idCommande',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any, type: any, row: any) => {
            return `
       <button class="btn btn-link-primary btn-edit"
                title="Edit"
                id="edit-${row.idCommande}"
                data-info='${JSON.stringify({ idCommande: row.idCommande, idDetailCommande: row.idDetailCommande, montant: row.montant, reste: row.reste, numero: row.numero }).replace(/'/g, "&apos;")}'>
            <i class="ti ti-pencil"></i>
        </button>
    `;
          }
        }
      ],
      responsive: true,
      select: false,
    };

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next(this.dtRouterLinkOptions);
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {

      $('#paiementTable tbody').on('click', '.btn-edit', (event) => {
        const $target = $(event.currentTarget);
        const row = $target.data('info');
        this.paiementForm?.patchValue({
          montant: row['reste'],
          reste: row['reste'],
          oldMontant: row['montant'],
          numFacture: row['numero'],
          idCommande: row['idCommande'],
          idDetailCommande: row['idDetailCommande']
        });

      });
    });
  }




}

